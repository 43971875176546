import { useCallback } from 'react'

import { faX } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag } from 'antd'
import { Popover } from 'govwell-ui'
import { MenuContentStyles } from 'govwell-ui/components/Menu/Menu'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import FilterEditor from 'src/components/TableViews/FilterEditor'
import Text, { TextSize } from 'src/components/Typography/Text'
import useDisclosure from 'src/hooks/use-disclosure'
import { FilterManager } from 'src/models/TableViews/FilterManager'
import { TableViewFilter } from 'src/models/TableViews/TableViewFilter'

import FilterTagValue from './FilterTagValue'

const StyledXButton = styled(Text)`
  outline: solid 1px transparent;
  border-radius: 4px;
  margin-left: 3px;
  padding: 0px 3px;
  cursor: pointer;
  align-self: center;
  &:focus {
    outline: solid 1px ${({ theme }) => theme.colorPrimaryActive};
  }
`
const StyledPopoverContent = styled(Popover.Content)`
  ${MenuContentStyles}
  width: fit-content;
  max-width: 100vw;
  overflow-x: auto;
  padding: 6px 0px;
`

const StyledBadge = styled.div`
  background-color: ${({ theme }) => theme['orange-6']};
  width: 8px;
  height: 8px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`

const StyledTag = styled(Tag)`
  margin: 0;
  height: fit-content;
  max-width: calc(100% - 4px);
`

const ENTER = 'Enter'
const SPACE = ' '

type Props = {
  filter: TableViewFilter
  filterManager: FilterManager
}
const FilterTag = ({ filter, filterManager }: Props) => {
  const { isOpen: isEditorOpen, open: onOpenEditor, close: onCloseEditor } = useDisclosure()
  const handleClear = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation()
      runInAction(() => {
        filter.clear()
      })
      onCloseEditor()
    },
    [filter, onCloseEditor]
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if ([ENTER, SPACE].includes(e.key)) {
        handleClear()
      }
    },
    [handleClear]
  )

  if (!filter.isPopulated && !isEditorOpen) {
    return null
  }

  return (
    <Popover isOpen={isEditorOpen} onOpen={onOpenEditor} onClose={onCloseEditor}>
      <Popover.Trigger asChild>
        <StyledTag
          key={filter.columnType}
          style={{
            width: 'fit-content',
            alignItems: 'baseline',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            cursor: 'pointer',
            pointerEvents: 'all',
          }}
          onClick={onOpenEditor}
        >
          <Text colorToken="colorTextSecondary">
            {filter.columnTemplate.defaultLabelSentenceCase}:{' '}
          </Text>
          <Text strong whiteSpace="break-spaces">
            <FilterTagValue filter={filter} />
          </Text>
          <StyledXButton
            role="button"
            size={TextSize.ExtraSmall}
            onClick={handleClear}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <FontAwesomeIcon icon={faX} />
          </StyledXButton>
          {filter.hasUnsavedChanges && <StyledBadge />}
        </StyledTag>
      </Popover.Trigger>
      <StyledPopoverContent align="start" sideOffset={6}>
        <FilterEditor columnTemplate={filter.columnTemplate} filterManager={filterManager} />
      </StyledPopoverContent>
    </Popover>
  )
}

export default observer(FilterTag)
