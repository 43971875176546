import { faExclamation, faRefresh } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'

import { useTheme } from '../../../hooks/use-theme'
import { getErrorName } from '../../../utils/file'
import Text, { TextSize } from '../../Typography/Text'

interface Props {
  errorType: string
  name: string
  reset: () => void
}

export const Error = (props: Props) => {
  const { errorType, name, reset } = props
  const { getTokenVal } = useTheme()

  return (
    <Flex vertical gap="6px" align="center">
      <Flex gap="6px">
        <FontAwesomeIcon
          icon={faExclamation}
          style={{
            height: 32,
            width: 32,
            color: getTokenVal('colorErrorBase'),
          }}
        />
        <Text size={TextSize.ExtraLarge}>Error: {name}</Text>
      </Flex>
      <Text size={TextSize.Small}>{getErrorName(errorType)}</Text>
      <Button type="link" icon={<FontAwesomeIcon icon={faRefresh} />} onClick={reset}>
        Try again
      </Button>
    </Flex>
  )
}
