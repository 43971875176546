export const RecordTemplateOptionFragment = gql`
  fragment RecordTemplateOptionFragment on RecordTemplate {
    id
    status
    uuid
    createdAt
    name
    recordType {
      id
      recordName
      name
      category
    }
  }
`

export const RecordTemplatePickFragment = gql`
  fragment RecordTemplatePickFragment on RecordTemplate {
    id
    uuid
    name
    tagline
    publishable
    whenRequired
    applicantType
    icon
    visibleOnModulePickPage
    visibleOnOrganizationHomepage
    applicationGroup {
      id
      name
    }
    fileFields {
      ...FieldFragment
    }
    fieldGroups {
      ...FieldGroupFragment
    }
    defaultWorkflowTemplate {
      recordTaskTemplateGroups {
        id
        feeConfigs {
          id
          visible
          fee {
            ...FeeFragment
            latestVersion {
              applicantExplanation
            }
          }
        }
        inspectionGroups {
          id
          inspections {
            id
            inspectionTemplate {
              id
              name
            }
          }
        }
      }
    }
  }
`
