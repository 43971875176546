import { useCallback } from 'react'

import { Button } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import styled from 'styled-components'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import { useResetAuthState } from 'src/hooks/use-reset-auth-state'

import { useAuth } from '../auth'

export const ItemContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
`
export const TitleContainer = styled.div`
  color: white;
`

type Props = {
  type?: BaseButtonProps['type']
}
export const LogInOutButton = ({ type = 'default' }: Props) => {
  const { isAuthenticated, loading } = useAuth()
  const { pathname } = useLocation()

  const text = isAuthenticated ? 'Sign out' : 'Sign in'
  const resetAuthState = useResetAuthState()
  const onClick = useCallback(async () => {
    // Whether a user is trying to sign in or out, assume their auth-related state should be reset.
    // This is an attempt to fix a Clerk bug where users have a stale session:
    //  - Clerk useAuth() hook returns isAuthenticated = false
    //  - Clerk SignIn component redirects the user away from the login page anyway as though they're logged in
    await resetAuthState()

    if (!isAuthenticated) {
      navigate(routes.login(pathname ? { redirectTo: pathname } : {}))
    }
  }, [isAuthenticated, pathname, resetAuthState])

  if (loading) {
    return null
  }

  return (
    <Button onClick={onClick} type={type} style={{ width: 'fit-content' }}>
      {text}
    </Button>
  )
}

export default LogInOutButton
