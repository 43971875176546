import omit from 'lodash.omit'
import { makeAutoObservable, toJS } from 'mobx'
import {
  ViewColumnFragment,
  ViewColumnTemplateFragment,
  ViewFilterFragment,
  ViewFragment,
  ViewSortFragment,
  ViewType,
} from 'types/graphql'

import { ColumnManager } from 'src/models/TableViews/ColumnManager'
import { SortManager } from 'src/models/TableViews/SortManager'
import { TableViewFilter } from 'src/models/TableViews/TableViewFilter'

import { FilterManager } from './FilterManager'

export class TableView {
  public readonly id: number
  public readonly columnManager: ColumnManager
  public readonly filterManager: FilterManager
  public readonly sortManager: SortManager
  public readonly viewType: ViewType

  public totalCount: number
  public databaseTotalCount: number
  public databaseDisplayIndex: number
  public displayIndex: number
  public name: string

  constructor(args: {
    columns: ViewColumnFragment[]
    columnTemplates: ViewColumnTemplateFragment[]
    displayIndex: number
    id: number
    filters: ViewFilterFragment[]
    name: string
    sorts: ViewSortFragment[]
    totalCount: number
    viewType: ViewType
  }) {
    this.columnManager = new ColumnManager({
      columns: args.columns.map((c) => omit(c, '__typename')),
      columnTemplates: args.columnTemplates,
    })
    this.displayIndex = args.displayIndex
    this.databaseDisplayIndex = args.displayIndex
    this.id = args.id
    this.filterManager = new FilterManager({
      filters: args.filters,
      columnTemplates: args.columnTemplates,
    })
    this.name = args.name
    this.sortManager = new SortManager({
      sorts: args.sorts.map((s) => omit(s, '__typename')),
    })
    this.totalCount = args.totalCount
    this.databaseTotalCount = args.totalCount
    this.viewType = args.viewType
    makeAutoObservable(this)
  }

  static fromProtocol(
    protocol: ViewFragment,
    columnTemplates: ViewColumnTemplateFragment[]
  ): TableView {
    return new TableView({
      columns: protocol.columns ?? [],
      columnTemplates,
      displayIndex: protocol.displayIndex,
      filters: protocol.filters ?? [],
      id: protocol.id,
      name: protocol.name,
      sorts: protocol.sorts ?? [],
      totalCount: 0,
      viewType: protocol.viewType,
    })
  }

  public clone(): TableView {
    return new TableView({
      columns: this.columnManager.columns,
      columnTemplates: this.filterManager.columnTemplates,
      id: this.id,
      displayIndex: this.displayIndex,
      name: this.name,
      filters: this.filterManager.populatedFilters.map((f) => f.protocol),
      sorts: this.sortManager.sorts,
      totalCount: this.databaseTotalCount,
      viewType: this.viewType,
    })
  }

  public reset() {
    this.filterManager.reset()
    this.sortManager.reset()
    this.totalCount = this.databaseTotalCount
  }

  public save() {
    this.filterManager.save()
    this.sortManager.save()
    this.databaseTotalCount = this.totalCount
  }

  public get hasUnsavedChanges(): boolean {
    return this.filterManager.hasUnsavedChanges || this.sortManager.hasUnsavedChanges
  }

  public get protocol(): ViewFragment {
    return {
      columns: toJS(this.columnManager.columns.map((c) => toJS(c.protocol))),
      displayIndex: this.displayIndex,
      filters: toJS(this.filterManager.populatedFilters.map((f) => toJS(f.protocol))),
      id: this.id,
      name: this.name,
      viewType: this.viewType,
      sorts: toJS(this.sortManager.sorts),
    }
  }

  public get databaseProtocol(): ViewFragment {
    return {
      columns: this.columnManager.columns.map((c) => toJS(c.protocol)),
      displayIndex: this.databaseDisplayIndex,
      filters: toJS(
        this.filterManager.filters
          .map((f) => toJS(f.databaseProtocol))
          .filter((f) => TableViewFilter.isFilterPopulated(f))
      ),
      id: this.id,
      name: this.name,
      viewType: this.viewType,
      sorts: toJS(this.sortManager.databaseProtocol),
    }
  }
}
