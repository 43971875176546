import {
  GetForeignStorageTokenMutation,
  GetForeignStorageTokenMutationVariables,
} from 'types/graphql'

import { useMutation } from 'src/utils/queryClient'

const GetForeignStorageToken = gql`
  mutation GetForeignStorageTokenMutation {
    getForeignStorageToken {
      token
    }
  }
`

export const useForeignStorageTokenMutation = () => {
  return useMutation<GetForeignStorageTokenMutation, GetForeignStorageTokenMutationVariables>({
    mutationDocument: GetForeignStorageToken,
  })
}
