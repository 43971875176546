import React from 'react'

export enum LogoColor {
  White,
  Black,
}

interface Props {
  color: LogoColor
  height?: React.SVGAttributes<SVGElement>['height']
  width?: React.SVGAttributes<SVGElement>['width']
}

export const Logo = (props: Props) => {
  const { color: logoColor, height = 48, width = 144 } = props
  const color = logoColor === LogoColor.Black ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 122 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0291 13.2202C19.0458 13.0203 19.0549 12.8189 19.0549 12.6146C19.0549 8.79418 16.0298 5.69653 12.2977 5.69653C8.56555 5.69653 5.54102 8.79363 5.54102 12.6146C5.54102 12.8189 5.55016 13.0203 5.56682 13.2202H19.0286H19.0291Z"
        fill={color}
      />
      <path
        d="M16.128 0.70606C14.8364 0.695796 13.8123 1.00477 13.1908 1.24623V5.09928C13.8118 4.85782 14.8359 4.54885 16.128 4.55911C17.3563 4.56883 18.3334 4.86269 18.9417 5.09928V1.24623C18.3334 1.00964 17.3563 0.715783 16.128 0.70606Z"
        fill={color}
      />
      <rect
        x="11.4056"
        y="6.47156"
        width="5.7657"
        height="1.78514"
        transform="rotate(-90 11.4056 6.47156)"
        fill={color}
      />
      <rect x="3.68919" y="13.9729" width="17.2174" height="1.78514" fill={color} />
      <rect x="0.595764" y="16.8019" width="23.4042" height="1.78514" fill={color} />
      <rect
        x="1.65363"
        y="24.4652"
        width="4.83446"
        height="3.38178"
        transform="rotate(-90 1.65363 24.4652)"
        fill={color}
      />
      <rect
        x="7.62239"
        y="24.4652"
        width="4.83446"
        height="3.38178"
        transform="rotate(-90 7.62239 24.4652)"
        fill={color}
      />
      <rect
        x="13.5914"
        y="24.4652"
        width="4.83446"
        height="3.38178"
        transform="rotate(-90 13.5914 24.4652)"
        fill={color}
      />
      <rect
        x="19.5602"
        y="24.4652"
        width="4.83446"
        height="3.38178"
        transform="rotate(-90 19.5602 24.4652)"
        fill={color}
      />
      <rect x="0.595764" y="25.509" width="23.4042" height="1.78514" fill={color} />
      <path
        d="M38.292 23.253C32.91 23.253 29.92 19.757 29.92 14.881V14.697C29.92 9.752 33.439 6.302 38.292 6.302C41.949 6.302 45.215 7.981 45.606 12.052H41.903C41.581 10.12 40.316 9.246 38.338 9.246C35.486 9.246 33.807 11.385 33.807 14.743V14.927C33.807 18.101 35.164 20.378 38.384 20.378C41.144 20.378 42.271 18.791 42.34 16.905H38.637V14.191H46.02V15.939C46.02 20.332 43.214 23.253 38.292 23.253ZM54.2603 20.723C56.1233 20.723 57.2043 19.343 57.2043 17.066V16.905C57.2043 14.605 56.1233 13.225 54.2603 13.225C52.4203 13.225 51.3163 14.559 51.3163 16.859V17.043C51.3163 19.343 52.3743 20.723 54.2603 20.723ZM54.2373 23.253C50.6493 23.253 47.9123 20.861 47.9123 17.112V16.928C47.9123 13.156 50.6493 10.695 54.2603 10.695C57.8713 10.695 60.5853 13.11 60.5853 16.859V17.043C60.5853 20.838 57.8483 23.253 54.2373 23.253ZM65.8449 23L61.4519 10.971H65.0169L67.8919 19.573L70.7439 10.971H73.9639L69.5939 23H65.8449ZM78.777 23L74.775 6.555H78.547L81.123 17.917L83.929 6.555H87.103L90.024 17.986L92.692 6.555H96.142L92.14 23H88.345L85.378 11.638L82.365 23H78.777ZM103.06 23.253C99.426 23.253 96.781 20.976 96.781 17.135V16.951C96.781 13.156 99.472 10.695 102.968 10.695C106.073 10.695 108.833 12.512 108.833 16.813V17.733H100.162C100.254 19.734 101.335 20.884 103.152 20.884C104.693 20.884 105.452 20.217 105.659 19.205H108.81C108.419 21.804 106.349 23.253 103.06 23.253ZM100.208 15.686H105.59C105.475 13.869 104.555 12.995 102.968 12.995C101.473 12.995 100.461 13.984 100.208 15.686ZM111.568 23V5.52H114.88V23H111.568ZM118.474 23V5.52H121.786V23H118.474Z"
        fill={color}
      />
    </svg>
  )
}
