import { createContext } from 'react'

import { CellSuccessProps } from '@redwoodjs/web'

import {
  GetAppStateQuery,
  GetAppStateQueryVariables,
  GetOrganizationBySlugStateQuery,
  RecordTypeFragment,
} from '../../../types/graphql'
import { RefetchFnType } from '../../types'

export type AppStateContextLayoutCellProps = CellSuccessProps<
  GetAppStateQuery,
  GetAppStateQueryVariables
>

export type AppStateContextUser = AppStateContextLayoutCellProps['user']
export type AppStateQueryOrganization = AppStateContextLayoutCellProps['organization']
export type AppStateQueryCompany = AppStateContextLayoutCellProps['company']

export interface AppStateContextData {
  user: AppStateContextLayoutCellProps['user']
  organization: AppStateQueryOrganization | undefined
  organizationBySlug?: GetOrganizationBySlugStateQuery['organizationBySlug']
  company: AppStateQueryCompany
  recordTypes: RecordTypeFragment[]
  currentlyViewedRecordTypeId: number | undefined
  setCurrentlyViewedRecordTypeId: (recordTypeId: number | undefined) => void
  refetch: RefetchFnType
}

export const AppStateContext = createContext<AppStateContextData | undefined>(undefined)
