import React from 'react'

import { useRedirectToLoginIfUnauthenticated } from 'src/hooks/use-redirect-to-login-if-unauthenticated'

import { useMyUser } from '../AppStateContextLayout/utils'

type Props = {
  children?: React.ReactNode
}

const AuthGateLayout = ({ children }: Props) => {
  useRedirectToLoginIfUnauthenticated()

  const user = useMyUser()
  if (!user) {
    return <></>
  }

  return <>{children}</>
}

export default AuthGateLayout
