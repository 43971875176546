import { useCallback } from 'react'

import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { useQueryClient } from '@tanstack/react-query'
import { Alert, Button, ConfigProvider, Flex } from 'antd'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { GetOrCreateViewsMutation } from 'types/graphql'

import Modal from 'src/components/Modal'
import Text from 'src/components/Typography/Text'
import { useDeleteViewMutation } from 'src/fetch/views'
import useDisclosure, { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'
import { QueryKey } from 'src/utils/queryClient'

type Props = {
  generateDefaultViews: () => Promise<GetOrCreateViewsMutation>
  modalState: UseDisclosureReturn
  tableViewManager: TableViewManager
  view: TableView
}
const DeleteViewModal = ({ generateDefaultViews, modalState, tableViewManager, view }: Props) => {
  const {
    isOpen: isDeletingView,
    open: startDeletingView,
    close: stopDeletingView,
  } = useDisclosure()
  const { mutateAsync: deleteView } = useDeleteViewMutation()
  const queryClient = useQueryClient()

  const handleSubmit = useCallback(async () => {
    startDeletingView()
    await runInAction(async () => {
      await deleteView({ id: view.id })
    })
    await runInAction(async () => {
      tableViewManager.deleteView(view.displayIndex)
      if (!tableViewManager.views.length) {
        tableViewManager.reset()
        await generateDefaultViews()
        await queryClient.refetchQueries({
          queryKey: [QueryKey.Views],
        })
      }
    })
    stopDeletingView()
    modalState.close()
  }, [
    deleteView,
    generateDefaultViews,
    modalState,
    queryClient,
    startDeletingView,
    stopDeletingView,
    tableViewManager,
    view.displayIndex,
    view.id,
  ])

  return (
    <Modal
      title={`Delete View "${view.name}"`}
      icon={faTrash}
      onOk={() => {}}
      form
      formInitialValues={{
        name: view.name,
      }}
      {...modalState}
      footer={
        <Flex justify="flex-end" gap="6px">
          <Button onClick={modalState.close}>Cancel</Button>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: 'rgba(245, 34, 45, 1)',
                colorPrimaryHover: 'rgba(255, 163, 158, 1)',
                colorPrimaryActive: 'rgba(168, 7, 26, 1)',
              },
            }}
          >
            <Button loading={isDeletingView} onClick={handleSubmit} type="primary">
              Delete view
            </Button>
          </ConfigProvider>
        </Flex>
      }
    >
      <Flex gap="12px" vertical>
        {tableViewManager.views.length === 1 && (
          <Alert
            type="info"
            showIcon
            message="Deleting the last view will reset this dashboard and add back the default views."
          />
        )}
        <Text>Are you sure you want to delete this view? This action cannot be undone.</Text>
      </Flex>
    </Modal>
  )
}

export default observer(DeleteViewModal)
