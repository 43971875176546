import { Tag } from 'antd'
import { RecordTaskPaymentStatus } from 'types/graphql'

type Props = {
  status: RecordTaskPaymentStatus | undefined | null
}
const RecordTaskPaymentStatusDisplay = ({ status }: Props) => {
  const color = status === 'Requested' ? 'orange' : status === 'Paid' ? 'green' : 'red'
  // status enums in DB are already human readable
  return <Tag color={color}>{status}</Tag>
}

export default React.memo(RecordTaskPaymentStatusDisplay)
