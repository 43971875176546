import {
  CreateFileMutation,
  CreateFileMutationVariables,
  GetAreFilesProcessedQuery,
  GetAreFilesProcessedQueryVariables,
  GetFilePageImageUrlsQuery,
  GetFilePageImageUrlsQueryVariables,
  GetIsFileProcessedQuery,
  GetIsFileProcessedQueryVariables,
  RotateAllFilePagesMutation,
  RotateAllFilePagesMutationVariables,
  RotateFilePageMutation,
  RotateFilePageMutationVariables,
} from 'types/graphql'

import { QueryKey, UseQueryOptions, useMutation, useQuery } from 'src/utils/queryClient'

const GetIsFileProcessed = gql`
  query GetIsFileProcessedQuery($id: Int!) {
    file(id: $id) {
      pageProcessingFinishedAt
      pages {
        ...FilePageFragment
      }
    }
  }
`

export const useIsFileProcessedQuery = (
  id: number,
  options?: UseQueryOptions<GetIsFileProcessedQuery, GetIsFileProcessedQueryVariables>
) => {
  return useQuery<GetIsFileProcessedQuery, GetIsFileProcessedQueryVariables>({
    queryKey: [QueryKey.FileProcessed, id],
    queryDocument: GetIsFileProcessed,
    variables: {
      id,
    },
    ...options,
  })
}
const GetAreFilesProcessed = gql`
  query GetAreFilesProcessedQuery($ids: [Int!]!) {
    files(ids: $ids) {
      pageProcessingFinishedAt
    }
  }
`

export const useAreFilesProcessedQuery = (
  ids: number[],
  options?: UseQueryOptions<GetAreFilesProcessedQuery, GetAreFilesProcessedQueryVariables>
) => {
  return useQuery<GetAreFilesProcessedQuery, GetAreFilesProcessedQueryVariables>({
    queryKey: [QueryKey.FileProcessed, ids],
    queryDocument: GetAreFilesProcessed,
    variables: {
      ids,
    },
    ...options,
  })
}

const GetFilePageImageUrls = gql`
  query GetFilePageImageUrlsQuery($id: Int!) {
    filePage(id: $id) {
      id
      signedSmallImageUrl
      signedMediumImageUrl
      signedLargeImageUrl
    }
  }
`

const ONE_MINUTE_IN_MS = 60 * 1000
export const useFilePageImageUrlsQuery = (
  id: number,
  options?: UseQueryOptions<GetFilePageImageUrlsQuery, GetFilePageImageUrlsQueryVariables>
) => {
  return useQuery<GetFilePageImageUrlsQuery, GetFilePageImageUrlsQueryVariables>({
    queryKey: [QueryKey.FilePageImageUrls, id],
    queryDocument: GetFilePageImageUrls,
    variables: {
      id,
    },
    staleTime: ONE_MINUTE_IN_MS,
    ...options,
  })
}

const RotateFilePage = gql`
  mutation RotateFilePageMutation($input: RotateFilePageInput!) {
    rotateFilePage(input: $input)
  }
`

export const useRotateFilePageMutation = () => {
  return useMutation<RotateFilePageMutation, RotateFilePageMutationVariables>({
    mutationDocument: RotateFilePage,
  })
}

const RotateAllFilePages = gql`
  mutation RotateAllFilePagesMutation($input: RotateAllFilePagesInput!) {
    rotateAllFilePages(input: $input)
  }
`

export const useRotateAllFilePagesMutation = () => {
  return useMutation<RotateAllFilePagesMutation, RotateAllFilePagesMutationVariables>({
    mutationDocument: RotateAllFilePages,
  })
}

const Create = gql`
  mutation CreateFileMutation($input: CreateFileInput!) {
    createFile(input: $input) {
      ...FileFragment
    }
  }
`
export const useCreateFileMutation = () => {
  return useMutation<CreateFileMutation, CreateFileMutationVariables>({
    mutationDocument: Create,
  })
}
