import { useParams } from '@redwoodjs/router'

import { useMyOrg, useOrgSlugMetadata } from '../layouts/AppStateContextLayout/utils'
import { useRecordContext } from '../pages/RecordPage/Record/Context'

export const useSlug = () => {
  const { slug } = useParams()
  const orgSlugMetadata = useOrgSlugMetadata()
  const myOrg = useMyOrg()
  const recordContext = useRecordContext()
  return slug || recordContext?.orgSlug || orgSlugMetadata?.slug || myOrg?.slug || ''
}
