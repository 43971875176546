import isNil from 'lodash.isnil'

import { PaymentMethod } from '../../types/graphql'
import { FinixFormType } from '../pages/RecordPage/WorkflowCell/shared/types'

const bpsPerPercent = 100
const percentPerOne = 100

export const formatFee = (fee: string | number | null | undefined): string => {
  let dividedAmount = 0
  if (!isNil(fee)) {
    dividedAmount = typeof fee === 'string' ? parseFloat(fee) / 100 : fee / 100
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  }).format(dividedAmount)
}

export const convertRawFeeToCents = (fee: string | number): number => {
  if (typeof fee === 'string') {
    const parsed = parseFloat(fee)
    return Math.round(parsed * 100)
  }
  return fee * 100
}

interface FeeAmountParams {
  invoiceAmount: number
  feeBPS: number
  finixFormType?: FinixFormType
}

export const feeAmount = (params: FeeAmountParams) => {
  const { invoiceAmount, feeBPS, finixFormType } = params
  if (!('finixFormType' in params)) {
    return invoiceAmount
  }
  // ACH for now is just a $1 fee
  if (finixFormType === FinixFormType.ACH) {
    return 100
  }
  return Math.floor(invoiceAmount * (feeBPS / (bpsPerPercent * percentPerOne)))
}

export const formatPaymentMethod = (
  paymentMethod: PaymentMethod | undefined,
  paymentProviderFormatted: string
): string => {
  if (!paymentMethod) {
    return ''
  }
  switch (paymentMethod) {
    case 'ACH':
      return 'ACH'
    case 'Cash':
      return 'Cash'
    case 'Check':
      return 'Check'
    case 'Credit': {
      return 'Credit Card'
    }
    case 'External': {
      return paymentProviderFormatted
    }
    case 'ExternalCardReader': {
      return 'External Card Reader'
    }
    case 'ExternalCreditCard': {
      return `${paymentProviderFormatted} (Credit Card)`
    }
    case 'ExternalACH': {
      return `${paymentProviderFormatted} (E-Check)`
    }
  }
}
