import { useEffect } from 'react'

import { usePrevious } from '@dnd-kit/utilities'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { FileFragment } from 'types/graphql'

import { UseFileUploadParams, useFileUploadState } from '../../hooks/use-file-upload-state'
import { FileUpload } from '../FileUpload/FileUpload'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

interface Props extends FormFieldWrapperProps {
  uploadParams: UseFileUploadParams
  fieldName: string
}

export const FileInput = (props: Props) => {
  const { uploadParams, ...formFieldWrapperProps } = props

  const fileUploadProps = useFileUploadState({
    fieldName: formFieldWrapperProps.fieldName,
    ...uploadParams,
  })

  const form = useFormInstance()
  useWatch(formFieldWrapperProps.fieldName, form)
  const file = form.getFieldValue(props.fieldName) as FileFragment

  const hasFile = !!file
  const previousHasFile = usePrevious(hasFile) || false

  useEffect(() => {
    if (hasFile !== previousHasFile) {
      form.validateFields([props.fieldName])
    }
  }, [form, hasFile, previousHasFile, props.fieldName])

  return (
    <Wrapper {...formFieldWrapperProps}>
      {({ hasError }) => (
        <FileUpload {...fileUploadProps} accept={uploadParams.accept} error={hasError} />
      )}
    </Wrapper>
  )
}
