import { useEffect, useRef } from 'react'

import { faSignature, faX } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form } from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import styled from 'styled-components'
import { FileFragment } from 'types/graphql'

import { PathPrefixes } from '../../constants/file'
import useDisclosure, { UseDisclosureReturn } from '../../hooks/use-disclosure'
import { useFileUploadState } from '../../hooks/use-file-upload-state'
import Modal from '../Modal'
import { RenderedSignature } from '../RenderedSignature'
import Text, { TextSize } from '../Typography/Text'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

interface Props extends FormFieldWrapperProps {
  signText?: string
  hideSignedText?: boolean
}

export const Signature = (props: Props) => {
  const { signText, hideSignedText, ...formFieldWrapperProps } = props

  const modalState = useDisclosure()

  const form = Form.useFormInstance()
  Form.useWatch(formFieldWrapperProps.fieldName, form)
  const file = form.getFieldValue(formFieldWrapperProps.fieldName) as FileFragment

  if (formFieldWrapperProps.disabled) {
    return (
      <Wrapper {...formFieldWrapperProps} required={false}>
        <RenderedSignature type="modal" url={file?.url?.signedUrl || ''} />
      </Wrapper>
    )
  }

  return (
    <>
      <Wrapper {...formFieldWrapperProps}>
        {({ value }) => {
          if (!value) {
            return (
              <Button
                icon={<FontAwesomeIcon icon={faSignature} />}
                onClick={modalState.open}
                size="large"
              >
                {signText || 'Sign'}
              </Button>
            )
          }
          return (
            <Text size={TextSize.Base} strong inline>
              {!hideSignedText && (
                <Text size={TextSize.Base} inline>
                  Signed
                </Text>
              )}
              <Text
                onClick={modalState.open}
                size={TextSize.Base}
                inline
                pointer
                colorToken="cyan-7"
              >
                {' '}
                Update
              </Text>
            </Text>
          )
        }}
      </Wrapper>
      <EditSignatureModal {...props} modalState={modalState} url={file?.url?.signedUrl} />
    </>
  )
}

interface ModalProps extends Props {
  modalState: UseDisclosureReturn
  url?: string
}

const Container = styled.div`
  border: 1px solid black;
  width: 100%;
  height: 100%;
  padding-bottom: 32px;
  margin-bottom: 20px;
`

const EditSignatureModal = (props: ModalProps) => {
  const { modalState, url, ...rest } = props

  const onOk = async () => {
    const signatureDataUrl = signatureRef?.current?.toDataURL('image/png')
    if (!signatureDataUrl) {
      return
    }
    await fileUploadProps.uploadFileUrl(signatureDataUrl)
  }
  const signatureRef = useRef<SignatureCanvas>(null)

  const fileUploadProps = useFileUploadState({
    pathPrefix: PathPrefixes.Signatures,
    fieldName: rest.fieldName,
  })

  const clearSignature = () => {
    signatureRef?.current?.clear?.()
    fileUploadProps.removeFile()
  }

  // Function to load and draw the initial image
  const loadInitialSignature = async (imageUrl: string | ArrayBuffer | null) => {
    if (typeof imageUrl !== 'string') {
      return
    }
    const image = new Image()
    image.src = imageUrl
    image.onload = () => {
      const canvas = signatureRef?.current?.getCanvas()
      if (canvas?.getContext) {
        const ctx = canvas.getContext('2d')
        ctx?.drawImage(image, 0, 0, canvas.width, canvas.height)
      }
    }
  }

  // Effect to load the initial signature
  useEffect(() => {
    if (url) {
      void convertImageToDataURL(url).then(loadInitialSignature)
    }
  }, [url, modalState.isOpen])

  return (
    <Modal
      {...modalState}
      icon={faSignature}
      title="Signature"
      okText="Done"
      hideCancelButton
      onOk={onOk}
    >
      <Container>
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{ width: 636, height: 200 }}
        />
      </Container>
      <Button icon={<FontAwesomeIcon icon={faX} />} onClick={clearSignature}>
        Clear
      </Button>
    </Modal>
  )
}

async function convertImageToDataURL(url: string) {
  try {
    const response = await fetch(url, {
      mode: 'cors', // This needs to be supported by the server hosting the image.
    })
    const blob = await response.blob()
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.error('Error converting image to Data URL:', error)
    throw error
  }
}
