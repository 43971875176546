export const RecordOptionFragment = gql`
  fragment RecordOptionFragment on Record {
    id
    currentUserHasAccess
    uuid
    identifier
    issuedIdentifier
    isRenewal
    expirationDate
    formattedName
    status {
      ...RecordTypeStatusFragment
    }
    isDraft
    recordTemplate {
      id
      name
      recordType {
        id
        name
        recordName
      }
    }
    parentRecord {
      id
    }
  }
`

export const RelatedRecordFragment = gql`
  fragment RelatedRecordFragment on RelatedRecord {
    record {
      ...RecordOptionFragment
    }
    relationshipType
  }
`

export const RecordFragment = gql`
  fragment RecordFragment on Record {
    id
    createdAt
    expirationDate
    formattedName
    identifier
    issuedAt
    issuedIdentifier
    rejectedAt
    submittedAt
    tags
    unpaidRecordTaskPaymentsCount
    uuid
    relatedRecords {
      ...RelatedRecordFragment
    }
    futureScheduledEvents {
      id
    }
    accesses {
      id
      contactFieldId
      user {
        ...UserDataFragment
      }
    }
    currentCaseHistory {
      id
      violationType {
        id
        name
      }
    }
    status {
      id
      name
      type
    }
    resolvedAt
    isResolved
    isViolation
    isDraft
    importedAt
    recordCollections {
      id
      name
      uuid
    }
    mostRecentPlansSet {
      id
    }
    currentRecordTaskGroup {
      id
    }
    alerts {
      id
    }
    contactsFieldGroup {
      fields {
        id
      }
    }
    addressField {
      ...AddressFieldFragment
    }
    ownerUser {
      ...UserDataFragment
    }
    applicantUser {
      ...RecordApplicantUserFragment
    }
    recordTaskInspectionGroups {
      ...RecordComponentRecordTaskInspectionGroupFragment
    }
    generatedDocuments {
      ...RecordGeneratedDocumentFragment
    }
    recordTemplate {
      id
      name
      recordTypeId
      icon
      applicantType
      recordType {
        ...RecordComponentRecordTypeFragment
      }
    }
    formFieldGroups {
      ...FieldGroupFragment
    }
    recordTaskGroups {
      id
      status
      type
      isLocked
      recordTasks {
        ...RecordComponentRecordTaskFragment
      }
    }
    createdByUser {
      ...UserDataFragmentWithCompany
    }
    workflows {
      ...WorkflowOptionFragment
    }
    emailAttachments {
      ...RecordEmailFileFragment
    }
    missingTemplateFields {
      id
    }
  }
  fragment RecordApplicantUserFragment on User {
    ...UserDataFragment
    uuid
    company {
      id
      uuid
      name
      phoneNumber
      emailAddress
      addressLine1
      addressLine2
      city
      state
      zip
    }
  }
  fragment RecordComponentRecordTypeFragment on RecordType {
    id
    name
    recordName
    category
    allowFees
    addressEntryType
    organization {
      city
      uuid
      slug
      id
      inspectionCoordinationType
      inspectionCutoffTime
      inspectionSchedulingInstructions
    }
  }
  fragment RecordComponentRecordTaskInspectionGroupFragment on RecordTaskInspectionGroup {
    alwaysAvailableToApplicants
    ...RecordTaskInspectionGroupFragment
    recordTaskGroup {
      id
      isLocked
      status
      name
      workflow {
        id
        completedAt
        createdAt
        status
        workflowTemplate {
          name
        }
      }
    }
    inspections {
      attempts {
        ...RecordTaskInspectionAttemptFragment
        requestedAt
        scheduledAt
        hasReport
        scheduledByUser {
          ...UserDataFragment
        }
        cancelledAt
        cancelledByUser {
          ...UserDataFragment
        }
        loggedByUser {
          ...UserDataFragment
        }
        updatedAt
      }
    }
  }
  fragment RecordComponentRecordTaskFragment on RecordTask {
    id
    status
    type
    name
    ownerUser {
      ...UserDataFragment
    }
    fieldGroup {
      ...FieldGroupFragment
    }
    checklistItems {
      id
      completedAt
    }
    createdRecord {
      id
      uuid
    }
    requestedRecordTemplate {
      ...RecordTemplateOptionFragment
    }
  }
  fragment RecordGeneratedDocumentFragment on RecordGeneratedDocument {
    id
    uuid
    file {
      ...FileFragment
    }
    isMailer
    issuedByUser {
      ...UserDataFragment
    }
    createdByUser {
      ...UserDataFragment
    }
    documentTemplateId
    identifier
    validFrom
    validUntil
    createdAt
  }
`
