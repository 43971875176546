import dayjs from 'dayjs'
import omit from 'lodash.omit'

import { filterNil } from 'src/utils'

import { FieldFragment, FieldParametersSchema } from '../../../types/graphql'
import { mapFileFragmentToUploadMultipleFilesFileUploadState } from '../../hooks/use-files-upload-state'
import {
  RecordAutocompleteFieldValues,
  mapRecordResultToAutocompleteOption,
} from '../form/shared/RecordAutocompleteCell/RecordAutocomplete'
import { RecordCollectionSelectValues } from '../form/shared/RecordCollectionSelect'

import { getAddressFrontendFormValue, makeFieldNameForFieldFragment } from './util'

interface Params {
  fieldFragment: FieldFragment
  fieldNameSuffix?: number | string
}

const mapFieldFragmentToFrontEndFormValue = (params: Params): Record<string, unknown> => {
  const { fieldFragment, fieldNameSuffix } = params
  const {
    allowMultiple,
    type,
    checked,
    num,
    str,
    strs,
    codeBookItems,
    contact,
    inspectionResult,
    record,
    file,
    files,
    recordCollections,
    foreignValue,
  } = fieldFragment
  const parameters = fieldFragment.parameters as FieldParametersSchema
  const baseFieldName = makeFieldNameForFieldFragment(fieldNameSuffix)
  switch (type) {
    case 'Address': {
      if (!fieldFragment.locationSnapshot && !fieldFragment.str) {
        return { [baseFieldName]: undefined }
      }
      if (fieldFragment.locationSnapshot) {
        return {
          [baseFieldName]: getAddressFrontendFormValue({
            str: fieldFragment.str,
            locationSnapshot: fieldFragment.locationSnapshot,
          }),
        }
      }
      return {
        [baseFieldName]: {
          manualAddress: fieldFragment.str,
        },
      }
    }
    case 'Record': {
      if (!record) {
        return { [baseFieldName]: undefined }
      }
      const mappedOption = mapRecordResultToAutocompleteOption(record)
      const res: RecordAutocompleteFieldValues = {
        label: mappedOption.label,
        selectedId: mappedOption.id,
        selectedOption: record,
        options: [record],
      }
      return {
        [baseFieldName]: res,
      }
    }
    case 'Contact': {
      const value: FieldFragment['contact'] = {
        id: contact?.id ?? -1,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        companyName: contact?.companyName,
        phoneNumber: contact?.phoneNumber,
        faxNumber: contact?.faxNumber,
        email: contact?.email,
        title: contact?.title,
        addressLine1: contact?.addressLine1,
        addressLine2: contact?.addressLine2,
        city: contact?.city,
        state: contact?.state,
        zip: contact?.zip,
      }
      return {
        [baseFieldName]: value,
      }
    }
    case 'ShortText': {
      if (allowMultiple) {
        return { [baseFieldName]: strs?.length ? strs : [''] }
      }
      return {
        [baseFieldName]: str,
      }
    }
    case 'LongText':
    case 'Radio': {
      return {
        [baseFieldName]: str,
      }
    }
    case 'Date': {
      return {
        [baseFieldName]: str ? dayjs(str) : '',
      }
    }
    case 'Checkbox': {
      const { checkboxText } = parameters
      return {
        [baseFieldName]: checked ? [checkboxText] : [],
      }
    }
    case 'Number':
    case 'Currency': {
      return {
        [baseFieldName]: `${num}`,
      }
    }
    case 'Dropdown': {
      return {
        [baseFieldName]: strs,
      }
    }
    case 'File': {
      return {
        [baseFieldName]: file,
      }
    }
    case 'Files': {
      return {
        [baseFieldName]: filterNil(files ?? []).map(
          mapFileFragmentToUploadMultipleFilesFileUploadState
        ),
      }
    }
    case 'Signature': {
      return {
        [baseFieldName]: file,
      }
    }
    case 'InspectionResult': {
      return {
        [baseFieldName]: inspectionResult?.id,
      }
    }
    case 'RecordCollection': {
      const res: RecordCollectionSelectValues = {
        selectedIds: (recordCollections || []).flatMap((rc) => rc?.id || []),
        recordCollectionNames: (recordCollections || []).flatMap((rc) => rc?.name || []),
      }
      return {
        [baseFieldName]: res,
      }
    }
    case 'ForeignValue': {
      return {
        [baseFieldName]: omit(foreignValue, '__typename'),
      }
    }
    case 'CodeBookItem': {
      return {
        [baseFieldName]: codeBookItems?.map((cbi) => cbi.id) ?? [],
      }
    }
    default: {
      return {}
    }
  }
}

export const makeInitialFormValues = (args: {
  fields: FieldFragment[]
  getFieldNameSuffix?: (field: FieldFragment) => string
}) => {
  const { fields, getFieldNameSuffix } = args
  return [...(fields || [])]?.reduce((prev: Record<string, unknown>, curr) => {
    const fieldNameSuffix =
      (getFieldNameSuffix ? getFieldNameSuffix(curr) : curr.basedOnFieldId) ?? ''
    const res = mapFieldFragmentToFrontEndFormValue({
      fieldFragment: curr,
      fieldNameSuffix,
    })
    if (!res) {
      return prev
    }
    const resValue = Object.values(res)?.[0]
    const fieldName = makeFieldNameForFieldFragment(fieldNameSuffix)

    // short text values are different from other allowMultiple values:
    // 'multiple' fields of type Record and Address create multiple Fields, whereas
    // 'multiple' fields of type ShortText create a single field with 'strs' populated rather than 'str'
    if (curr.allowMultiple && curr.type !== 'ShortText') {
      return {
        ...prev,
        [fieldName]: [...((prev[fieldName] as any[]) || []), resValue],
      }
    }

    return {
      ...prev,
      [fieldName]: resValue,
    }
  }, {})
}
