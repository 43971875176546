import { useEffect, useState } from 'react'

import {
  faArrowUpFromBracket,
  faChevronDown,
  faChevronRight,
  faPlus,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Tag, Tooltip } from 'antd'
import { styled } from 'styled-components'

import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import useDebounce from '../../hooks/use-debounce'
import { useHover } from '../../hooks/use-hover'
import { TableRecordGenericType } from '../../hooks/use-table'
import { useTheme } from '../../hooks/use-theme'
import { isFunctionAsync } from '../../utils'
import Input from '../form/Input'
import { CardTitle, StylePropsMobile } from '../shared/StyledComponents'
import Text, { TextSize } from '../Typography/Text'

import { constructFilterParams, FilterInputDisplay } from './filters'
import { TableFilters } from './TableFilters'
import { ButtonProps, TableProps } from './types'

interface ContainerProps extends StylePropsMobile {
  $hovered: boolean
  $closed: boolean
}
const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 16px;
  border-bottom: 1px solid
    ${(props) => (props.$hovered ? props.theme.colorPrimaryBase : props.theme.colorSplit)};
  border-left: 1px solid
    ${(props) => (props.$hovered ? props.theme.colorPrimaryBase : props.theme.colorSplit)};
  border-right: 1px solid
    ${(props) => (props.$hovered ? props.theme.colorPrimaryBase : props.theme.colorSplit)};
  border-top: 1px solid
    ${(props) => (props.$hovered ? props.theme.colorPrimaryBase : props.theme.colorSplit)};
  gap: 20px;
  background-color: ${(props) => props.theme.colorFillAlter};
  border-radius: ${(props) => (props.$closed ? '8px' : '8px 8px 0 0')};
  ${(props) => props.$hovered && `cursor: pointer;`}
  min-height: 76px;
`
const TableHeaderLeft = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: calc(100% - 300px);
  flex: 1;
`
const TableHeaderRight = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`
const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`

export function TableHeader<TRow extends TableRecordGenericType>(props: TableProps<TRow>) {
  const {
    count = 0,
    search,
    searchParams,
    filterInputs,
    isReordering,
    renderRowSelection,
    rowSelection,
    currentData,
    hideSearchBar,
    searchBarWidth,
    order,
    headerAccordionState,
  } = props
  const name = props.header?.name
  const buttonProps = props.header?.buttonProps || []
  const accordion = props.header?.accordion
  const [formMethods] = Form.useForm()
  const [csvLoading, setCsvLoading] = useState(false)
  const [filterInputDisplays, setFilterInputDisplays] = useState<FilterInputDisplay[]>([])
  Form.useWatch([], formMethods)
  const query = formMethods.getFieldValue('tableQuery')
  const debouncedQuery = useDebounce<string>(query, 500)
  const values = formMethods.getFieldsValue()
  const stringifiedValue = JSON.stringify(values)
  useEffect(() => {
    const { backendFilterInputs, filterInputDisplays: newFilterInputDisplays } =
      constructFilterParams(values, filterInputs)
    setFilterInputDisplays(newFilterInputDisplays)
    search?.({ query: debouncedQuery, filters: backendFilterInputs, order })
  }, [debouncedQuery, stringifiedValue])
  const { isSmall } = useCurrentBreakpoint()
  const { getTokenVal } = useTheme()
  const small = isSmall(BreakpointComponents.Table)

  const { hoverProps, hovered } = useHover()

  const renderButtons = () => {
    if (!buttonProps) {
      return undefined
    }
    if (accordion && headerAccordionState && !headerAccordionState?.isOpen) {
      return undefined
    }
    const buttonPropsArr = Array.isArray(buttonProps) ? buttonProps : [buttonProps]
    return buttonPropsArr.map((p, index) => (
      <HeaderButton key={`${JSON.stringify(p)}-${index}`} {...p} />
    ))
  }
  const renderRight = () => {
    const selectedRows = currentData?.filter((d) =>
      rowSelection?.selectedRowKeys?.find((key) => `${key}` === `${d.id}`)
    )
    if (renderRowSelection && selectedRows?.length) {
      return renderRowSelection(selectedRows)
    }
    return (
      <>
        {search && (
          <Text size={TextSize.Base} strong noWrap>
            {count.toLocaleString()} {small ? '' : count === 1 ? 'Result' : 'Results'}
          </Text>
        )}
        {isReordering && !small && 'Updating...'}
        {renderButtons()}
        {!small && searchParams?.generateCSV && (
          <Tooltip title="Export as CSV" placement="bottomLeft">
            <Button
              loading={csvLoading}
              type="default"
              onClick={async () => {
                const { backendFilterInputs } = constructFilterParams(values, filterInputs)
                setCsvLoading(true)
                const res = await searchParams?.generateCSV?.({
                  query: debouncedQuery,
                  filters: backendFilterInputs,
                  order,
                })
                setCsvLoading(false)
                if (res) {
                  window.location.href = res
                }
              }}
              icon={<FontAwesomeIcon icon={faArrowUpFromBracket} />}
            />
          </Tooltip>
        )}
      </>
    )
  }

  const renderContent = () => {
    return (
      <Container
        $small={small}
        $hovered={hovered && !!accordion}
        onClick={() => headerAccordionState?.toggle?.()}
        $closed={!!accordion && !headerAccordionState?.isOpen}
        {...hoverProps}
      >
        <TableHeaderLeft>
          {accordion && (
            <Button
              onClick={(e) => {
                e.preventDefault?.()
                e.stopPropagation?.()
                headerAccordionState.toggle()
              }}
              style={hovered ? { borderColor: getTokenVal('colorPrimaryBase') } : {}}
              icon={
                <FontAwesomeIcon
                  icon={headerAccordionState.isOpen ? faChevronDown : faChevronRight}
                  style={hovered ? { color: getTokenVal('colorPrimaryBase') } : {}}
                  size="sm"
                />
              }
              size="small"
            />
          )}
          {search && !hideSearchBar ? (
            <Input
              fieldName="tableQuery"
              placeholder={searchParams?.placeholder || 'Search'}
              noMargin
              width={searchBarWidth || (small ? '80px' : searchBarWidth || 'fit-content')}
              prefix={<FontAwesomeIcon color={getTokenVal('colorIcon')} icon={faSearch} />}
              size="middle"
            />
          ) : (
            <TitleContainer>
              <CardTitle title={name} />
              <Tag>{count}</Tag>
            </TitleContainer>
          )}
          {!!filterInputs?.length && (
            <TableFilters filterInputDisplays={filterInputDisplays} {...props} />
          )}
        </TableHeaderLeft>
        <TableHeaderRight>{renderRight()}</TableHeaderRight>
      </Container>
    )
  }

  // RecordTemplateForm's FieldsTable has a table where the `name` is an <Input />
  // the `<Form>` here would conflict with the Form that that <Input /> is part of
  const wrapInForm = search && !hideSearchBar

  if (wrapInForm) {
    return (
      <Form form={formMethods} layout="vertical">
        {renderContent()}
      </Form>
    )
  }

  return renderContent()
}

export const HeaderButton = (props: ButtonProps) => {
  const [loading, setLoading] = useState(false)
  const isAsync = isFunctionAsync(props.onClick)
  return (
    <Tooltip title={props.tooltip}>
      <Button
        type={props.type || 'primary'}
        onClick={async (e) => {
          if (isAsync) {
            setLoading(true)
          }
          e?.stopPropagation()
          e?.preventDefault()
          await props?.onClick()
          if (isAsync) {
            setLoading(false)
          }
        }}
        loading={props.isLoading || loading}
        disabled={props.disabled}
        icon={
          props.icon ? <FontAwesomeIcon icon={props.icon} /> : <FontAwesomeIcon icon={faPlus} />
        }
      >
        {props.text}
      </Button>
    </Tooltip>
  )
}
