import { Table as AntdTable, TableProps } from 'antd'
import { styled } from 'styled-components'

export interface GovWellTableProps {
  noStyle?: boolean
  noScroll?: boolean
  noPadding?: boolean
}

interface Props<RecordType> extends TableProps<RecordType>, GovWellTableProps {
  showHeader?: boolean
}

interface TableWithoutStyleProps {
  $noPadding: boolean
  $showHeader: boolean
}
const TableWithoutStyle = styled(AntdTable)<TableWithoutStyleProps>`
  .ant-table {
    background-color: inherit;
  }
  .ant-table-thead {
    ${(props) =>
      props.$showHeader
        ? `
      tr > th {
        border-bottom: unset !important;
        padding: 0 0 6px;
      }
      tr > th:before {
        display: none;
      }
    `
        : 'display: none;'}
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${(props) => props.theme.colorSplit};
    ${(props) =>
      props.$showHeader &&
      `
      padding: 6px 0;
    `}
  }
  ${(props) =>
    props.$noPadding &&
    `
      .ant-table-tbody > tr > td {
        padding-left: 0;
        padding-right: 0;
      }
    `}
  .ant-table-tbody > tr:last-child {
    td {
      border-bottom: none;
    }
  }
`

export function Table<RecordType>(props: Props<RecordType>) {
  const { noStyle, noScroll, noPadding, showHeader, ...rest } = props

  const tableProps = {
    ...rest,
    pagination: false as TableProps<any>['pagination'],
    ...(noScroll ? {} : { scroll: { x: 'max-content' } }),
  }

  return noStyle ? (
    <TableWithoutStyle
      {...tableProps}
      // @ts-expect-error
      dataSource={tableProps.dataSource ?? []}
      $noPadding={!!noPadding}
      $showHeader={!!showHeader}
    />
  ) : (
    // @ts-expect-error
    <AntdTable {...tableProps} />
  )
}
