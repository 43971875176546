import { memo, useEffect, useMemo } from 'react'

import { Form } from 'antd'
import type {
  RecordCollectionSelectQuery,
  RecordCollectionSelectQueryVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Select, { BasicOptionType, SelectProps } from '../Select'
import { makeArrayFieldName, makeHiddenFieldName } from '../util'

const QUERY = gql`
  query RecordCollectionSelectQuery($orgSlug: String!) {
    recordCollectionsForOrg(slug: $orgSlug) {
      id
      name
      description
    }
  }
`

export type RecordCollectionSelectValues = {
  selectedIds: 'CREATE' | number | number[]
  recordCollectionNames: string[]
}

type Props = Omit<SelectProps<BasicOptionType>, 'options'> & {
  orgSlug: string
  additionalOptions?: BasicOptionType<string>[]
  disabledRecordCollectionIds?: number[]
}
const RecordCollectionSelect = (props: Props) => {
  const { orgSlug, additionalOptions = [], disabledRecordCollectionIds = [], ...rest } = props
  const { data, loading } = useQuery<
    RecordCollectionSelectQuery,
    RecordCollectionSelectQueryVariables
  >(QUERY, {
    variables: {
      orgSlug,
    },
    fetchPolicy: 'cache-first',
  })

  const baseOptions: BasicOptionType<number>[] = useMemo(
    () =>
      data?.recordCollectionsForOrg?.map((item) => ({
        key: item.id,
        value: item.id,
        label: `${item.name} (${item.description})`,
        disabled: disabledRecordCollectionIds.includes(item.id),
      })) || [],
    [data?.recordCollectionsForOrg, disabledRecordCollectionIds]
  )
  const options = useMemo(
    () => [...baseOptions, ...additionalOptions],
    [additionalOptions, baseOptions]
  )

  const form = Form.useFormInstance()
  Form.useWatch([rest.fieldName], form)
  const value: RecordCollectionSelectValues = form.getFieldValue(rest.fieldName)

  const collectionNamesFieldName = makeHiddenFieldName(
    rest.fieldName,
    null,
    'recordCollectionNames'
  )
  const selectedIds: (number | string)[] = useMemo(
    () =>
      Array.isArray(value?.selectedIds)
        ? value?.selectedIds || []
        : value?.selectedIds
          ? [value.selectedIds]
          : [],
    [value?.selectedIds]
  )
  const stringifiedIds = JSON.stringify(selectedIds)

  useEffect(() => {
    const newTypeNames = options
      .filter((o) => typeof o.value === 'number' && selectedIds.includes(o.value))
      .map((o) => o.label)
    form.setFieldValue(collectionNamesFieldName, newTypeNames)
  }, [stringifiedIds, collectionNamesFieldName, options, form, selectedIds])

  return (
    <>
      <Select
        {...rest}
        fieldName={makeArrayFieldName(rest.fieldName, 'selectedIds')}
        options={loading ? [] : options}
        loading={loading}
      />
      <Form.Item name={collectionNamesFieldName} hidden>
        <input type="hidden" />
      </Form.Item>
    </>
  )
}

export default memo(RecordCollectionSelect)
