import { ReactNode } from 'react'

import { EvervaultProvider } from '@evervault/react'
import { ForeignValueType } from 'types/graphql'

import { EnvVariables } from 'src/constants'

type GovWellEvervaultProviderProps = {
  children: React.ReactNode
}

export const GovWellEvervaultProvider = ({ children }: GovWellEvervaultProviderProps) => {
  return (
    <EvervaultProvider
      appId={`${EnvVariables.EvervaultAppId}`}
      teamId={`${EnvVariables.EvervaultTeamId}`}
    >
      {children}
    </EvervaultProvider>
  )
}

export const ForeignValueTypeLengthMap: Record<ForeignValueType, number> = {
  SocialSecurityNumber: 9,
  EmployerIdentificationNumber: 9,
  Other: 10, // TODO: we can't make arbitrary-length foreign values!
}

export const ForeignValueTypeMaskMap: Record<ForeignValueType, ReactNode> = {
  SocialSecurityNumber: (
    <>&lowast;&lowast;&lowast;-&lowast;&lowast;-&lowast;&lowast;&lowast;&lowast;</>
  ),
  EmployerIdentificationNumber: (
    <>&lowast;&lowast;-&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;</>
  ),
  Other: '***',
}
