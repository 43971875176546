import { ReactNode } from 'react'

import { Col, Row } from 'antd'
import { styled } from 'styled-components'

import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'

interface Props {
  left: ReactNode
  right: ReactNode
  sizes?: {
    left: number
    right: number
    leftFullHeight?: boolean
    rightFullHeight?: boolean
  }
  smallScreenBottomMargin?: boolean
}

interface ContainerProps {
  $marginBottom?: boolean
  $fullHeight?: boolean
}
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.$marginBottom && `margin-bottom: 24px;`}
  ${(props) => props.$fullHeight && `height: 100%;`}
`

export const LeftRightView = (props: Props) => {
  const { left, right, sizes, smallScreenBottomMargin = true } = props

  const { isSmallScreen } = useCurrentBreakpoint()
  const small = isSmallScreen()

  return (
    <Row
      gutter={24}
      style={{
        width: '100%',
        ...(small
          ? {
              marginLeft: 0,
              marginRight: 0,
            }
          : {}),
      }}
    >
      <Col xs={24} md={sizes?.left || 16}>
        <Container
          $marginBottom={smallScreenBottomMargin && small}
          $fullHeight={sizes?.leftFullHeight}
        >
          {left}
        </Container>
      </Col>
      <Col xs={24} md={sizes?.right || 8} style={{ position: 'sticky', top: 0, height: '100%' }}>
        <Container $fullHeight={sizes?.rightFullHeight}>{right}</Container>
      </Col>
    </Row>
  )
}
