export const PlanReviewFileFragment = gql`
  fragment PlanReviewFileFragment on File {
    ...FileFragment
    pageProcessingFinishedAt
    pages {
      ...FilePageFragment
    }
  }
`
export const PlanReviewFieldGroupFragment = gql`
  fragment PlanReviewFieldGroupFragment on FieldGroup {
    id
    fields {
      ...PlanReviewFieldFragment
    }
  }
  fragment PlanReviewFieldFragment on Field {
    id
    label
    file {
      ...PlanReviewFileFragment
    }
    files {
      ...PlanReviewFileFragment
    }
  }
`
