import {
  ArchiveCodeBookMutation,
  ArchiveCodeBookMutationVariables,
  CreateCodeBookMutation,
  CreateCodeBookMutationVariables,
  GetCodeBooksQuery,
  UpdateCodeBookMutation,
  UpdateCodeBookMutationVariables,
} from '../../types/graphql'
import { QueryKey, useMutation, useQuery } from '../utils/queryClient'

const CreateMutation = gql`
  mutation CreateCodeBookMutation($input: CreateCodeBookInput!) {
    createCodeBook(input: $input) {
      id
    }
  }
`

export const useCreateCodeBookMutation = () => {
  return useMutation<CreateCodeBookMutation, CreateCodeBookMutationVariables>({
    mutationDocument: CreateMutation,
  })
}

const Get = gql`
  query GetCodeBooksQuery {
    codeBooks {
      ...CodeBookFragment
    }
  }
`

export const useCodeBooksQuery = () => {
  return useQuery<GetCodeBooksQuery>({
    queryKey: [QueryKey.CodeBooks],
    queryDocument: Get,
  })
}

const UpdateMutation = gql`
  mutation UpdateCodeBookMutation($id: Int!, $input: CreateCodeBookInput!) {
    updateCodeBook(id: $id, input: $input) {
      id
    }
  }
`

export const useUpdateCodeBookMutation = () => {
  return useMutation<UpdateCodeBookMutation, UpdateCodeBookMutationVariables>({
    mutationDocument: UpdateMutation,
  })
}

const ArchiveMutation = gql`
  mutation ArchiveCodeBookMutation($id: Int!) {
    archiveCodeBook(id: $id) {
      id
    }
  }
`

export const useArchiveCodeBookMutation = () => {
  return useMutation<ArchiveCodeBookMutation, ArchiveCodeBookMutationVariables>({
    mutationDocument: ArchiveMutation,
  })
}
