import { Flex } from 'antd'
import { TableContext } from 'govwell-ui/components/Table/context'
import TableContent from 'govwell-ui/components/Table/TableContent'
import TableControls from 'govwell-ui/components/Table/TableControls'
import { Size } from 'govwell-ui/types'

type Props = { children?: React.ReactNode; size?: Size }
const TableBase = ({ children, size }: Props) => (
  <Flex vertical gap="6px">
    <TableContext.Provider value={{ size }}>{children}</TableContext.Provider>
  </Flex>
)

export default Object.assign(TableBase, {
  Content: TableContent,
  Controls: TableControls,
})
