import { Switch as AntdSwitch, Form, SwitchProps } from 'antd'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import styled from 'styled-components'

import Text, { TextSize } from '../Typography/Text'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

interface Props extends FormFieldWrapperProps {
  onChange?: SwitchProps['onChange']
  disabled?: boolean
  leftText?: string
  rightText?: string
}

const Container = styled.div`
  display: flex;
  gap: 12px;
`

export function Switch(props: Props) {
  const { disabled, leftText, rightText, ...formFieldWrapperProps } = props
  const { fieldName } = formFieldWrapperProps
  const form = Form.useFormInstance()
  Form.useWatch(fieldName, form)
  const { setFieldValue, getFieldValue } = form
  const value = getFieldValue(fieldName)
  const onChange: SwitchChangeEventHandler = async (checked, event) => {
    setFieldValue(fieldName, checked)
    props.onChange?.(checked, event)
  }
  return (
    <Wrapper {...formFieldWrapperProps} valuePropName="checked">
      <Container>
        {leftText && <Text size={TextSize.Base}>{leftText}</Text>}
        <AntdSwitch
          size="default"
          disabled={disabled}
          style={{ width: '44px', height: '22px' }}
          value={value}
          checked={value}
          onChange={onChange}
        />
        {rightText && <Text size={TextSize.Base}>{rightText}</Text>}
      </Container>
    </Wrapper>
  )
}

export default Switch
