import { faUser } from '@fortawesome/pro-regular-svg-icons'

import { formatUserName } from 'src/utils'

import { UserDataFragment } from '../../../types/graphql'
import { useReassignRecordTaskInspectionAttemptMutation } from '../../fetch/recordTaskInspectionAttempts'
import { OwnerSelect } from '../form/shared/OwnerSelect'
import Modal from '../Modal'
import Text, { TextSize } from '../Typography/Text'

import { InspectionMetadata } from './InspectionMetadata'
import { InspectionsTabModalProps } from './types'

interface Props extends InspectionsTabModalProps {
  currentInspector: UserDataFragment | null | undefined
}

interface FormValues {
  inspectorUserId: number
}

export const ReassignInspectionModal = (props: Props) => {
  const { modalState, currentInspector, recordTaskInspectionAttemptId, refetch } = props
  const isAssigned = !!currentInspector
  const { mutateAsync: reassign } = useReassignRecordTaskInspectionAttemptMutation()
  const onOk = async (formValues: FormValues) => {
    const { inspectorUserId } = formValues
    await reassign({
      id: recordTaskInspectionAttemptId,
      input: { inspectorUserId },
    })
    await refetch()
    modalState.close()
  }

  const title = isAssigned ? `Reassign Inspection` : `Assign Inspection`

  return (
    <Modal {...modalState} form icon={faUser} title={title} okText={title} onOk={onOk}>
      <InspectionMetadata {...props} />
      <Text size={TextSize.Large} margin="0 0 12px 0">
        This inspection is currently{' '}
        {isAssigned ? `assigned to ${formatUserName(currentInspector)}` : 'unassigned'}. Please
        select an employee to {isAssigned ? 'reassign' : 'assign'} it to.
      </Text>
      <OwnerSelect
        label="Inspector"
        fieldName="inspectorUserId"
        caption={`The inspector you'd like to ${
          isAssigned ? 'reassign' : 'assign'
        } this inspection to.`}
        placeholder="Select an inspector"
        required
        errorMessage="You must select an inspector"
        disabledUserIds={currentInspector ? [currentInspector.id] : []}
        filterUser={(u: UserDataFragment) => !!u.isInspector}
      />
    </Modal>
  )
}
