import {
  CreateLocationSnapshotMutation,
  CreateLocationSnapshotMutationVariables,
  SearchGISMutation,
  SearchGISMutationVariables,
  SearchMapMutation,
  SearchMapMutationVariables,
} from '../../types/graphql'
import { useMutation } from '../utils/queryClient'

const SearchMap = gql`
  mutation SearchMapMutation($input: SearchMapInput!) {
    searchMap(input: $input) {
      results {
        ...GISPropertyFragment
      }
    }
  }
  fragment GISPropertyFragment on GISProperty {
    id
    address
    parcelId
    center {
      latitude
      longitude
    }
  }
`
export const useSearchMapMutation = () => {
  return useMutation<SearchMapMutation, SearchMapMutationVariables>({
    mutationDocument: SearchMap,
  })
}

export const CreateLocationSnapshot = gql`
  mutation CreateLocationSnapshotMutation($input: CreateLocationSnapshotInput!) {
    createLocationSnapshot(input: $input) {
      ...CreatedLocationSnapshotFragment
    }
  }
  fragment CreatedLocationSnapshotFragment on LocationSnapshot {
    id
    locationObjectId
    fields {
      key
      value
    }
    owner
  }
`
export const useCreateLocationSnapshotMutation = () => {
  return useMutation<CreateLocationSnapshotMutation, CreateLocationSnapshotMutationVariables>({
    mutationDocument: CreateLocationSnapshot,
  })
}

const SearchGIS = gql`
  mutation SearchGISMutation($input: SearchGISInput!) {
    searchGIS(input: $input) {
      results {
        ...GISPropertySearchFragment
      }
    }
  }
  fragment GISPropertySearchFragment on GISProperty {
    id
    address
    parcelId
  }
`

export const useSearchGISMutation = () => {
  return useMutation<SearchGISMutation, SearchGISMutationVariables>({
    mutationDocument: SearchGIS,
  })
}
