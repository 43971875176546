import { DateDirection } from 'types/graphql'

import Select, { BasicOptionType, SelectProps } from 'src/components/form/Select'
import { DateFilterDirectionNamesByDirection } from 'src/components/TableViews/util'

const options: BasicOptionType<DateDirection>[] = [
  {
    label: DateFilterDirectionNamesByDirection['Past'],
    value: 'Past',
  },
  {
    label: DateFilterDirectionNamesByDirection['Future'],
    value: 'Future',
  },
]

type Props = Omit<SelectProps<BasicOptionType<DateDirection>>, 'options'>
const DateFilterDirectionSelect = (props: Props) => {
  return <Select options={options} {...props} />
}

export default React.memo(DateFilterDirectionSelect)
