import { useCallback, useMemo } from 'react'

import { Button, Collapse, CollapseProps, Flex } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { DateDirection, DelayType } from 'types/graphql'

import DatePicker, { DatePickerAllowedDates } from 'src/components/form/DatePicker'
import Input, { InputTypes } from 'src/components/form/Input'
import { FormContainer } from 'src/components/shared/StyledComponents'
import DateFilterDelayTypeSelect from 'src/components/TableViews/DateFilterDelayTypeSelect'
import DateFilterDirectionSelect from 'src/components/TableViews/DateFilterDirectionSelect'
import Text from 'src/components/Typography/Text'
import { TableViewFilter } from 'src/models/TableViews/TableViewFilter'
import { DateFormats } from 'src/utils/date'

const StyledPresetButton = styled(Button)<{ $isActive: boolean }>`
  ${({ $isActive, theme }) => {
    if ($isActive) {
      return `
        color: ${theme.colorPrimaryBase};
        border-color: ${theme.colorPrimaryBase};
      `
    }
  }}
`

enum CollapseMenuKey {
  Presets = 'Presets',
  Exact = 'Exact',
  RelativeToToday = 'RelativeToToday',
}

type Props = {
  filter: TableViewFilter
}
const DateFilter = ({ filter }: Props) => {
  const [form] = useForm()

  const defaultViewFilterDateType: CollapseMenuKey = ['IsNull', 'IsNotNull', 'Today'].includes(
    filter.date?.type
  )
    ? CollapseMenuKey.Presets
    : filter.date?.type === 'RelativeToToday'
      ? CollapseMenuKey.RelativeToToday
      : CollapseMenuKey.Exact

  const handleFilterTypeChange = useCallback(
    (activeKeys: string[] | string) => {
      const activeKey = Array.isArray(activeKeys) ? activeKeys[0] : activeKeys

      const resetExactFilter = () => {
        runInAction(() => {
          filter.date.exactStart = undefined
          filter.date.exactEnd = undefined
        })
      }

      const resetRelativeToTodayFilter = () => {
        runInAction(() => {
          filter.date.relativeToTodayCount = undefined
          filter.date.relativeToTodayDelayType = undefined
          filter.date.relativeToTodayDirection = undefined
        })
      }

      runInAction(() => {
        if (activeKey === CollapseMenuKey.Exact) {
          const now = dayjs()
          const nowFormatted = now.format(DateFormats.ISO8601Standard)
          filter.date.type = 'Exact'
          form.setFieldsValue({
            exactDate: [now, now],
          })
          filter.date.exactStart = nowFormatted
          filter.date.exactEnd = nowFormatted
          resetRelativeToTodayFilter()
        }
        if (activeKey === CollapseMenuKey.Presets) {
          filter.date.type = 'IsNull'
          resetExactFilter()
          resetRelativeToTodayFilter()
        }
        if (activeKey === CollapseMenuKey.RelativeToToday) {
          form.setFieldsValue({
            relativeToTodayCount: 1,
            relativeToTodayDelayType: 'Month',
            relativeToTodayDirection: 'Past',
          })
          filter.date.type = 'RelativeToToday'
          resetExactFilter()
          filter.date.relativeToTodayCount = 1
          filter.date.relativeToTodayDelayType = 'Month'
          filter.date.relativeToTodayDirection = 'Past'
        }
      })
    },
    [filter.date, form]
  )

  const handleExactDatesChanged = useCallback(
    (dates: [Dayjs | null, Dayjs | null] | null) => {
      runInAction(() => {
        if (!dates) {
          filter.date.exactStart = undefined
          filter.date.exactEnd = undefined
        } else {
          filter.date.type = 'Exact'
          filter.date.exactStart = dates[0]?.format(DateFormats.ISO8601Standard)
          filter.date.exactEnd = dates[1]?.format(DateFormats.ISO8601Standard)
        }
      })
    },
    [filter.date]
  )

  const handleIsNullSelected = useCallback(() => {
    runInAction(() => {
      filter.date.type = 'IsNull'
    })
  }, [filter.date])

  const handleIsNotNullSelected = useCallback(() => {
    runInAction(() => {
      filter.date.type = 'IsNotNull'
    })
  }, [filter.date])

  const handleTodaySelected = useCallback(() => {
    runInAction(() => {
      filter.date.type = 'Today'
    })
  }, [filter.date])

  const handleRelativeToTodayDirectionChanged = useCallback(
    (value: DateDirection) => {
      runInAction(() => {
        filter.date.relativeToTodayDirection = value
      })
    },
    [filter.date]
  )

  const handleRelativeToTodayCountChanged = useCallback(
    (value: string | undefined) => {
      if (!value || !parseInt(value)) {
        return
      }
      runInAction(() => {
        filter.date.relativeToTodayCount = parseInt(value)
      })
    },
    [filter.date]
  )

  const handleRelativeToTodayDelayTypeChanged = useCallback(
    (value: DelayType) => {
      runInAction(() => {
        filter.date.relativeToTodayDelayType = value
      })
    },
    [filter.date]
  )

  const items: CollapseProps['items'] = useMemo(
    () => [
      {
        key: CollapseMenuKey.Exact,
        label: <Text>Exact dates</Text>,
        children: (
          <DatePicker
            fieldName="exactDate"
            noMargin
            range
            allowedDates={DatePickerAllowedDates.All}
            isStartDateRequired={false}
            isEndDateRequired={false}
            onValueChange={handleExactDatesChanged}
          />
        ),
      },
      {
        key: CollapseMenuKey.Presets,
        label: <Text>Presets</Text>,
        children: (
          <Flex justify="space-around" gap="12px">
            <StyledPresetButton
              onClick={handleIsNullSelected}
              type="default"
              $isActive={filter.date.type === 'IsNull'}
            >
              Is empty
            </StyledPresetButton>
            <StyledPresetButton
              onClick={handleIsNotNullSelected}
              type="default"
              $isActive={filter.date.type === 'IsNotNull'}
            >
              Is not empty
            </StyledPresetButton>
            <StyledPresetButton
              onClick={handleTodaySelected}
              type="default"
              $isActive={filter.date.type === 'Today'}
            >
              Today
            </StyledPresetButton>
          </Flex>
        ),
      },
      {
        key: CollapseMenuKey.RelativeToToday,
        label: <Text>Relative to today</Text>,
        children: (
          <Flex align="center" gap="6px">
            <DateFilterDirectionSelect
              fieldName="relativeToTodayDirection"
              noMargin
              onChange={handleRelativeToTodayDirectionChanged}
            />
            <Input
              fieldName="relativeToTodayCount"
              type={InputTypes.Number}
              min={1}
              max={1000}
              size="middle"
              noMargin
              noIcon
              onBlur={handleRelativeToTodayCountChanged}
            />
            <DateFilterDelayTypeSelect
              fieldName="relativeToTodayDelayType"
              noMargin
              isPlural={(filter.date.relativeToTodayCount ?? 0) > 1}
              onChange={handleRelativeToTodayDelayTypeChanged}
            />
          </Flex>
        ),
      },
    ],
    [
      filter.date.relativeToTodayCount,
      filter.date.type,
      handleExactDatesChanged,
      handleIsNotNullSelected,
      handleIsNullSelected,
      handleRelativeToTodayCountChanged,
      handleRelativeToTodayDelayTypeChanged,
      handleRelativeToTodayDirectionChanged,
      handleTodaySelected,
    ]
  )
  return (
    <FormContainer
      form={form}
      initialValues={{
        exactDate: [
          filter.date.exactStart ? dayjs(filter.date.exactStart) : null,
          filter.date.exactEnd ? dayjs(filter.date.exactEnd) : null,
        ],
        relativeToTodayDirection: filter.date.relativeToTodayDirection,
        relativeToTodayCount: filter.date.relativeToTodayCount,
        relativeToTodayDelayType: filter.date.relativeToTodayDelayType,
      }}
    >
      <Collapse
        accordion
        items={items}
        defaultActiveKey={defaultViewFilterDateType}
        onChange={handleFilterTypeChange}
      />
    </FormContainer>
  )
}

export default observer(DateFilter)
