import { useCallback } from 'react'

import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { faColumns3 } from '@fortawesome/pro-regular-svg-icons'
import { computed, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ViewColumnType } from 'types/graphql'

import { Drawer } from 'src/components/Drawer'
import HiddenColumnsHeader, {
  HIDDEN_SECTION_HEADER_KEY,
} from 'src/components/TableViews/ManageColumnsDrawer/HiddenColumnsHeader'
import ManageColumnsDrawerItem from 'src/components/TableViews/ManageColumnsDrawer/ManageColumnsDrawerItem'
import { StyledHeader } from 'src/components/TableViews/ManageColumnsDrawer/styled-components'
import { useUpdateViewMutation } from 'src/fetch/views'
import { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewColumn } from 'src/models/TableViews/TableViewColumn'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

type Props = {
  modalState: UseDisclosureReturn
  tableViewManager: TableViewManager
  view: TableView
}
const ManageColumnsDrawer = ({ modalState, tableViewManager, view }: Props) => {
  const columnManager = view.columnManager
  const { mutateAsync: updateView } = useUpdateViewMutation()

  const saveChanges = useCallback(async () => {
    await runInAction(async () => {
      await updateView({
        id: view.id,
        input: tableViewManager.getCreateViewInput(view.databaseProtocol), // Here, intentionally NOT saving the client changes to filters and sorts by using `databaseProtocol`; the invariant is that column changes should never stay unsaved
      })
    })
  }, [tableViewManager, updateView, view.databaseProtocol, view.id])

  const handleColumnTypeToggled = useCallback(
    async (columnType: ViewColumnType) => {
      runInAction(() => {
        columnManager.toggleColumnTypeVisibility(columnType)
      })
      await saveChanges()
    },
    [columnManager, saveChanges]
  )

  const handleColumnsReordered = useCallback(
    async (e: DragEndEvent) => {
      const draggedColumnType = e.active?.id as ViewColumnType
      const displacedColumnType = e.over?.id as ViewColumnType | string
      await runInAction(async () => {
        const sourceIndex = columnManager.columns.findIndex(
          (c) => c.columnType === draggedColumnType
        )
        const destinationIndex = columnManager.columns.findIndex(
          (c) => c.columnType === displacedColumnType
        )
        const newColumns = [...columnManager.columns]

        // Dragged column type was hidden, create a column
        if (sourceIndex < 0) {
          const columnTemplate = tableViewManager.columnTemplates.find(
            (ct) => ct.columnType === draggedColumnType
          )
          if (!columnTemplate) {
            return
          }
          const newColumn = new TableViewColumn({
            columnTemplate,
            columnType: draggedColumnType,
            displayIndex: destinationIndex,
          })
          // Column dragged to end of visible columns
          if (displacedColumnType === HIDDEN_SECTION_HEADER_KEY) {
            newColumns.push(newColumn)
          }
          // Column dragged into beginning or middle of visible columns
          else if (destinationIndex >= 0) {
            newColumns.splice(destinationIndex, 0, newColumn)
          }
        }
        // Dragged column type was visible but is becoming hidden
        else if (destinationIndex < 0) {
          if (columnManager.columns.length <= 1) {
            return // don't allow hiding all columns
          }
          newColumns.splice(sourceIndex, 1)
        }
        // Visible columns are rearranged
        else {
          const sourceColumn = newColumns.splice(sourceIndex, 1)[0]
          newColumns.splice(destinationIndex, 0, sourceColumn)
        }

        columnManager.columns = newColumns
        await saveChanges()
      })
    },
    [columnManager, saveChanges, tableViewManager.columnTemplates]
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  )

  const hiddenColumnTemplates = computed(() =>
    tableViewManager.columnTemplates
      .filter((ct) => !columnManager.columnsByColumnType.has(ct.columnType))
      .sort((a, b) => a.defaultLabelSentenceCase.localeCompare(b.defaultLabelSentenceCase))
  ).get()

  const keys = computed(() => [
    ...columnManager.columns.map((c) => c.columnType),
    HIDDEN_SECTION_HEADER_KEY,
    ...hiddenColumnTemplates.map((ct) => ct.columnType),
  ]).get()

  return (
    <Drawer {...modalState} title="Manage Columns" icon={faColumns3} width="300px">
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleColumnsReordered}
      >
        <SortableContext items={keys} strategy={verticalListSortingStrategy}>
          <StyledHeader>Shown in table</StyledHeader>
          {columnManager.columns.map((column) => (
            <ManageColumnsDrawerItem
              columnTemplate={column.columnTemplate}
              isVisible
              onColumnTypeToggled={handleColumnTypeToggled}
              key={column.columnType}
            />
          ))}
          <HiddenColumnsHeader />
          {hiddenColumnTemplates.map((ct) => (
            <ManageColumnsDrawerItem
              columnTemplate={ct}
              isVisible={false}
              onColumnTypeToggled={handleColumnTypeToggled}
              key={ct.columnType}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Drawer>
  )
}

export default observer(ManageColumnsDrawer)
