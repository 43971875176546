import { Button, Dropdown, Form, Tag } from 'antd'
import styled from 'styled-components'

import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { TableRecordGenericType } from '../../hooks/use-table'

import { RenderedFilterInput } from './FilterInput'
import { FilterInputDisplay } from './filters'
import { FilterInputRenderType, TableProps } from './types'

interface Props<RecordType extends TableRecordGenericType> extends TableProps<RecordType> {
  filterInputDisplays: FilterInputDisplay[]
}

const FilterChipsContainer = styled.div`
  flex: 1;
  display: flex;
  overflow-x: auto;
`

export function TableFilters<RecordType extends TableRecordGenericType>(props: Props<RecordType>) {
  const { filterInputs, filterInputDisplays } = props
  const { setFieldValue } = Form.useFormInstance()
  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.Table)
  return (
    <>
      <Dropdown
        trigger={['click']}
        dropdownRender={() => (
          <FilterContainer>
            {filterInputs?.map((fi, index) => (
              <RenderedFilterInput
                key={`filter-input-${index}`}
                renderType={FilterInputRenderType.Collapse}
                filterInput={fi}
              />
            ))}
          </FilterContainer>
        )}
      >
        <Button size="middle">{small ? 'Filter' : 'Add Filter'}</Button>
      </Dropdown>
      <FilterChipsContainer>
        {filterInputDisplays.map((filterInputDisplay) => (
          <Tag
            closable
            key={`filter-input-tag-${filterInputDisplay.fieldName as string}`}
            onClose={() => setFieldValue(filterInputDisplay.fieldName, undefined)}
          >
            {filterInputDisplay.display}
          </Tag>
        ))}
      </FilterChipsContainer>
    </>
  )
}
const FilterContainer = styled.div`
  width: 276px;
  padding: 16px;
  background-color: ${(props) => props.theme.colorBgElevated};
  box-shadow: ${(props) => props.theme.boxShadowSecondary};
  border-radius: 8px;
  max-height: 60vh;
  overflow-y: scroll;
`
