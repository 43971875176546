import { ReactNode } from 'react'

import { Form } from 'antd'
import { FormInstance, Rule } from 'antd/es/form'
import { styled } from 'styled-components'

import Text, { TextSize } from '../Typography/Text'

import { Label } from './Label'
import { CaptionContainer } from './StyledComponents'

export interface FormFieldWrapperProps<InputType = any> {
  label?: ReactNode
  fieldName: string | string[]
  errorMessage?: string
  required?: boolean
  caption?: ReactNode
  noMargin?: boolean
  tooltip?: string
  rules?: Rule[]
  initialValue?: InputType
  valuePropName?: string
  width?: string
  disabled?: boolean
  noValidation?: boolean
  stopPropagation?: boolean
  fieldNamePrefix?: string // the top-level item for form parent fields i.e. <Form.List name="fieldNamePrefix">
}

interface WrapperChildParams {
  value: any
  hasError: boolean
  formInstance: FormInstance<any>
}

interface Props<InputType> extends FormFieldWrapperProps<InputType> {
  children: ReactNode | ((params: WrapperChildParams) => ReactNode)
}

interface ContainerProps {
  $width: string | undefined
}
const Container = styled.div<ContainerProps>`
  width: ${(props) => props.$width || '100%'};
  .ant-form-item-explain-error {
    margin: 8px 0 24px;
  }
  .ant-switch-handle::before {
    height: 18px;
    width: 18px;
    border-radius: 16px;
  }
  .ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 20px);
  }
  .ant-form-item-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .ant-form-item-label {
    padding: 0 0 3px;
  }
`

export const Wrapper = <TInput,>(props: Props<TInput>) => {
  const {
    children,
    fieldName,
    label,
    errorMessage,
    required,
    caption,
    noMargin,
    tooltip,
    initialValue,
    valuePropName,
    width,
    noValidation,
    stopPropagation,
    fieldNamePrefix,
  } = props
  const getRules = () => {
    if (noValidation) {
      return []
    }
    if (props.rules) {
      return props.rules
    }
    return errorMessage ? [{ required: true, message: errorMessage }] : []
  }
  const rules = getRules()

  const form = Form.useFormInstance()
  Form.useWatch(fieldName, form)
  const { getFieldError, getFieldValue } = form

  const fieldNameForLookup = Array.isArray(fieldName) ? [fieldNamePrefix, ...fieldName] : fieldName
  const hasError = getFieldError(fieldNameForLookup)?.length > 0
  const value = getFieldValue(fieldNameForLookup)

  const shouldUpdate = (prevValues: Record<string, any>, newValues: Record<string, any>) => {
    const get = (values: Record<string, any>) => {
      if (Array.isArray(fieldName)) {
        return getValueByPath(values, fieldName)
      }
      return values[fieldName]
    }
    return get(prevValues) !== get(newValues)
  }

  return (
    <Container
      $width={width}
      onClick={(e) => (stopPropagation ? e.stopPropagation() : {})}
      id={Array.isArray(fieldName) ? fieldName.join('-') : fieldName}
    >
      <Form.Item
        label={label && <Label label={label} tooltip={tooltip} />}
        // @ts-expect-error since TInput defaults to unknown, the form data is not expected to have more than 1 level of depth
        name={fieldName}
        shouldUpdate={shouldUpdate}
        rules={rules}
        {...(initialValue ? { initialValue } : {})}
        {...(valuePropName ? { valuePropName } : {})}
        required={required}
        extra={
          !hasError &&
          caption && (
            <CaptionContainer>
              <Text size={TextSize.Small} inline colorToken="colorTextTertiary">
                {caption}
              </Text>
            </CaptionContainer>
          )
        }
        style={noMargin ? { margin: 0 } : {}}
      >
        {typeof children === 'function'
          ? children({ hasError, value, formInstance: form })
          : children}
      </Form.Item>
    </Container>
  )
}

export default Wrapper

function getValueByPath(obj: any, path: string[]): any {
  let current = obj
  for (const field of path) {
    if (current?.[field] === undefined) {
      return undefined
    }
    current = current[field]
  }
  return current
}
