import { styled } from 'styled-components'

import { Logo, LogoColor } from '../../components/svgs/Logo'
import { TopBarContainer, TopbarTitle } from '../../components/Topbar/TopbarTemplate'

const TopbarTitleContainer = styled.div`
  flex: 1;
`

export const LoginTopbar = () => (
  // TODO: Use `colorMenuDarkBg` in place of this magic color
  <TopBarContainer $color="rgba(10, 46, 87, 1)">
    <TopbarTitleContainer>
      <TopbarTitle>
        <Logo color={LogoColor.White} />
      </TopbarTitle>
    </TopbarTitleContainer>
  </TopBarContainer>
)
