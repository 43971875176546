export const RecipientContactFieldFragment = gql`
  fragment RecipientContactFieldFragment on Field {
    id
    basedOnFieldId
    type
    contact {
      ...ContactFragment
    }
    record {
      id
      contactField {
        id
        contact {
          ...ContactFragment
        }
      }
      recordTemplate {
        id
        recordType {
          id
          isContact
        }
      }
    }
  }
`
