import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import dayjs, { Dayjs } from 'dayjs'

import { useRequestRecordTaskInspectionAttemptMutation } from '../../fetch/recordTaskInspectionAttempts'
import DatePicker, { DatePickerAllowedDates } from '../form/DatePicker'
import TextArea from '../form/TextArea'
import Modal from '../Modal'

import InspectionSchedulingInstructions from './InspectionSchedulingInstructions'
import { InspectionsTabModalProps } from './types'

interface FormValues {
  date: Dayjs
  schedulingNotes: string
}

export const RequestInspectionModal = (props: InspectionsTabModalProps) => {
  const {
    modalState,
    recordTaskInspectionAttemptId,
    inspectionTemplateName,
    refetch,
    inspectionCutoffTime,
    inspectionSchedulingInstructions,
  } = props
  const { mutateAsync: requestInspection } = useRequestRecordTaskInspectionAttemptMutation()
  const onOk = async (formValues: FormValues) => {
    const { date, schedulingNotes } = formValues
    await requestInspection({
      id: recordTaskInspectionAttemptId,
      input: {
        requestedFor: dayjs(date).toISOString(),
        schedulingNotes,
      },
    })
    await refetch()
    modalState.close()
  }

  return (
    <Modal
      {...modalState}
      form
      icon={faCalendar}
      title={`Request Inspection: ${inspectionTemplateName}`}
      okText="Request inspection"
      onOk={onOk}
    >
      {inspectionSchedulingInstructions && (
        <InspectionSchedulingInstructions
          inspectionSchedulingInstructions={inspectionSchedulingInstructions}
        />
      )}
      <DatePicker
        fieldName="date"
        label="Requested Inspection Date"
        required
        errorMessage="You must enter a date"
        allowedDates={DatePickerAllowedDates.Future}
        disableWeekends
        cutoffTime={inspectionCutoffTime}
      />
      <TextArea
        fieldName="schedulingNotes"
        label="Scheduling Notes"
        placeholder="e.g. I will be home between 2pm and 4pm."
        caption="You may add some notes for the staff."
      />
    </Modal>
  )
}
