import { useEffect, useState } from 'react'

import { UniversalSearchQuery, UniversalSearchQueryVariables } from '../../types/graphql'
import { QueryKey, useQuery } from '../utils/queryClient'

const Search = gql`
  query UniversalSearchQuery($input: UniversalSearchInput!) {
    universalSearch(input: $input) {
      ...UniversalSearchResultFragment
    }
  }
  fragment UniversalSearchResultFragment on UniversalSearchResult {
    records {
      uuid
      id
      identifier
      issuedIdentifier
      addressField {
        ...AddressFieldFragment
      }
      recordTemplate {
        id
        recordType {
          id
          name
          recordName
        }
      }
    }
    parcels
    addresses
    violations {
      id
      identifier
      address
    }
  }
`
export const useUniversalSearchQuery = (query: string) => {
  const [placeholderData, setPlaceholderData] = useState<UniversalSearchQuery>()
  const queryResult = useQuery<UniversalSearchQuery, UniversalSearchQueryVariables>({
    queryDocument: Search,
    queryKey: [QueryKey.UniversalSearch, query],
    variables: { input: { query } },
    enabled: !!query,
    placeholderData,
  })
  useEffect(() => {
    if (query) {
      setPlaceholderData(queryResult.data)
    } else {
      setPlaceholderData(undefined)
    }
  }, [query, queryResult.data])
  return queryResult
}
