import { faSort, faSortAsc, faSortDesc } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Column } from '@tanstack/react-table'
import { Flex } from 'antd'

import { useTheme } from 'src/hooks/use-theme'

type Props<TData> = {
  column: Column<TData, unknown>
}
const TableSortIndicator = <TData,>({ column }: Props<TData>) => {
  const theme = useTheme()
  if (!column.getCanSort()) {
    return null
  }
  return (
    <Flex align="flex-end">
      {column.getIsSorted() === 'desc' ? (
        <FontAwesomeIcon icon={faSortDesc} color={theme.getTokenVal('colorPrimaryBase')} />
      ) : column.getIsSorted() === 'asc' ? (
        <FontAwesomeIcon icon={faSortAsc} color={theme.getTokenVal('colorPrimaryBase')} />
      ) : (
        <FontAwesomeIcon icon={faSort} color={theme.getTokenVal('colorTextDisabled')} />
      )}
    </Flex>
  )
}

export default TableSortIndicator
