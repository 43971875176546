import { ReactNode, useState } from 'react'

import { IconDefinition, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select as AntdSelect, SelectProps as AntdSelectProps, Form, Tooltip } from 'antd'
import styled from 'styled-components'

import { LottiesLoading } from '../../lotties/loading'
import LottieAnimation from '../Lottie'
import { LabelSublabelOption } from '../shared/StyledComponents'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

export interface BasicOptionType<T = string | number> {
  label: ReactNode
  sublabel?: ReactNode
  value: T
  disabled?: boolean
  children?: string
  tooltip?: string
  icon?: IconDefinition
}

export interface SelectProps<TOption extends BasicOptionType> extends FormFieldWrapperProps {
  placeholder?: React.ReactNode
  options: TOption[]
  renderOption?: (option: TOption, index: number) => ReactNode
  filterOption?: (input: string, value: TOption['value']) => boolean
  notFoundMessage?: string
  mode?: AntdSelectProps['mode']
  size?: AntdSelectProps['size']
  optionLabelProp?: string
  initialValue?: TOption
  onChange?: (value: TOption['value']) => void | Promise<unknown> | Promise<void>
  renderOtherOptionInput?: () => ReactNode
  hideSearch?: boolean
  limitOne?: boolean
  disabled?: boolean
  longList?: boolean
  suffixIcon?: IconDefinition
  loading?: boolean
  allowClear?: boolean
}

export const SelectNotFoundContent = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SelectOptionOther = 'Other'

export function Select<TOption extends BasicOptionType>(props: SelectProps<TOption>) {
  const {
    options,
    filterOption,
    notFoundMessage,
    mode,
    optionLabelProp,
    onChange,
    renderOtherOptionInput,
    hideSearch,
    limitOne,
    disabled,
    longList,
    suffixIcon,
    size,
    allowClear,
    loading: _,
    ...formFieldWrapperProps
  } = props
  const { fieldName } = formFieldWrapperProps
  const placeholder = props.placeholder || props.label

  const defaultRenderOption = (option: BasicOptionType): ReactNode => {
    if (option.tooltip) {
      return (
        <AntdSelect.Option key={option.value} disabled={option.disabled} value={option.value}>
          <Tooltip title={option.tooltip}>{option.label}</Tooltip>
        </AntdSelect.Option>
      )
    }
    if (option.sublabel) {
      return (
        <AntdSelect.Option
          key={option.value}
          disabled={option.disabled}
          label={option.label}
          value={option.value}
        >
          <LabelSublabelOption label={option.label} sublabel={option.sublabel} />
        </AntdSelect.Option>
      )
    }
    if (option.icon) {
      return (
        <AntdSelect.Option
          key={option.value}
          disabled={option.disabled}
          label={option.label}
          value={option.value}
        >
          {option.icon && <FontAwesomeIcon icon={option.icon} />} {option.label}
        </AntdSelect.Option>
      )
    }
    return (
      <AntdSelect.Option
        key={option.value}
        disabled={option.disabled}
        label={option.label}
        value={option.value}
      >
        {option.label}
      </AntdSelect.Option>
    )
  }
  const renderOption = props.renderOption || defaultRenderOption

  const otherOption = (
    <AntdSelect.Option key={SelectOptionOther} value={SelectOptionOther}>
      {SelectOptionOther}
    </AntdSelect.Option>
  )

  Form.useWatch(fieldName)

  const { getFieldValue, setFieldValue } = Form.useFormInstance()
  const value = getFieldValue(fieldName)
  const isOtherOptionSelected = Array.isArray(value)
    ? value.includes(SelectOptionOther)
    : value === SelectOptionOther

  const hasSelectedOptionsApartFromOther =
    Array.isArray(value) && value.length && !value.includes(SelectOptionOther)

  const [loading, setLoading] = useState(false)

  const onChangeFn = async (val: TOption['value']) => {
    setLoading(true)
    await onChange?.(val)
    if (limitOne) {
      if (Array.isArray(val) && val.length > 0) {
        setFieldValue(fieldName, [val[val.length - 1]])
      } else {
        setFieldValue(fieldName, [])
      }
    }
    setLoading(false)
  }

  return (
    <>
      <Wrapper {...formFieldWrapperProps}>
        <AntdSelect
          showSearch={!hideSearch}
          onChange={onChangeFn}
          placeholder={placeholder}
          disabled={disabled}
          listHeight={longList ? 512 : undefined}
          defaultActiveFirstOption={false}
          size={size}
          allowClear={allowClear}
          loading={props.loading}
          filterOption={(input: string, optionPartial: TOption | undefined) => {
            if (!optionPartial) {
              return false
            }
            if (!filterOption) {
              const val =
                optionPartial?.label ||
                optionPartial?.children ||
                optionPartial?.value ||
                optionLabelProp
              if (!(typeof val === 'string')) {
                return false
              }
              return val?.toLowerCase().includes(input.toLowerCase())
            }
            return filterOption(input, optionPartial.value)
          }}
          suffixIcon={
            loading && onChange ? (
              <LottieAnimation animationData={LottiesLoading} width={30} height={30} />
            ) : (
              <FontAwesomeIcon icon={suffixIcon || faChevronDown} />
            )
          }
          notFoundContent={<SelectNotFoundContent>{notFoundMessage}</SelectNotFoundContent>}
          {...(mode ? { mode } : {})}
          {...(optionLabelProp ? { optionLabelProp } : {})}
        >
          {options.map(renderOption)}
          {renderOtherOptionInput && !hasSelectedOptionsApartFromOther ? otherOption : null}
        </AntdSelect>
      </Wrapper>
      {isOtherOptionSelected && renderOtherOptionInput?.()}
    </>
  )
}

export default Select
