import { useEffect } from 'react'

import { navigate, useParams } from '@redwoodjs/router'

import { useAuth } from '../auth'

import { useHomePage } from './use-home-page'

export const useAuthRedirect = (redirectCondition?: () => boolean) => {
  const { isAuthenticated } = useAuth()

  const { redirectTo: inputRedirectTo } = useParams()

  useEffect(() => {
    if (inputRedirectTo) {
      localStorage.setItem('redirectTo', inputRedirectTo)
    }
  }, [inputRedirectTo])

  const defaultRedirect = useHomePage()
  const redirectTo = localStorage.getItem('redirectTo') || inputRedirectTo || defaultRedirect

  if (redirectCondition?.() || isAuthenticated) {
    navigate(redirectTo)
  }

  return redirectTo
}

export const clearRedirect = () => localStorage.removeItem('redirectTo')
