import { useCallback } from 'react'

import { faBrakeWarning } from '@fortawesome/pro-regular-svg-icons'
import { useLocalStorage } from 'usehooks-ts'

import { routes, useLocation, useParams } from '@redwoodjs/router'

import Navigation from 'src/components/Sidebar/Navigation/Navigation'
import { LocalStorageKey } from 'src/types'

import { ElementIds } from '../../../constants'

export const CodeViolationsNavItems = () => {
  const { pathname } = useLocation()
  const { slug } = useParams()
  const isActive = (href: string) => pathname.includes(href)
  const [isOpen, setIsOpen] = useLocalStorage<boolean>(
    LocalStorageKey.NavigationCodeV1IsOpen,
    false,
    {
      initializeWithValue: true,
      serializer: (v) => `${v}`,
      deserializer: (v) => v === 'true',
    }
  )

  const handleIsOpenToggled = useCallback(() => {
    setIsOpen((v) => !v)
  }, [setIsOpen])

  const isComplaintsActive = slug
    ? isActive(routes.codeComplaints()) || isActive(routes.submitComplaint({ slug }))
    : isActive(routes.codeComplaints())
  return (
    <Navigation.Group isOpen={isOpen} onToggleIsOpen={handleIsOpenToggled}>
      <Navigation.GroupHeader
        icon={faBrakeWarning}
        title="Code Enforcement"
        id={ElementIds.NavCodeEnforcement}
      />
      <Navigation.GroupList>
        <Navigation.GroupListItem
          title="Cases"
          isActive={isComplaintsActive}
          href={routes.codeComplaints()}
          id={ElementIds.NavComplaints}
        />
        <Navigation.GroupListItem
          title="Inspections"
          href={routes.codeInspections()}
          isActive={isActive(routes.codeInspections())}
          id={ElementIds.NavInspections}
        />
      </Navigation.GroupList>
    </Navigation.Group>
  )
}
