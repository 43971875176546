import isNil from 'lodash.isnil'
import { observer } from 'mobx-react-lite'

import {
  DateFilterDirectionNamesByDirection,
  getLabelForDateFilterDelayType,
} from 'src/components/TableViews/util'
import { useMyOrg } from 'src/layouts/AppStateContextLayout/utils'
import { TableViewDateFilter } from 'src/models/TableViews/TableViewDateFilter'
import { TableViewFilter } from 'src/models/TableViews/TableViewFilter'
import { filterNil } from 'src/utils'
import { DateFormats, formatDateInTimeZone } from 'src/utils/date'

type Props = {
  filter: TableViewFilter
}
const FilterTagValue = ({ filter }: Props) => {
  const org = useMyOrg()
  const timeZone = org?.timeZone
  const paymentProviderFormatted = org?.paymentProviderFormatted

  if (!isNil(filter.bool)) {
    return filter.bool ? 'True' : 'False'
  }
  if (TableViewDateFilter.isDatePopulated(filter.date)) {
    switch (filter.date.type) {
      case 'Exact': {
        const start = filter.date.exactStart
        const end = filter.date.exactEnd
        if (end && !start) {
          return `On or before ${formatDateInTimeZone(
            end,
            timeZone,
            DateFormats.MonthNameDateNoTime
          )}`
        }
        if (start && !end) {
          return `On or after ${formatDateInTimeZone(
            start,
            timeZone,
            DateFormats.MonthNameDateNoTime
          )}`
        }
        if (!start || !end) {
          return '-' // Should not happen
        }
        return `${formatDateInTimeZone(
          start,
          timeZone,
          DateFormats.MonthNameDateNoTime
        )} - ${formatDateInTimeZone(end, timeZone, DateFormats.MonthNameDateNoTime)}`
      }
      case 'IsNotNull':
        return 'Is not empty'
      case 'IsNull':
        return 'Is empty'
      case 'RelativeToToday':
        if (
          !filter.date.relativeToTodayDirection ||
          !filter.date.relativeToTodayDelayType ||
          isNil(filter.date.relativeToTodayCount)
        ) {
          return '-' // Should not happen
        }
        return `${
          DateFilterDirectionNamesByDirection[filter.date.relativeToTodayDirection]
        } ${filter.date.relativeToTodayCount} ${getLabelForDateFilterDelayType(
          filter.date.relativeToTodayDelayType,
          filter.date.relativeToTodayCount > 1
        )}`
      case 'Today':
        return 'Today'
    }
    return '-'
  }
  if (!isNil(filter.isNull)) {
    return filter.isNull ? 'Is empty' : 'Is not empty'
  }
  if (!isNil(filter.optionsSelected)) {
    return filterNil(
      filter.optionsSelected.map((selectedOption) =>
        filter.getOptionDisplayValue(selectedOption, paymentProviderFormatted ?? '')
      )
    )
      .sort()
      .join(', ')
  }
}

export default observer(FilterTagValue)
