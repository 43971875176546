import { MutableRefObject, Ref, RefCallback, useMemo } from 'react'

/**
 * Merges an array of refs into a single memoized callback ref or `null`.
 * Code copied from floating-ui and altered to match GovWell conventions.
 */
export const useMergeRefs = <TValue>(...refs: Array<Ref<TValue>>): RefCallback<TValue> | null => {
  return useMemo(() => {
    if (refs.every((ref) => ref === null)) {
      return null
    }

    return (value) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(value)
        } else if (ref != null) {
          ;(ref as MutableRefObject<TValue | null>).current = value
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs)
}
