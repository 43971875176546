import { useEffect } from 'react'

import CobrowseIO from 'cobrowse-sdk-js'

import { EnvVariables, Environments } from '../constants'

export const useSetUpCobrowse = () => {
  useEffect(() => {
    if (EnvVariables.CoBrowseLicenseKey && EnvVariables.Environment === Environments.Production) {
      // @ts-expect-error Top-level module imports are read-only, what cobrowse has us do is an anti-pattern
      CobrowseIO.license = EnvVariables.CoBrowseLicenseKey
      CobrowseIO.start()
    }
  }, [])
}
