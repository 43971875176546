import { useCallback, useEffect, useState } from 'react'

import { Form } from 'antd'
import { RcFile } from 'antd/es/upload'
import axios from 'axios'

import { useOrgUuid } from 'src/hooks/use-org-uuid'

import { CreateFileMutationVariables, FileFragment } from '../../types/graphql'
import { FormFieldWrapperProps } from '../components/form/Wrapper'
import { MaxFileSize } from '../constants'
import { BreakpointComponents } from '../constants/breakpoints'
import { PathPrefixes } from '../constants/file'
import { useCreateFileMutation } from '../fetch/files'
import {
  FileUploadErrors,
  getIsEncryptedPdf,
  getIsFileExtensionAccepted,
  uploadFile,
} from '../utils/file'

import { useCurrentBreakpoint } from './use-current-breakpoint'

export enum FileUploadStatuses {
  Default = 'default',
  Uploading = 'uploading',
  Done = 'done',
  Error = 'error',
  Existing = 'existing',
}

export interface FileUploadState {
  name: string
  path: string
  status: FileUploadStatuses
  progress: number
  govWellFileId: number | undefined
  size: number | undefined
  cancelUpload: () => void
  removeFile: () => void
  beforeUpload: (file: RcFile) => Promise<boolean>
  uploadFileUrl: (fileUrl: string) => Promise<boolean>
  reset: () => void
  createdFileId: number | undefined
  errorType: string | undefined
}

export interface UseFileUploadParams {
  inputBucket?: string
  pathPrefix?: PathPrefixes
  afterUpload?: (newFileId: number, path: string, reset: () => void) => Promise<void>
  afterRemove?: () => Promise<void>
  fieldName?: FormFieldWrapperProps['fieldName']
  accept?: string
}

export const useFileUploadState = (params: UseFileUploadParams): FileUploadState => {
  const { inputBucket, pathPrefix, afterUpload, fieldName, afterRemove, accept } = params

  const { isSmall } = useCurrentBreakpoint()
  const isSmallScreen = isSmall(BreakpointComponents.FileUpload)

  const form = Form.useFormInstance()
  Form.useWatch(fieldName, form)
  const fileFragment: FileFragment | undefined = form?.getFieldValue(fieldName)

  const orgUuid = useOrgUuid()
  const bucket = inputBucket || orgUuid

  const [path, setPath] = useState<string>(fileFragment?.path ?? '')
  const [status, setFileUploadStatus] = useState<FileUploadStatuses>(
    fileFragment ? FileUploadStatuses.Existing : FileUploadStatuses.Default
  )
  const [progress, setFileUploadProgress] = useState<number>(0)
  const [createdFileId, setCreatedFileId] = useState<number | undefined>(undefined)
  const [govWellFileId, setFileGovWellFileId] = useState<number | undefined>(fileFragment?.id)
  const [name, setFileName] = useState(fileFragment?.name ?? '')
  const [size, setFileSize] = useState<number | undefined>(fileFragment?.size ?? undefined)
  const [errorType, setErrorType] = useState<string | undefined>(undefined)

  const { mutateAsync: createFileRaw } = useCreateFileMutation()

  const createFile = useCallback(
    async (input: CreateFileMutationVariables) => {
      const res = await createFileRaw(input)
      return res.createFile
    },
    [createFileRaw]
  )

  const CancelToken = axios.CancelToken
  const source = CancelToken.source()

  const cancelUpload = () => {
    source.cancel()
    setFileUploadStatus(FileUploadStatuses.Default)
    reset()
  }

  const removeFile = () => {
    setFileUploadStatus(FileUploadStatuses.Default)
    if (form && fieldName) {
      form.setFieldValue(fieldName, '')
      void form.validateFields([fieldName])
    }
    reset()
    afterRemove?.()
  }

  const commonUploadFileProps = {
    bucket,
    pathPrefix,
    setFileUploadStatus,
    setFileUploadProgress,
    setFileSize,
    setPath,
    setFileGovWellFileId,
    setFileName,
    cancelToken: source.token,
    createFile,
    isSmallScreen,
    onError: (type: string) => setErrorType(type),
  }

  const afterUploadInternal = async (fileId: number, newFile: RcFile | FileFragment) => {
    setCreatedFileId(fileId)
    if (form && fileId && fieldName) {
      form.setFieldValue(fieldName, newFile)
      try {
        await form.validateFields([fieldName])
      } catch {
        // Do nothing
      }
    }
  }

  const beforeUpload = async (newFile: RcFile) => {
    if (!getIsFileExtensionAccepted({ accept, fileName: newFile.name })) {
      setFileName(newFile.name)
      setErrorType(FileUploadErrors.UnsupportedFileType)
      setFileUploadStatus(FileUploadStatuses.Error)
      return false
    }

    if (newFile.size > MaxFileSize) {
      setErrorType(FileUploadErrors.TooBig)
      setFileUploadStatus(FileUploadStatuses.Error)
      return false
    }

    if (await getIsEncryptedPdf(newFile)) {
      setErrorType(FileUploadErrors.EncryptedPdf)
      setFileUploadStatus(FileUploadStatuses.Error)
      return false
    }

    const uploadResult = await uploadFile({
      ...commonUploadFileProps,
      file: newFile,
    })
    if (!uploadResult) {
      return false
    }
    const { id: fileId, path: newPath } = uploadResult
    await afterUpload?.(fileId, newPath, reset)
    await afterUploadInternal(fileId, uploadResult)
    return false
  }

  const uploadFileUrl = async (fileUrl: string) => {
    const newFile = await uploadFile({
      ...commonUploadFileProps,
      fileUrl,
    })
    if (newFile) {
      const { id: fileId, path: newPath } = newFile
      await afterUpload?.(fileId, newPath, reset)
      await afterUploadInternal(fileId, newFile)
    }
    return false
  }

  const reset = () => {
    setPath('')
    setFileName('')
    setFileSize(undefined)
    setFileUploadStatus(FileUploadStatuses.Default)
    setFileUploadProgress(0)
  }

  useEffect(() => {
    // Sync form state with regular state in case the file field value is modified via form state, e.g. via form auto-fill
    if (fileFragment?.path && fileFragment.path !== path) {
      reset()
      setPath(fileFragment?.path)
      setFileName(fileFragment?.name)
      setFileSize(fileFragment?.size ?? undefined)
      setFileUploadStatus(FileUploadStatuses.Existing)
      setFileUploadProgress(0)
    }
  }, [fileFragment, path])

  return {
    name,
    path,
    status,
    size,
    progress,
    govWellFileId,
    cancelUpload,
    removeFile,
    beforeUpload,
    uploadFileUrl,
    createdFileId,
    reset,
    errorType,
  }
}
