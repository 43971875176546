import { faBookOpen } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'

import { CellSuccessProps } from '@redwoodjs/web'

import {
  ViewRecordTaskInspectionResultsModalCellQuery,
  ViewRecordTaskInspectionResultsModalCellQueryVariables,
} from '../../../../types/graphql'
import { filterNil } from '../../../utils'
import { Menu } from '../../Menu/Menu'
import { MenuItem } from '../../Menu/types'
import Modal from '../../Modal'
import { InspectionsTabModalProps } from '../types'
import {
  InspectionAttemptStatusDisplay,
  RecordTaskInspectionAttemptResults,
} from '../ViewRecordTaskInspectionAttemptResultsModalCell/RecordTaskInspectionAttemptResults'

export const QUERY = gql`
  query ViewRecordTaskInspectionResultsModalCellQuery($recordTaskInspectionId: Int!) {
    recordTaskInspection(id: $recordTaskInspectionId) {
      attempts {
        ...ViewRecordTaskInspectionAttemptFragment
      }
    }
  }
`

export const Loading = () => <></>

interface Props
  extends CellSuccessProps<
      ViewRecordTaskInspectionResultsModalCellQuery,
      ViewRecordTaskInspectionResultsModalCellQueryVariables
    >,
    InspectionsTabModalProps {
  recordTaskInspectionId: number
}

const Container = styled.div`
  margin-top: 12px;
`

export const Success = (props: Props) => {
  const {
    recordTaskInspection: { attempts },
    modalState,
  } = props
  const inspectionTemplateName = attempts?.[0]?.recordTaskInspection?.inspectionTemplate?.name
  const title = `${inspectionTemplateName} Inspection`

  const resultedAttempts = attempts.filter((a) => !!a?.result)

  const menuItems: MenuItem[] = filterNil(resultedAttempts).map((attempt, index) => {
    return {
      label: (
        <>
          #{index + 1}
          <InspectionAttemptStatusDisplay attempt={attempt} />
        </>
      ),
      renderChildren: () => (
        <Container>
          <RecordTaskInspectionAttemptResults attempt={attempt} />
        </Container>
      ),
      isInitialItem: index === resultedAttempts.length - 1,
    }
  })

  return (
    <Modal
      title={title}
      onOk={modalState.close}
      {...modalState}
      icon={faBookOpen}
      okText="Ok"
      hideCancelButton
      width="80%"
    >
      {menuItems.length > 1 ? <Menu items={menuItems} /> : menuItems?.[0]?.renderChildren()}
    </Modal>
  )
}
