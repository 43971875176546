import { useEffect } from 'react'

import { usePrevious } from '@dnd-kit/utilities'
import { Form } from 'antd'

import { PathPrefixes } from '../../constants/file'
import { FileUploadStatuses } from '../../hooks/use-file-upload-state'
import {
  UploadMultipleFilesFileUploadState,
  UseFilesUploadStateParams,
  useFilesUploadState,
} from '../../hooks/use-files-upload-state'
import { FilesUpload } from '../FilesUpload/FilesUpload'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

interface Props extends FormFieldWrapperProps {
  fieldName: string
  uploadParams: Omit<UseFilesUploadStateParams, 'pathPrefix'> & {
    pathPrefix: PathPrefixes
  }
}

export const FilesInput = (props: Props) => {
  const { uploadParams, ...formFieldWrapperProps } = props

  const filesUploadProps = useFilesUploadState({
    fieldName: formFieldWrapperProps.fieldName,
    ...uploadParams,
  })

  const form = Form.useFormInstance()
  Form.useWatch(formFieldWrapperProps.fieldName, form)
  const states = (form.getFieldValue(props.fieldName) || []) as UploadMultipleFilesFileUploadState[]

  const validate = async () => {
    if (hasLoadingFiles) {
      return Promise.reject('One or more files is still loading')
    }
    return Promise.resolve()
  }

  const hasLoadingFiles = states.some((s) => s.status === FileUploadStatuses.Uploading)
  const previousHasLoadingFiles = usePrevious(hasLoadingFiles) || false
  const fileCount = states.length
  const previousFileCount = usePrevious(fileCount) || 0

  // Validate fields when the form state has materially changed.
  // You cannot rely on the file count alone, because the file count increases before a file is successfully uploaded, must wait for the loading state to end.
  useEffect(() => {
    if (
      (!hasLoadingFiles && !!previousHasLoadingFiles) || // A file was successfully uploaded
      (fileCount === 0 && previousFileCount !== 0) // All files were deleted
    ) {
      form.validateFields([props.fieldName])
    }
  }, [
    fileCount,
    form,
    hasLoadingFiles,
    previousFileCount,
    previousHasLoadingFiles,
    props.fieldName,
    states.length,
  ])

  return (
    <Wrapper
      {...formFieldWrapperProps}
      rules={[
        {
          validator: validate,
          validateTrigger: ['onSubmit'],
        },
        ...(props.required ? [{ required: props.required, message: props.errorMessage }] : []),
      ]}
    >
      {({ hasError }) => (
        <FilesUpload {...filesUploadProps} accept={uploadParams.accept} error={hasError} />
      )}
    </Wrapper>
  )
}
