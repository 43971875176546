import { MouseEventHandler } from 'react'

import { faFileCircleCheck, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import styled from 'styled-components'

import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { UploadMultipleFilesFileUploadState } from '../../hooks/use-files-upload-state'
import { useTheme } from '../../hooks/use-theme'
import { formatBytes } from '../../utils'
import { StylePropsMobile } from '../shared/StyledComponents'
import Text, { TextSize } from '../Typography/Text'

import { FileCard } from './StyledComponents'

const Container = styled(FileCard)`
  flex-direction: row;
  text-align: left;
`

const Left = styled.div<StylePropsMobile>`
  flex: 1;
  margin-right: auto;
  display: flex;
  gap: ${(props) => (props.$small ? '8px' : '12px')};
  align-items: center;
`
const Right = styled.div`
  margin-left: auto;
  display: flex;
  gap: 4px;
  align-items: center;
`

interface Props extends UploadMultipleFilesFileUploadState {
  onRemove: () => void
}

export const UploadedFileCard = (props: Props) => {
  const { getTokenVal } = useTheme()
  const onClick: MouseEventHandler = (e: React.MouseEvent<Element, MouseEvent>) =>
    e.stopPropagation()
  const { onRemove, size, name } = props
  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)
  return (
    <Container onClick={onClick} $small={small}>
      <Left $small={small}>
        <FontAwesomeIcon
          icon={faFileCircleCheck}
          color={getTokenVal('colorSuccessBase')}
          style={{ fontSize: 12 }}
        />
        <Tooltip trigger={['click', 'hover']} title={name}>
          <Text size={TextSize.Base} style={{ flex: 1 }} maxLines={3}>
            {name}
          </Text>
        </Tooltip>
      </Left>
      <Right>
        {size && (
          <Text size={TextSize.Small} style={{ whiteSpace: 'nowrap' }}>
            {formatBytes(size)}
          </Text>
        )}
        <Button
          type="text"
          size="small"
          onClick={onRemove}
          icon={<FontAwesomeIcon icon={faTrash} />}
        ></Button>
      </Right>
    </Container>
  )
}
