import React from 'react'

import { Flex } from 'antd'
import styled from 'styled-components'

import { Logo, LogoColor } from 'src/components/svgs/Logo'
import { useAuthRedirect } from 'src/hooks/use-auth-redirect'
import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'
import { useTheme } from 'src/hooks/use-theme'
import { LoginTopbar } from 'src/pages/LoginPage/LoginTopbar'

import HagiaSophia from './hagia-sophia.svg'

type Props = {
  children?: React.ReactNode
}

const DesktopMain = styled.main`
  flex: 1;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DesktopHeroSection = styled.section<{
  $backgroundColor: React.CSSProperties['backgroundColor']
}>`
  position: relative;
  display: flex;
  flex: 1;
  align-items: stretch;
  justify-items: stretch;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`

const Hero = styled.div`
  width: 100%;
  background: url(${HagiaSophia}) no-repeat;
  background-size: cover;
  display: flex;
`

const MobileMain = styled.main`
  flex: 1;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AppLandingLayout = ({ children }: Props) => {
  useAuthRedirect()
  const { getTokenVal } = useTheme()
  const backgroundColor = getTokenVal('colorMenuDarkBg')
  const { isSmallScreen } = useCurrentBreakpoint()
  if (isSmallScreen()) {
    return (
      <Flex vertical>
        <header>
          <LoginTopbar />
        </header>
        <MobileMain>{children}</MobileMain>
      </Flex>
    )
  }

  return (
    <Flex>
      <DesktopMain>{children}</DesktopMain>
      <DesktopHeroSection $backgroundColor={backgroundColor as string}>
        <Hero>
          <Flex justify="center" align="center" flex={1}>
            <Logo color={LogoColor.White} width={397} height={163} />
          </Flex>
        </Hero>
      </DesktopHeroSection>
    </Flex>
  )
}

export default AppLandingLayout
