import { useCallback } from 'react'

import { faGear, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex, Skeleton, Tooltip } from 'antd'
import { Tabs } from 'govwell-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { GetOrCreateViewsMutation } from 'types/graphql'

import CreateViewModal from 'src/components/TableViews/CreateViewModal'
import EditViewsDrawer from 'src/components/TableViews/EditViewsDrawer/EditViewsDrawer'
import TableViewTab from 'src/components/TableViews/TableViewTab'
import { TabReorderReason } from 'src/govwell-ui/components/Tabs'
import useDisclosure from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

const StyledActionButton = styled(Button)`
  width: 36px !important;
  min-width: 36px !important;
`

type Props = {
  generateDefaultViews: () => Promise<GetOrCreateViewsMutation>
  isLoading?: boolean
  onSelectedViewChange?: (view: TableView) => void
  tableViewManager: TableViewManager
}
const TableViewTabs = ({
  generateDefaultViews,
  isLoading,
  onSelectedViewChange,
  tableViewManager,
}: Props) => {
  const createViewModalState = useDisclosure()
  const editViewsDrawerState = useDisclosure()

  const handleSelectionChange = useCallback(
    (viewId: string) => {
      runInAction(() => {
        const isChanging = tableViewManager.selectedView?.id !== parseInt(viewId)
        if (!isChanging) {
          return
        }
        tableViewManager.setSelectedViewId(parseInt(viewId))
        if (tableViewManager.selectedView) {
          onSelectedViewChange?.(tableViewManager.selectedView)
        }
      })
    },
    [onSelectedViewChange, tableViewManager]
  )

  const handleReorderTabs = useCallback(
    (viewIds: string[], _reorderReason: TabReorderReason) => {
      runInAction(() => {
        tableViewManager.reorderViews(viewIds.map((id) => parseInt(id)))
      })

      /**
       * if (reorderReason === TabReorderReason.DragAndDrop) {
       *   execute mutation to update table view
       * }
       */
    },
    [tableViewManager]
  )

  return (
    <>
      <Tabs
        selectedTabKey={`${tableViewManager.selectedView?.id}`}
        onReorderTabs={handleReorderTabs}
        onSelectedKeyChange={handleSelectionChange}
      >
        <Tabs.TabsList>
          <Flex gap="6px" align="center">
            {(isLoading || tableViewManager.views.length === 0) &&
              [0, 1, 2].map((i) => <Skeleton.Input key={`loading-state-${i}`} active />)}
          </Flex>
          {!isLoading &&
            tableViewManager.views.map((v) => (
              <TableViewTab
                key={v.id}
                generateDefaultViews={generateDefaultViews}
                view={v}
                tableViewManager={tableViewManager}
              />
            ))}
        </Tabs.TabsList>
        <Tabs.Actions>
          <Tooltip title="Add view">
            <StyledActionButton
              className="govwell-intercom-saved-table-view-tooltip-target"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={createViewModalState.open}
            />
          </Tooltip>
          <Tooltip title="Edit saved views">
            <StyledActionButton
              icon={<FontAwesomeIcon icon={faGear} />}
              onClick={editViewsDrawerState.open}
            />
          </Tooltip>
        </Tabs.Actions>
      </Tabs>
      {createViewModalState.isOpen && (
        <CreateViewModal tableViewManager={tableViewManager} modalState={createViewModalState} />
      )}
      {editViewsDrawerState.isOpen && (
        <EditViewsDrawer
          generateDefaultViews={generateDefaultViews}
          modalState={editViewsDrawerState}
          tableViewManager={tableViewManager}
        />
      )}
    </>
  )
}

export default observer(TableViewTabs)
