import styled from 'styled-components'

import { routes } from '@redwoodjs/router'
import { CellSuccessProps } from '@redwoodjs/web'

import { RecordTypeStatusDisplay } from '../../pages/RecordTypeStatusesPage/RecordTypeStatusesCell/RecordTypeStatusDisplay'
import { TableLink, TableLinkNewPage } from '../shared/StyledComponents'

export const QUERY = gql`
  query RecordLinkCellQuery($id: Int!) {
    recordById(id: $id) {
      uuid
      isDraft
      status {
        id
        type
        name
      }
      id
      stringified
    }
  }
`

interface Props extends CellSuccessProps {
  text: string
}

export const Loading = (props: Props) => <TableLink href="">{props.text}</TableLink>

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Success = (props: Props) => {
  const {
    recordById: { uuid, status, isDraft, stringified },
  } = props
  return (
    <TableLinkNewPage href={routes.record({ recordUuid: uuid })}>
      <ContentContainer>
        {stringified} {status && <RecordTypeStatusDisplay status={status} isDraft={isDraft} />}
      </ContentContainer>
    </TableLinkNewPage>
  )
}
