import { DatePicker as AntdDatePicker, DatePickerProps } from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { DateFormats } from '../../utils/date'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

export enum DatePickerAllowedDates {
  Future = 'future',
  Past = 'past',
  All = 'all',
}

interface Props extends FormFieldWrapperProps {
  placeholder?: string | [string, string]
  showTime?: boolean
  range?: boolean
  allowedDates?: DatePickerAllowedDates
  isStartDateRequired?: boolean
  isEndDateRequired?: boolean
  disableWeekends?: boolean
  cutoffTime?: Date | string | null
  size?: DatePickerProps['size']
  allowClear?: boolean
  onValueChange?: (values: [Dayjs | null, Dayjs | null] | null) => void
}

const { RangePicker } = AntdDatePicker

const StyledAntdDatePicker = styled(AntdDatePicker)<{
  $size: React.ComponentProps<typeof AntdDatePicker>['size']
}>`
  width: 100%;
  height: ${({ $size }) => {
    switch ($size) {
      case 'small':
        return '24px'
      case 'middle':
        return '32px'
      default:
        return '40px'
    }
  }};
`
const StyledAntdRangePicker = styled(RangePicker)<{
  $size: React.ComponentProps<typeof AntdDatePicker>['size']
}>`
  width: 100%;
  height: ${({ $size }) => {
    switch ($size) {
      case 'small':
        return '24px'
      case 'middle':
        return '32px'
      default:
        return '40px'
    }
  }};
`

export function DatePicker(props: Props) {
  const {
    placeholder,
    showTime,
    range,
    allowedDates,
    isStartDateRequired = true,
    isEndDateRequired = true,
    disableWeekends,
    cutoffTime,
    disabled,
    size = 'middle',
    allowClear = true,
    onValueChange,
    ...formFieldWrapperProps
  } = props

  const getIsBeforeCutoff = () => {
    const now = dayjs()
    const cutoff = dayjs(cutoffTime)
    if (now.hour() > cutoff.hour()) {
      return false
    }
    if (now.hour() < cutoff.hour()) {
      return true
    }
    if (now.minute() > cutoff.minute()) {
      return false
    }
    return true
  }

  const getFirstAllowableDay = () => {
    if (!cutoffTime) {
      return undefined
    }
    if (allowedDates !== DatePickerAllowedDates.Future) {
      return undefined
    }

    const isBeforeCutoff = getIsBeforeCutoff()

    let firstAllowableDay = dayjs()

    if (!disableWeekends) {
      const offset = isBeforeCutoff ? 1 : 2
      firstAllowableDay = firstAllowableDay.add(offset, 'day')
    }

    firstAllowableDay = firstAllowableDay.add(1, 'day')

    while ([0, 6].includes(firstAllowableDay.day())) {
      firstAllowableDay = firstAllowableDay.add(1, 'day')
    }
    const offset = isBeforeCutoff ? 0 : 1
    firstAllowableDay = firstAllowableDay
      .add(offset, 'day')
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
    return firstAllowableDay
  }
  const firstAllowableDay = getFirstAllowableDay()

  const disabledDate = (current: Dayjs) => {
    if (!allowedDates) {
      return true
    }
    if (allowedDates === DatePickerAllowedDates.All) {
      return false
    }

    const day = dayjs(current).day()
    const isWeekend = day === 0 || day === 6

    if (firstAllowableDay && dayjs(current).isBefore(firstAllowableDay)) {
      return true
    }

    if (disableWeekends && isWeekend) {
      return true
    }

    if (allowedDates === DatePickerAllowedDates.Future) {
      return dayjs(current) <= dayjs() && !dayjs(current).isSame(dayjs(), 'day')
    } else if (allowedDates === DatePickerAllowedDates.Past) {
      return dayjs(current) > dayjs()
    }

    return false
  }

  const commonProps = {
    disabledDate,
  }

  if (range) {
    return (
      <Wrapper {...formFieldWrapperProps}>
        <StyledAntdRangePicker
          {...commonProps}
          onChange={onValueChange}
          allowEmpty={[!isStartDateRequired, !isEndDateRequired]}
          disabled={disabled}
          format={showTime ? DateFormats.NumericalDateTimeConcise : DateFormats.MonthNameDateNoTime}
          size={size}
          $size={size}
          placeholder={placeholder as [string, string]}
          showTime={
            showTime
              ? {
                  format: DateFormats.NumericalDateTimeConcise,
                  use12Hours: true,
                  minuteStep: 1,
                  defaultValue: [dayjs().startOf('day'), dayjs().endOf('day')],
                }
              : false
          }
        />
      </Wrapper>
    )
  }
  const formats = [
    showTime ? DateFormats.MonthNameDateTime : DateFormats.MonthNameDateNoTime,
    'MM/DD/YYYY',
    'MM/DD/YY',
    'M/D/YYYY',
    'M/D/YY',
    'MMMM D, YYYY',
    'MMMM D YYYY',
    'MMM D, YYYY',
    'MMM D YYYY',
    'D MMMM YYYY',
    'D MMM YYYY',
    'YYYY/MM/DD',
    'YYYY-MM-DD',
    'MMDDYYYY',
    'MMDDYY',
  ]
  return (
    <Wrapper {...formFieldWrapperProps}>
      <StyledAntdDatePicker
        placeholder={placeholder as string}
        format={formats}
        disabled={disabled}
        size={size}
        $size={size}
        allowClear={allowClear}
        showTime={
          showTime
            ? {
                format: DateFormats.MonthNameDateTime,
                use12Hours: true,
                minuteStep: 1,
              }
            : false
        }
        {...commonProps}
      />
    </Wrapper>
  )
}

export default DatePicker
