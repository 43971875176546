export const RecordSettingsFragment = gql`
  fragment RecordSettingsFragment on RecordSettings {
    allowAnonymousSubmissions
    issuedIdentifierYearCutoff {
      day
      month
    }
    trackApplicants
  }
`
