import { MenuItemStyleProps, MenuItemStyles } from 'govwell-ui/components/Menu/Menu'
import styled from 'styled-components'

const StyledMenuItem = styled.div.attrs({
  role: 'option',
})<MenuItemStyleProps>`
  ${MenuItemStyles};
  max-width: 100%;
  width: 100%;
`

export default StyledMenuItem
