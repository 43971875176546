import { useEffect } from 'react'

import { navigate } from '@redwoodjs/router'

import PageContent from 'src/components/shared/PageContent'

import { LoadingSpin } from '../../components/shared/StyledComponents'
import { useRecordTypeQuery } from '../../fetch/recordTypes'
import { useHomePage } from '../../hooks/use-home-page'
import { useAmIInspector, useIsRestrictedReviewer } from '../../layouts/AppStateContextLayout/utils'

import RecordsInspectionsContent from './RecordsInspectionsContent'

type Props = {
  recordTypeId: number
}
const RecordsInspectionsPage = ({ recordTypeId }: Props) => {
  const { data } = useRecordTypeQuery(recordTypeId)
  const isRestrictedReviewer = useIsRestrictedReviewer()
  const isInspector = useAmIInspector()
  const homePage = useHomePage()

  const hasPermissions = !isRestrictedReviewer || isInspector

  useEffect(() => {
    if (!hasPermissions) {
      navigate(homePage)
    }
  }, [homePage, hasPermissions])

  if (!hasPermissions) {
    return null
  }

  return (
    <PageContent>
      {data?.recordType ? (
        <RecordsInspectionsContent recordType={data.recordType} key={recordTypeId} />
      ) : (
        <LoadingSpin />
      )}
    </PageContent>
  )
}
export default React.memo(RecordsInspectionsPage)
