import { DateDirection, DelayType } from 'types/graphql'

export const DateFilterDirectionNamesByDirection: Record<DateDirection, string> = {
  Future: 'Next',
  Past: 'Previous',
}

export const getLabelForDateFilterDelayType = (delayType: DelayType, isPlural: boolean) => {
  switch (delayType) {
    case 'Day':
      return isPlural ? 'Days' : 'Day'
    case 'Week':
      return isPlural ? 'Weeks' : 'Week'
    case 'Month':
      return isPlural ? 'Months' : 'Month'
    case 'Year':
      return isPlural ? 'Years' : 'Year'
  }
  return ''
}

export const TABLE_VIEW_HEADER_BREAKPOINT_PX = 768
