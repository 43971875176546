import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { FileUploadState, FileUploadStatuses } from '../../hooks/use-file-upload-state'
import { StyledDragger } from '../StyledComponents'

import { Default } from './states/Default'
import { Done } from './states/Done'
import { Error } from './states/Error'
import { Uploading } from './states/Uploading'

export interface FileUploadProps extends FileUploadState {
  accept?: string
  disabled?: boolean
  error?: boolean
  onRemove?: () => void
}

export const FileUpload = (props: FileUploadProps) => {
  const {
    accept,
    disabled,
    size,
    status,
    progress,
    name,
    cancelUpload,
    removeFile,
    beforeUpload,
    error,
    errorType,
    reset,
  } = props

  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)

  return (
    <>
      <StyledDragger
        accept={accept}
        className="dragger-class-name"
        $disabled={disabled}
        disabled={disabled}
        name="file"
        action=""
        beforeUpload={beforeUpload}
        openFileDialogOnClick={[
          FileUploadStatuses.Default,
          FileUploadStatuses.Error,
          FileUploadStatuses.Existing,
        ].includes(status)}
        $small={small}
        $error={error}
      >
        {status === FileUploadStatuses.Default && <Default accept={accept} disabled={disabled} />}
        {status === FileUploadStatuses.Error && errorType && (
          <Error name={name} errorType={errorType} reset={reset} />
        )}
        {status === FileUploadStatuses.Uploading && (
          <Uploading progress={progress} cancelUpload={cancelUpload} />
        )}
        {[FileUploadStatuses.Done, FileUploadStatuses.Existing].includes(status) && size && (
          <Done size={size} removeFile={removeFile} name={name} status={status} />
        )}
      </StyledDragger>
    </>
  )
}
