import {
  CreateRecordTypeMutation,
  CreateRecordTypeMutationVariables,
  RecordTypeQuery,
  RecordTypeQueryVariables,
  RecordTypesForConfigQuery,
  RecordTypesQuery,
  RecordTypesQueryVariables,
  RecordTypeStatusQuery,
  RecordTypeStatusQueryVariables,
  UpdateRecordTypeMutation,
  UpdateRecordTypeMutationVariables,
} from 'types/graphql'

import { QueryKey, useMutation, useQuery } from 'src/utils/queryClient'

const QueryRecordTypeStatus = gql`
  query RecordTypeStatusQuery($recordTypeId: Int!) {
    recordType(id: $recordTypeId) {
      statuses {
        ...RecordTypeStatusFragment
      }
    }
  }
`

export const useRecordTypeStatusesQuery = (args: { recordTypeId: number | null | undefined }) => {
  return useQuery<RecordTypeStatusQuery, RecordTypeStatusQueryVariables>({
    queryKey: [QueryKey.RecordTypeStatuses, args.recordTypeId],
    queryDocument: QueryRecordTypeStatus,
    variables: {
      recordTypeId: args.recordTypeId ?? -1,
    },
    enabled: !!args.recordTypeId,
  })
}

const RecordTypes = gql`
  query RecordTypesQuery($organizationId: Int!) {
    recordTypesForOrg(organizationId: $organizationId) {
      id
      name
      shouldShowInStaffNavigation
      visibleOnOrganizationHomepage
    }
  }
`
export const useRecordTypesQuery = (organizationId: number) => {
  return useQuery<RecordTypesQuery, RecordTypesQueryVariables>({
    queryKey: [QueryKey.RecordTypes, organizationId],
    queryDocument: RecordTypes,
    variables: { organizationId },
  })
}

const RecordType = gql`
  query RecordTypeQuery($recordTypeId: Int!) {
    recordType: recordType(id: $recordTypeId) {
      ...RecordTypeFragment
    }
  }
`
export const useRecordTypeQuery = (recordTypeId: number | null | undefined) => {
  return useQuery<RecordTypeQuery, RecordTypeQueryVariables>({
    queryKey: [QueryKey.RecordType, recordTypeId],
    queryDocument: RecordType,
    variables: {
      recordTypeId: recordTypeId ?? -1,
    },
    enabled: !!recordTypeId,
  })
}

const RecordTypesForConfig = gql`
  query RecordTypesForConfigQuery {
    recordTypes {
      ...RecordTypeForConfigFragment
    }
  }
  fragment RecordTypeApplicationGroupFragment on RecordTypeApplicationGroup {
    id
    name
    deletable
  }
  fragment RecordTemplateForConfigFragment on RecordTemplate {
    id
    recordTypeId
    name
    tags
    applicantType
    uuid
    prefix
    visibleOnModulePickPage
    visibleOnOrganizationHomepage
    owner {
      ...UserDataFragment
    }
    createdByUser {
      ...UserDataFragment
    }
    applicationGroup {
      id
      name
    }
  }
  fragment RecordTypeForConfigFragment on RecordType {
    id
    name
    recordName
    category
    recordTemplates {
      ...RecordTemplateForConfigFragment
    }
    applicationGroups {
      ...RecordTypeApplicationGroupFragment
    }
  }
`

export const useRecordTypesForConfigQuery = () => {
  return useQuery<RecordTypesForConfigQuery>({
    queryDocument: RecordTypesForConfig,
    queryKey: [QueryKey.RecordTypesForConfig],
  })
}

const Create = gql`
  mutation CreateRecordTypeMutation($input: CreateRecordTypeInput!) {
    createRecordType(input: $input) {
      id
    }
  }
`
export const useCreateRecordTypeMutation = () => {
  return useMutation<CreateRecordTypeMutation, CreateRecordTypeMutationVariables>({
    mutationDocument: Create,
  })
}

const Update = gql`
  mutation UpdateRecordTypeMutation($id: Int!, $input: UpdateRecordTypeInput!) {
    updateRecordType(id: $id, input: $input) {
      id
    }
  }
`

export const useUpdateRecordTypeMutation = () => {
  return useMutation<UpdateRecordTypeMutation, UpdateRecordTypeMutationVariables>({
    mutationDocument: Update,
  })
}
