import { HTMLProps, ReactNode, forwardRef, useContext, useMemo } from 'react'

import * as PopoverPrimitive from '@radix-ui/react-popover'
import { Portal } from 'govwell-ui'
import styled from 'styled-components'

type PopoverBaseProps = {
  isOpen: boolean
  onOpen?: () => void
  onClose?: () => void
  children?: ReactNode
}
const PopoverBase = ({ isOpen, onOpen, onClose, children }: PopoverBaseProps) => {
  const contextValue: PopoverContextType = useMemo(() => ({ isOpen }), [isOpen])
  return (
    <PopoverPrimitive.Root
      open={isOpen}
      onOpenChange={(newIsOpen) => (newIsOpen ? onOpen?.() : onClose?.())}
    >
      <PopoverContext.Provider value={contextValue}>{children}</PopoverContext.Provider>
    </PopoverPrimitive.Root>
  )
}

const StyledContent = styled(PopoverPrimitive.Content)`
  max-width: 100vw;
  max-height: var(--radix-popover-content-available-height);
  overflow: auto;
  background-color: ${({ theme }) => theme.colorWhite};
  border-radius: 8px;
  z-index: 1000; /* remove once not competing with antd which styles all of its modal content at z-index 1000 */
`
type PopoverContentProps = HTMLProps<HTMLDivElement> &
  Pick<
    PopoverPrimitive.PopoverContentProps,
    'align' | 'onOpenAutoFocus' | 'side' | 'sideOffset' | 'collisionBoundary'
  > & {
    children?: React.ReactNode
  }
const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ children, ...rest }: PopoverContentProps, ref) => {
    const { isOpen } = useContext(PopoverContext)
    if (!isOpen) {
      return null // Don't mount content if the popover is closed
    }
    return (
      <Portal>
        <StyledContent {...rest} ref={ref} collisionPadding={{ right: 20 }}>
          {children}
        </StyledContent>
      </Portal>
    )
  }
)

type PopoverContextType = {
  isOpen: boolean
}
const PopoverContext = React.createContext<PopoverContextType>({} as PopoverContextType)

const Popover = Object.assign(PopoverBase, {
  Anchor: PopoverPrimitive.Anchor,
  Arrow: PopoverPrimitive.Arrow,
  Content: PopoverContent,
  Trigger: PopoverPrimitive.Trigger,
})

export default Popover
