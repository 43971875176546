import { useMemo } from 'react'

import { SelectOption } from 'govwell-ui/components/Select/types'

const defaultFilterOption = <TValue>(searchQuery: string | null, option: SelectOption<TValue>) =>
  option.label.toLocaleLowerCase().includes(searchQuery?.toLocaleLowerCase() ?? '')

export const useFilteredOptions = <TValue>(args: {
  isAsync: boolean
  filterOption?: (searchQuery: string, option: SelectOption<TValue>) => boolean
  searchQuery: string | null
  options: SelectOption<TValue>[]
}) => {
  const { isAsync, filterOption, options, searchQuery } = args
  return useMemo((): SelectOption<TValue>[] => {
    if (isAsync) {
      return options // the options are already filtered by the backend for async selects
    }
    const fn = filterOption ?? defaultFilterOption
    return options.filter((o) => fn(searchQuery ?? '', o))
  }, [filterOption, isAsync, options, searchQuery])
}
