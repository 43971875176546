import { useCallback } from 'react'

import { Flex } from 'antd'
import { Checkbox } from 'govwell-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ViewFilterOptionFragment } from 'types/graphql'

import UserAvatar from 'src/components/UserAvatar/UserAvatar'
import { TableViewFilter } from 'src/models/TableViews/TableViewFilter'

import { useMyOrg } from '../../layouts/AppStateContextLayout/utils'

type Props = {
  option: ViewFilterOptionFragment
  filter: TableViewFilter
}
const OptionCheckbox = ({ option, filter }: Props) => {
  const id = option.identifier
  const isSelected = !!filter.optionsSelected?.includes(id)
  const handleValueChange = useCallback(() => {
    runInAction(() => {
      if (isSelected) {
        filter.unselectOption(id)
      } else {
        filter.selectOption(id)
      }
    })
  }, [filter, isSelected, id])

  const paymentProviderFormatted = useMyOrg()?.paymentProviderFormatted || ''

  const displayValue = filter.getOptionDisplayValue(id, paymentProviderFormatted)

  const renderOption = () => {
    switch (option.data.__typename) {
      case 'ViewDataElementUser':
        return (
          <Flex align="center" gap="6px">
            {option.data.__typename === 'ViewDataElementUser' && (
              <UserAvatar sizePx={20} user={option.data.user} />
            )}
            {displayValue}
          </Flex>
        )
      default:
        return displayValue
    }
  }

  return (
    <Checkbox value={isSelected} onValueChange={handleValueChange}>
      {renderOption()}
    </Checkbox>
  )
}

export default observer(OptionCheckbox)
