import { useCallback, useEffect, useState } from 'react'

import { Form } from 'antd'
import { DraggerProps, RcFile } from 'antd/es/upload'
import axios, { CancelTokenSource } from 'axios'
import { v4 } from 'uuid'

import { useOrgUuid } from 'src/hooks/use-org-uuid'
import { filterNil } from 'src/utils'

import { CreateFileMutationVariables, FileFragment } from '../../types/graphql'
import { MaxFileSize } from '../constants'
import { PathPrefixes } from '../constants/file'
import { useCreateFileMutation } from '../fetch/files'
import { useIsCitizen } from '../layouts/AppStateContextLayout/utils'
import {
  FileUploadErrors,
  getFileName,
  getIsEncryptedPdf,
  getIsFileExtensionAccepted,
  uploadFile as uploadFileBase,
} from '../utils/file'

import { FileUploadStatuses } from './use-file-upload-state'

export interface UploadMultipleFilesFileUploadState {
  path: string
  status: FileUploadStatuses
  progress: number
  id: string
  name: string
  size: number | undefined
  govWellFileId: number | undefined
  cancelToken: CancelTokenSource | undefined
  errorType: string | undefined
  govWellFile: FileFragment | undefined
}

export interface FilesUploadState {
  states: UploadMultipleFilesFileUploadState[]

  loading: boolean
  govWellFileIds: number[]

  cancelUpload: (index: number) => void
  beforeUpload: DraggerProps['beforeUpload']
  reset: () => void

  removeFile: (index: number) => void
}

export interface UseFilesUploadStateParams {
  inputBucket?: string
  pathPrefix: PathPrefixes
  afterUpload?: (fileId: number, path: string) => Promise<void>
  fieldName?: string
  accept?: string
}

export const mapFileFragmentToUploadMultipleFilesFileUploadState = (
  fileFragment: FileFragment
): UploadMultipleFilesFileUploadState => {
  return {
    path: fileFragment?.path,
    name: fileFragment?.name,
    status: fileFragment ? FileUploadStatuses.Existing : FileUploadStatuses.Default,
    size: fileFragment?.size ?? 0,
    progress: 0,
    id: v4(),
    govWellFileId: fileFragment?.id,
    errorType: '',
    cancelToken: undefined,
    govWellFile: fileFragment,
  }
}

export const useFilesUploadState = (params: UseFilesUploadStateParams): FilesUploadState => {
  const { inputBucket, pathPrefix, afterUpload, fieldName, accept } = params
  const orgUuid = useOrgUuid()
  const bucket = inputBucket || orgUuid

  const form = Form.useFormInstance()
  Form.useWatch?.(fieldName, form)
  const existingFormStates: UploadMultipleFilesFileUploadState[] | undefined =
    form?.getFieldValue(fieldName)
  const getInitialStates = useCallback((): UploadMultipleFilesFileUploadState[] => {
    if (existingFormStates) {
      return existingFormStates
    }
    return []
  }, [existingFormStates])

  const [states, setStates] = useState<UploadMultipleFilesFileUploadState[]>(getInitialStates())

  const [fileIdMap] = useState<Set<string>>(new Set<string>())

  const initializeNewFileUploads = useCallback(
    (files: RcFile[]) => {
      const newStates: UploadMultipleFilesFileUploadState[] = files.flatMap((file: RcFile) => {
        return [
          {
            path: '',
            name: getFileName(file),
            status: FileUploadStatuses.Default,
            size: file?.size,
            progress: 0,
            id: v4(),
            govWellFileId: undefined,
            errorType: '',
            cancelToken: axios.CancelToken.source(),
            govWellFile: undefined,
          },
        ]
      })
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => [...prevStates, ...newStates])
      return { prevLength: states.length, newStates }
    },
    [states.length]
  )

  const setFormFieldState = useCallback(
    (fieldStates: UploadMultipleFilesFileUploadState[]) => {
      if (form && fieldName) {
        form.setFieldValue(fieldName, fieldStates)
      }
    },
    [fieldName, form]
  )

  const removeFile = (index: number) => {
    setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
      const newStates = [...prevStates.slice(0, index), ...prevStates.slice(index + 1)]
      setFormFieldState(newStates)
      return newStates
    })
  }

  function updateProperty<K extends keyof UploadMultipleFilesFileUploadState>(
    fileStates: UploadMultipleFilesFileUploadState[],
    index: number,
    property: K,
    value: UploadMultipleFilesFileUploadState[K]
  ) {
    if (fileStates?.[index]) {
      fileStates[index][property] = value
    }
  }

  const setPath = useCallback(
    (index: number, path: string) => {
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
        const newStates = [...prevStates]
        updateProperty(newStates, index, 'path', path)
        setFormFieldState(newStates)
        return newStates
      })
    },
    [setFormFieldState]
  )
  const setFileUploadStatus = useCallback(
    (index: number, status: FileUploadStatuses) => {
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
        const newStates = [...prevStates]
        updateProperty(newStates, index, 'status', status)
        setFormFieldState(newStates)
        return newStates
      })
    },
    [setFormFieldState]
  )
  const setFileUploadProgress = useCallback(
    (index: number, progress: number) => {
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
        const newStates = [...prevStates]
        updateProperty(newStates, index, 'progress', progress)
        setFormFieldState(newStates)
        return newStates
      })
    },
    [setFormFieldState]
  )
  const setFileName = useCallback(
    (index: number, name: string) => {
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
        const newStates = [...prevStates]
        updateProperty(newStates, index, 'name', name)
        setFormFieldState(newStates)
        return newStates
      })
    },
    [setFormFieldState]
  )
  const setFileGovWellFileId = useCallback(
    (index: number, govWellFileId: number) => {
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
        const newStates = [...prevStates]
        updateProperty(newStates, index, 'govWellFileId', govWellFileId)
        setFormFieldState(newStates)
        return newStates
      })
    },
    [setFormFieldState]
  )
  const setErrorType = useCallback(
    (index: number, errorType: string) => {
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
        const newStates = [...prevStates]
        updateProperty(newStates, index, 'errorType', errorType)
        setFormFieldState(newStates)
        return newStates
      })
    },
    [setFormFieldState]
  )
  const setFileSize = useCallback(
    (index: number, size: number | undefined) => {
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
        const newStates = [...prevStates]
        updateProperty(newStates, index, 'size', size)
        setFormFieldState(newStates)
        return newStates
      })
    },
    [setFormFieldState]
  )
  const setGovWellFile = useCallback(
    (index: number, fileFragment: FileFragment) => {
      setStates((prevStates: UploadMultipleFilesFileUploadState[]) => {
        const newStates = [...prevStates]
        updateProperty(newStates, index, 'govWellFile', fileFragment)
        setFormFieldState(newStates)
        return newStates
      })
    },
    [setFormFieldState]
  )

  const cancelUpload = (index: number) => {
    const state = states[index]
    state.cancelToken?.cancel()
    removeFile(index)
  }

  const { mutateAsync: createFileRaw } = useCreateFileMutation()
  const createFile = useCallback(
    async (input: CreateFileMutationVariables) => {
      const res = await createFileRaw(input)
      return res.createFile
    },
    [createFileRaw]
  )
  const isCitizen = useIsCitizen()

  const uploadFile = useCallback(
    async (index: number, file: RcFile, newState: UploadMultipleFilesFileUploadState) => {
      if (isCitizen && file.size > MaxFileSize) {
        setErrorType(index, FileUploadErrors.TooBig)
        setFileUploadStatus(index, FileUploadStatuses.Error)
        return
      }

      if (await getIsEncryptedPdf(file)) {
        setErrorType(index, FileUploadErrors.EncryptedPdf)
        setFileUploadStatus(index, FileUploadStatuses.Error)
        return
      }

      if (!getIsFileExtensionAccepted({ accept, fileName: file.name })) {
        setErrorType(index, FileUploadErrors.UnsupportedFileType)
        setFileUploadStatus(index, FileUploadStatuses.Error)
        return
      }

      if (!bucket || !newState.cancelToken) {
        return
      }

      const newFile = await uploadFileBase({
        file,
        bucket,
        setFileSize: (size: number) => setFileSize(index, size),
        setFileName: (name: string) => setFileName(index, name),
        setFileUploadStatus: (status: FileUploadStatuses) => setFileUploadStatus(index, status),
        setFileUploadProgress: (progress: number) => setFileUploadProgress(index, progress),
        setPath: (path: string) => setPath(index, path),
        setFileGovWellFileId: (govWellFileid: number) => setFileGovWellFileId(index, govWellFileid),
        setGovWellFile: (govWellFile: FileFragment) => setGovWellFile(index, govWellFile),
        cancelToken: newState.cancelToken.token,
        createFile,
        pathPrefix,
        onError: (errorType: string) => setErrorType(index, errorType),
      })
      if (newFile) {
        const { id: fileId, path: newPath } = newFile
        await afterUpload?.(fileId, newPath)
      }
    },
    [
      accept,
      afterUpload,
      bucket,
      createFile,
      isCitizen,
      pathPrefix,
      setErrorType,
      setFileGovWellFileId,
      setFileName,
      setFileSize,
      setFileUploadProgress,
      setFileUploadStatus,
      setGovWellFile,
      setPath,
    ]
  )

  const reset = () => setStates([])

  const beforeUpload = useCallback(
    async (_: RcFile, fileList: RcFile[]) => {
      if (fileIdMap.has(fileList[0].uid)) {
        return
      }
      const newIds = fileList.map((file: RcFile) => file.uid)
      newIds.forEach((id) => fileIdMap.add(id))

      const filteredFileList = fileList.filter((file: RcFile) => !!file)
      const { prevLength, newStates } = initializeNewFileUploads(filteredFileList)
      await Promise.all(
        fileList.map((file: RcFile, index: number) =>
          uploadFile(prevLength + index, file, newStates[index])
        )
      )
      return false
    },
    [fileIdMap, initializeNewFileUploads, uploadFile]
  )

  const loading = states.some(
    (state: UploadMultipleFilesFileUploadState) => state.status === FileUploadStatuses.Uploading
  )
  const govWellFileIds = filterNil(
    states
      .filter((s) => !s.errorType)
      .map((state: UploadMultipleFilesFileUploadState) => state.govWellFileId)
      .filter((id) => !!id)
  )

  useEffect(() => {
    // Sync form state with regular state in case the files field value is modified via form state, e.g. via form auto-fill
    if (
      existingFormStates?.length &&
      existingFormStates.some((fileFragment, index) => states[index]?.path !== fileFragment.path)
    ) {
      reset()
      setStates(getInitialStates())
    }
  }, [
    existingFormStates,
    getInitialStates,
    setFileName,
    setFileSize,
    setFileUploadProgress,
    setFileUploadStatus,
    setPath,
    states,
  ])

  return {
    states,
    loading,
    govWellFileIds,
    cancelUpload,
    beforeUpload,
    reset,
    removeFile,
  }
}
