import { useEffect } from 'react'

import styled from 'styled-components'

import { useLocation } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

import { StylePropsMobile } from '../../components/shared/StyledComponents'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import TopBar from '../../components/Topbar/Topbar'
import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import useDisclosure from '../../hooks/use-disclosure'

type Props = {
  children?: React.ReactNode
}

const Layout = styled.div<StylePropsMobile>`
  display: flex;
  height: ${(props) => (props.$small ? '100%' : '100vh')};
  max-width: 100vw;
  background-color: ${({ theme }) => theme.colorMenuDarkBg};
`

const Right = styled.div<StylePropsMobile>`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: ${({ $small }) => ($small ? '100vw' : 'calc(100vw - var(--sidebar-width))')};
  height: 100vh;
`
const Content = styled.div<StylePropsMobile>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  background-color: ${({ theme }) => theme.colorWhite};
  overflow: hidden;

  ${({ $small }) =>
    !$small &&
    `
    width: calc(100% - 10px);
    margin: 10px 10px 0px 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`}
`

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`

const SidebarLayout = (props: Props) => {
  const { children } = props
  const { isSmall } = useCurrentBreakpoint()
  const { isAuthenticated } = useAuth()
  const small = isSmall(BreakpointComponents.Sidebar)
  const showTopbarLayout = small || !isAuthenticated
  const hamburgerMenuState = useDisclosure()

  const { pathname } = useLocation()
  useEffect(() => {
    hamburgerMenuState.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (!showTopbarLayout) {
      hamburgerMenuState.close()
    }
  }, [hamburgerMenuState, showTopbarLayout])

  return (
    <Layout $small={showTopbarLayout}>
      {!showTopbarLayout && <Sidebar />}
      <Right $small={showTopbarLayout}>
        {showTopbarLayout && (
          <TopBar
            onClickHamburger={hamburgerMenuState.toggle}
            hamburgerOpen={hamburgerMenuState.isOpen}
            color="blue"
          />
        )}
        {!hamburgerMenuState.isOpen && (
          <Content $small={showTopbarLayout}>
            <ScrollContainer>{children}</ScrollContainer>
          </Content>
        )}
        {hamburgerMenuState.isOpen && <Sidebar fullWidth />}
      </Right>
    </Layout>
  )
}

export default SidebarLayout
