import { useQuery } from '@redwoodjs/web'

import {
  PaymentFilterInputsQuery,
  PaymentTransferType,
  RecordFilterInputsQueryVariables,
  RecordTaskFeesPageFilters,
  SearchPaymentTransfersFilters,
} from '../../../types/graphql'
import { CheckboxOption } from '../../components/form/Checkbox'
import { FilterInput } from '../../components/TableV2/types'
import { useMyOrgActiveUsers } from '../../layouts/AppStateContextLayout/utils'
import { formatUserName } from '../../utils'
import { formatPaymentMethod } from '../../utils/fee'

const QueryPaymentFilterInputs = gql`
  query PaymentFilterInputsQuery {
    fees {
      id
      name
    }
    generalLedgerNumbers {
      ...GeneralLedgerNumberFragment
    }
    myOrganization {
      id
      finixMerchants {
        ...OrganizationFinixMerchantsFragment
      }
      paymentProvider
    }
    paymentMethodsUsedForOrg
  }
`

export interface Res {
  recordTaskFeesFilterInputs: FilterInput<RecordTaskFeesPageFilters>[]
  paymentTransfersFilterInputs: FilterInput<SearchPaymentTransfersFilters>[]
}

export const TransferTypeGovwellToDisplayMap: Record<PaymentTransferType, string> = {
  Debit: 'Customer Payment',
  Reversal: 'Refund',
  Credit: 'Credit',
  Adjustment: 'Adjustment',
  Dispute: 'Dispute',
  Fee: 'Fee',
  Settlement: 'Settlement',
  Reserve: 'Reserve',
  Unknown: 'Unknown',
}

const PaymentTransferTypeOptions: CheckboxOption<PaymentTransferType>[] = [
  {
    label: TransferTypeGovwellToDisplayMap['Debit'],
    value: 'Debit',
  },
  {
    label: TransferTypeGovwellToDisplayMap['Reversal'],
    value: 'Reversal',
  },
  {
    label: TransferTypeGovwellToDisplayMap['Credit'],
    value: 'Credit',
  },
  {
    label: TransferTypeGovwellToDisplayMap['Adjustment'],
    value: 'Adjustment',
  },
  {
    label: TransferTypeGovwellToDisplayMap['Dispute'],
    value: 'Dispute',
  },
]

const basePaymentTransferInputs: FilterInput<SearchPaymentTransfersFilters>[] = [
  {
    fieldName: 'transactionDate',
    type: 'Date',
    label: 'Transaction Date',
  },
  {
    fieldName: 'type',
    type: 'Arr',
    label: 'Type',
    checkboxOptions: PaymentTransferTypeOptions,
  },
]

const BaseRecordTaskFeeInputs: FilterInput<RecordTaskFeesPageFilters>[] = [
  {
    fieldName: 'requestedDateRange',
    label: 'Requested Date',
    type: 'Date',
  },
  {
    fieldName: 'paidDateRange',
    label: 'Payment Date',
    type: 'Date',
  },
]

export const usePaymentFilterInputs = (): Res => {
  const res = useQuery<PaymentFilterInputsQuery, RecordFilterInputsQueryVariables>(
    QueryPaymentFilterInputs
  )

  const users = useMyOrgActiveUsers()

  if (!res.data?.fees?.length && !res?.data?.generalLedgerNumbers?.length) {
    return {
      recordTaskFeesFilterInputs: BaseRecordTaskFeeInputs,
      paymentTransfersFilterInputs: basePaymentTransferInputs,
    }
  }

  const {
    data: { fees, generalLedgerNumbers, paymentMethodsUsedForOrg },
  } = res
  const finixMerchants = res.data?.myOrganization?.finixMerchants || []
  const paymentProvider = res.data?.myOrganization?.paymentProvider ?? ''

  const finixMerchantIdOptions = finixMerchants.map((fm) => ({
    label: fm.description,
    value: fm.id,
  }))

  const paymentTransferFilterFinixMerchant: FilterInput<SearchPaymentTransfersFilters> = {
    fieldName: 'oranizationFinixMerchantIds',
    label: 'Merchant',
    type: 'Arr',
    checkboxOptions: finixMerchantIdOptions,
  }

  const glFilterForPaymentTransfers: FilterInput<SearchPaymentTransfersFilters> = {
    fieldName: 'generalLedgerNumberIds',
    label: 'GL Number',
    type: 'Arr',
    checkboxOptions: generalLedgerNumbers.map((gl) => ({
      label: gl.description ? `${gl.glNumber} (${gl.description})` : gl.glNumber,
      value: gl.id,
    })),
  }

  const paymentTransferFilterProcessedBy: FilterInput<SearchPaymentTransfersFilters> = {
    fieldName: 'processedByUserIds',
    label: 'Processed By',
    type: 'Arr',
    checkboxOptions: users.map((u) => ({
      label: formatUserName(u),
      value: u.id,
    })),
  }

  const paymentMethodFilters: FilterInput<SearchPaymentTransfersFilters> = {
    fieldName: 'paymentMethod',
    type: 'Arr',
    label: 'Payment Method',
    checkboxOptions: (paymentMethodsUsedForOrg || []).map((paymentMethod) => ({
      label: formatPaymentMethod(paymentMethod, paymentProvider),
      value: paymentMethod,
    })),
  }

  const paymentTransfersFilters: FilterInput<SearchPaymentTransfersFilters>[] = [
    ...basePaymentTransferInputs,
    ...(paymentMethodsUsedForOrg?.length ? [paymentMethodFilters] : []),
    ...(generalLedgerNumbers?.length ? [glFilterForPaymentTransfers] : []),
    paymentTransferFilterProcessedBy,
    ...(finixMerchantIdOptions?.length > 1 ? [paymentTransferFilterFinixMerchant] : []),
  ]

  const glFilterForRecordTaskFees: FilterInput<RecordTaskFeesPageFilters> = {
    fieldName: 'glNumber',
    label: 'GL Number',
    type: 'Arr',
    checkboxOptions: generalLedgerNumbers.map((gl) => ({
      label: gl.description ? `${gl.glNumber} (${gl.description})` : gl.glNumber,
      value: gl.id,
    })),
  }

  const recordTaskFeesFilterFinixMerchant: FilterInput<RecordTaskFeesPageFilters> = {
    fieldName: 'oranizationFinixMerchantIds',
    label: 'Merchant',
    type: 'Arr',
    checkboxOptions: finixMerchantIdOptions,
  }

  const recordTaskFeeFilterInputs: FilterInput<RecordTaskFeesPageFilters>[] = [
    {
      fieldName: 'feeId',
      label: 'Fee',
      type: 'Arr',
      checkboxOptions: fees.map((f) => ({
        label: f.name,
        value: f.id,
      })),
    },
    ...BaseRecordTaskFeeInputs,
    ...(generalLedgerNumbers?.length ? [glFilterForRecordTaskFees] : []),
    ...(finixMerchantIdOptions?.length > 1 ? [recordTaskFeesFilterFinixMerchant] : []),
  ]

  return {
    recordTaskFeesFilterInputs: recordTaskFeeFilterInputs,
    paymentTransfersFilterInputs: paymentTransfersFilters,
  }
}
