import { ReactNode } from 'react'

import { useLazyQuery } from '@apollo/client'
import { faArrowUpRightFromSquare, faLink } from '@fortawesome/pro-regular-svg-icons'

import {
  RecordAutocompleteCellQuery,
  SearchRecordsOfTypeForOrgQuery,
  SearchRecordsOfTypeForOrgQueryVariables,
  SearchRecordsResultFragment,
} from '../../../../../types/graphql'
import useDisclosure, { UseDisclosureReturn } from '../../../../hooks/use-disclosure'
import { useSlug } from '../../../../hooks/use-slug'
import Modal from '../../../Modal'
import { AutoComplete, AutocompleteFieldValues } from '../../AutoComplete'
import { SingleOrMultipleInputProps } from '../../types'
import { FormFieldWrapperProps } from '../../Wrapper'

export const SearchRecordsOfTypeForOrgGql = gql`
  query SearchRecordsOfTypeForOrgQuery(
    $recordTemplateIds: [Int!]!
    $orgSlug: String!
    $query: String!
  ) {
    records: recordsOfTypesForOrg(
      recordTemplateIds: $recordTemplateIds
      orgSlug: $orgSlug
      query: $query
    ) {
      data {
        ...SearchRecordsResultFragment
      }
    }
  }
  fragment SearchRecordsResultFragment on Record {
    id
    stringified
  }
`

export interface RenderRecordAutocompleteModalContentFnParams {
  recordTemplates: {
    name: string
    uuid: string
  }[]
}

export type RenderRecordAutocompleteModalContentFn = (
  params: RenderRecordAutocompleteModalContentFnParams
) => ReactNode

export interface RecordAutocompleteFieldValues
  extends AutocompleteFieldValues<SearchRecordsResultFragment> {}

export interface RecordAutocompleteProps extends FormFieldWrapperProps, SingleOrMultipleInputProps {
  placeholder?: string
  disabled?: boolean
  recordTemplateIds: number[]
  submitText?: string
  preventSubmission?: boolean
  renderRecordAutocompleteModalContent?: RenderRecordAutocompleteModalContentFn
  recordTemplates: RecordAutocompleteCellQuery['recordTemplates']
  allowClear?: boolean
}

interface MappedOption {
  label: ReactNode
  value: string
  id: string
}

export const mapRecordResultToAutocompleteOption = (
  data: SearchRecordsResultFragment
): MappedOption => {
  return {
    label: data.stringified,
    value: `${data.id}`,
    id: `${data.id}`,
  }
}

export const RecordAutocomplete = (props: RecordAutocompleteProps) => {
  const {
    placeholder,
    recordTemplateIds,
    recordTemplates,
    submitText,
    preventSubmission,
    renderRecordAutocompleteModalContent,
    allowClear,
    ...formFieldWrapperProps
  } = props
  const [searchRecords] = useLazyQuery<
    SearchRecordsOfTypeForOrgQuery,
    SearchRecordsOfTypeForOrgQueryVariables
  >(SearchRecordsOfTypeForOrgGql)

  const orgSlug = useSlug()
  const search = async (query: string) => {
    const res = await searchRecords({
      variables: { query, orgSlug, recordTemplateIds },
    })
    const data = res.data?.records?.data
    return data ?? []
  }

  const modalState = useDisclosure()

  return (
    <>
      <AutoComplete<SearchRecordsResultFragment, MappedOption>
        {...formFieldWrapperProps}
        placeholder={`Search for ${placeholder}`}
        search={search}
        optionLabelProp="label"
        mapDataToOption={mapRecordResultToAutocompleteOption}
        suffixIcon={faLink}
        createOptionText={preventSubmission ? '' : submitText || 'Submit new application'}
        onSelectCreateOption={preventSubmission ? () => {} : modalState.open}
        allowClear={allowClear}
      />
      {recordTemplates?.map((recordTemplate) => {
        return (
          <RecordAutocompleteCreateNewModal
            key={recordTemplate?.uuid}
            modalState={modalState}
            recordTemplates={recordTemplates}
            submitText={submitText}
            renderRecordAutocompleteModalContent={renderRecordAutocompleteModalContent}
          />
        )
      })}
    </>
  )
}

interface CreateNewModalProps {
  modalState: UseDisclosureReturn
  submitText: string | undefined
  recordTemplates: RecordAutocompleteCellQuery['recordTemplates']
  renderRecordAutocompleteModalContent?: RenderRecordAutocompleteModalContentFn
}

const RecordAutocompleteCreateNewModal = (props: CreateNewModalProps) => {
  const { modalState, submitText, renderRecordAutocompleteModalContent, recordTemplates } = props

  return (
    <Modal
      title={submitText}
      {...modalState}
      icon={faArrowUpRightFromSquare}
      hideCancelButton
      hideOkButton
      onOk={async () => {}}
    >
      {renderRecordAutocompleteModalContent?.({
        recordTemplates:
          recordTemplates?.map(({ name, uuid }) => ({
            name: name || '',
            uuid,
          })) ?? [],
      })}
    </Modal>
  )
}
