import { useCallback } from 'react'

import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { faGear } from '@fortawesome/pro-regular-svg-icons'
import { Skeleton } from 'antd'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { GetOrCreateViewsMutation } from 'types/graphql'

import { Drawer } from 'src/components/Drawer'
import EditViewsDrawerItem from 'src/components/TableViews/EditViewsDrawer/EditViewsDrawerItem'
import { useReorderViewsMutation } from 'src/fetch/views'
import useDisclosure, { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

const LoadingContainer = styled.div<{ $isLoading: boolean }>`
  opacity: ${({ $isLoading }) => ($isLoading ? 0.5 : 1)};
`

type Props = {
  generateDefaultViews: () => Promise<GetOrCreateViewsMutation>
  tableViewManager: TableViewManager
  modalState: UseDisclosureReturn
}
const EditViewsDrawer = ({ generateDefaultViews, tableViewManager, modalState }: Props) => {
  const { isOpen: isReordering, open: startReordering, close: stopReordering } = useDisclosure()
  const { mutateAsync: reorderViews } = useReorderViewsMutation()

  const handleViewsReordered = useCallback(
    async (e: DragEndEvent) => {
      const draggedViewId = e.active?.id
      const displacedViewId = e.over?.id
      await runInAction(async () => {
        const sourceIndex = tableViewManager.viewsInDatabaseOrder.findIndex(
          (v) => v.id === draggedViewId
        )
        const destinationIndex = tableViewManager.viewsInDatabaseOrder.findIndex(
          (v) => v.id === displacedViewId
        )
        if (sourceIndex < 0 || destinationIndex < 0 || sourceIndex === destinationIndex) {
          return
        }

        startReordering()
        const newViews = [...tableViewManager.viewsInDatabaseOrder]
        const sourceView = newViews.splice(sourceIndex, 1)[0]
        newViews.splice(destinationIndex, 0, sourceView)
        tableViewManager.reorderViews(newViews.map((v) => v.id))
        tableViewManager.saveViewsOrder()

        try {
          await reorderViews({
            input: {
              viewIds: newViews.map((v) => v.id),
            },
          })
        } finally {
          stopReordering()
        }
      })
    },
    [reorderViews, startReordering, stopReordering, tableViewManager]
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  )

  return (
    <Drawer {...modalState} title="Edit Saved Views" icon={faGear} width="400px">
      <LoadingContainer $isLoading={isReordering}>
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleViewsReordered}
        >
          <SortableContext
            // rowKey array
            items={tableViewManager.viewsInDatabaseOrder.map((v) => v.id)}
            strategy={verticalListSortingStrategy}
          >
            {tableViewManager.viewsInDatabaseOrder.map((v) => (
              <EditViewsDrawerItem
                generateDefaultViews={generateDefaultViews}
                view={v}
                tableViewManager={tableViewManager}
                key={v.id}
              />
            ))}
            {!tableViewManager.viewsInDatabaseOrder.length && <Skeleton active />}
          </SortableContext>
        </DndContext>
      </LoadingContainer>
    </Drawer>
  )
}

export default observer(EditViewsDrawer)
