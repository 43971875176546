import { Breakpoint } from 'antd'

export enum BreakpointComponents {
  FileUpload = 'FileUpload',
  PaymentsModal = 'PaymentsModal',
  RecordComponent = 'RecordComponent',
  Sidebar = 'Sidebar',
  Table = 'Table',
  TaskCardButtons = 'TaskCardButtons',
  Topbar = 'Topbar',
}

export const Breakpoints: Record<BreakpointComponents, Breakpoint> = {
  FileUpload: 'sm',
  PaymentsModal: 'md',
  RecordComponent: 'md',
  Sidebar: 'md',
  Table: 'md',
  TaskCardButtons: 'md',
  Topbar: 'sm',
}
