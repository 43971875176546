import { Children, cloneElement } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import 'dragula/dist/dragula.css'
import { ReorderClassName } from './types'

interface Props extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

export const TableRow = (props: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 999 } : {}),
  }

  const clonedChildren = Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      // Clone each child and add a class name to it
      if (child?.props?.record && 'header' in child.props.record) {
        const cloneElementProps: any = {
          additionalProps: {
            className: 'table-v2-hide-checkbox',
          },
        }
        return cloneElement(child, cloneElementProps)
      }
      if (child.props.className !== ReorderClassName) {
        return child
      }
      const cloneElementProps: any = {
        additionalProps: {
          ...attributes,
          ...listeners,
          style: {
            cursor: 'move',
          },
        },
      }
      return cloneElement(child, cloneElementProps)
    }
    return child
  })

  return (
    <tr {...props} ref={setNodeRef} style={style}>
      {clonedChildren}
    </tr>
  )
}
