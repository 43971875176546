import styled from 'styled-components'

import { TableReorderType } from './types'

interface TableContainerProps {
  $showRightVerticalLine: boolean
  $hasHeader: boolean
  $reorderType: TableReorderType | undefined
  $hoveredRowIndex?: number // 1 is the first index here
  $isLoading?: boolean
}
export const TableV2Container = styled.div<TableContainerProps>`
  isolation: isolate;
  .ant-table-cell {
    padding: 8px !important;
  }
  .ant-table-cell::before {
    display: none;
  }
  .ant-table {
    overflow-x: auto;
    background-color: ${(props) => props.theme.colorFillAlter};
    border-radius: 0 0 8px 8px;
    border-left: 1px solid ${(props) => props.theme.colorSplit};
    border-right: 1px solid ${(props) => props.theme.colorSplit};
    border-bottom: 1px solid ${(props) => props.theme.colorSplit};
    ${(props) =>
      !props.$hasHeader &&
      `
      border-top: 1px solid ${props.theme.colorSplit};
      border-radius: 8px;
    `}
  }

  .ant-table {
    // alternate colors
    .ant-table-row:nth-child(odd) {
      background-color: ${(props) => props.theme.white};
    }
    .ant-table-row:nth-child(even) {
      background-color: ${(props) => props.theme.colorBgTableCellAlt};
    }

    // change color of all of the borders between rows
    .ant-table-row > .ant-table-cell {
      border-bottom: 1px solid ${(props) => props.theme.colorSplit};
    }
    tbody > .ant-table-row > .ant-table-cell:last-child {
      ${(props) =>
        props.$showRightVerticalLine && `border-left: 1px solid ${props.theme.colorSplit};`}
    }
    // remove border on last row
    tbody .ant-table-row:last-child > .ant-table-cell {
      border-bottom: 0px solid transparent;
    }
    // border radiuses on last row
    tbody .ant-table-row:last-child {
      .ant-table-cell:first-child {
        border-bottom-left-radius: 8px;
      }
      .ant-table-cell:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    .ant-table-thead > tr > .ant-table-cell {
      background: ${(props) => props.theme.colorBgLayout} !important;
      border-bottom: solid 1px ${(props) => props.theme.colorSplit};
      padding: 16px !important;
      font-size: 14px !important;
      font-weight: bold !important;
    }
    .ant-table-cell-row-hover {
      background: unset !important;
    }
    .ant-empty-image {
      display: none;
    }
    .ant-empty-description {
      color: ${(props) => props.theme.colorTextDescription};
    }
    .ant-table-cell {
      padding: 8px 16px !important;
    }

    .ant-table-cell:has(> svg.reorder) {
      ${(props) =>
        (props.$reorderType === TableReorderType.ReorderableIcon ||
          props.$reorderType === TableReorderType.ReorderableNoIcon) &&
        `
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    `}
    }

    .ant-table-cell.ant-table-column-sort {
      background-color: transparent;
    }

    tbody > .ant-table-row:nth-child(${(props) => props.$hoveredRowIndex}) {
      background-color: ${(props) => props.theme.colorTableRowHover}; // Set the color for hover
      cursor: pointer; // Optional, if you want to change the cursor on hover
    }

    .ant-table-footer {
      background-color: white;
      border-top: 1px solid ${(props) => props.theme.colorSplit};
    }
  }
  ${({ $isLoading }) => `
    opacity: ${$isLoading ? 0.5 : 1};
    pointer-events: ${$isLoading ? 'none' : 'all'};
  `};
`
