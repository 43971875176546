import { ReactNode } from 'react'

export const makeArrayFieldName = (fieldName: string | string[], suffix: string) => {
  return Array.isArray(fieldName) ? [...fieldName, suffix] : [fieldName, suffix]
}

export const makeHiddenFieldName = (
  fieldName: string | string[],
  index: number | null | undefined,
  str: string
) => {
  if (index === null || index === undefined) {
    return makeArrayFieldName(fieldName, str)
  }
  const base = makeArrayFieldName(fieldName, `${index}`)
  return makeArrayFieldName(base, str)
}

export const makeSelectedIdFieldName = (
  fieldName: string | string[],
  index: number | undefined
) => {
  return makeFieldNameVariableOnIndex(fieldName, index, 'selectedId')
}

export const makeManualAddressFieldName = (
  fieldName: string | string[],
  index: number | undefined
) => {
  return makeFieldNameVariableOnIndex(fieldName, index, 'manualAddress')
}

export const makeFieldNameVariableOnIndex = (
  fieldName: string | string[],
  index: number | undefined,
  str: string
) => {
  const base = index !== null && index !== undefined ? `${index}` : fieldName
  const res = makeArrayFieldName(base, str)
  return res
}

export const getLabelStr = (label: ReactNode): string | undefined => {
  if (typeof label === 'string') {
    return label
  }
  return undefined
}
