export const RecordTaskChecklistItemFragment = gql`
  fragment RecordTaskChecklistItemFragment on RecordTaskChecklistItem {
    id
    name
    order
    completedAt
    completedByUser {
      ...UserDataFragment
    }
  }
`
