export const LottiesLoading = {
  v: '5.5.1',
  fr: 30,
  ip: 0,
  op: 75,
  w: 512,
  h: 512,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [256, 256, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [3.912, -14.486, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [274.246, 274.246],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.9803921568627451, 0.32941176470588235, 0.10980392156862745, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 10,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 0,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [4.195, -14.521],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: {
                  x: [0.833],
                  y: [0.833],
                },
                o: {
                  x: [0.167],
                  y: [0.167],
                },
                t: 0,
                s: [36],
              },
              {
                i: {
                  x: [0.833],
                  y: [0.833],
                },
                o: {
                  x: [0.167],
                  y: [0.167],
                },
                t: 24,
                s: [0],
              },
              {
                i: {
                  x: [0.833],
                  y: [0.833],
                },
                o: {
                  x: [0.167],
                  y: [0.167],
                },
                t: 50,
                s: [0],
              },
              {
                t: 75,
                s: [36],
              },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: {
                  x: [0.833],
                  y: [0.833],
                },
                o: {
                  x: [0.167],
                  y: [0.167],
                },
                t: 0,
                s: [32],
              },
              {
                i: {
                  x: [0.833],
                  y: [0.833],
                },
                o: {
                  x: [0.167],
                  y: [0.167],
                },
                t: 24,
                s: [80],
              },
              {
                i: {
                  x: [0.833],
                  y: [0.833],
                },
                o: {
                  x: [0.167],
                  y: [0.167],
                },
                t: 37,
                s: [88],
              },
              {
                i: {
                  x: [0.833],
                  y: [0.833],
                },
                o: {
                  x: [0.167],
                  y: [0.167],
                },
                t: 50,
                s: [80],
              },
              {
                t: 75,
                s: [32],
              },
            ],
            ix: 2,
          },
          o: {
            a: 1,
            k: [
              {
                i: {
                  x: [0.833],
                  y: [0.833],
                },
                o: {
                  x: [0.167],
                  y: [0.167],
                },
                t: 0,
                s: [0],
              },
              {
                t: 75,
                s: [1080],
              },
            ],
            ix: 3,
          },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
