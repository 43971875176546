import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import { faAdd } from '@fortawesome/pro-regular-svg-icons'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import Input from 'src/components/form/Input'
import Modal from 'src/components/Modal'
import { GetViewsDataCounts, useCreateViewMutation } from 'src/fetch/views'
import { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

type FormValues = {
  name?: string
}

type Props = {
  tableViewManager: TableViewManager
  modalState: UseDisclosureReturn
  duplicateFromView?: TableView
}
const CreateViewModal = ({ tableViewManager, modalState, duplicateFromView }: Props) => {
  const { mutateAsync: createView } = useCreateViewMutation()

  const apolloClient = useApolloClient()
  const handleSubmit = useCallback(
    async ({ name }: FormValues) => {
      if (!name) {
        return
      }

      const createViewResult = await runInAction(async () => {
        const displayIndex = tableViewManager.views.length
        return await createView({
          input: duplicateFromView
            ? {
                ...tableViewManager.getCreateViewInput(duplicateFromView.protocol),
                name,
                displayIndex,
              }
            : {
                columns: tableViewManager.columnTemplates
                  .filter((ct) => ct.includeByDefault)
                  .map((ct) => ({ columnType: ct.columnType })),
                displayIndex,
                name,
                viewType: tableViewManager.viewType,
                recordTypeId: tableViewManager.recordTypeId,
              },
        })
      })
      const createdViewProtocol = createViewResult.createView
      const view = runInAction(() => tableViewManager.appendView(createdViewProtocol))

      const viewDataCountsResult = await runInAction(async () => {
        return await apolloClient.query({
          query: GetViewsDataCounts,
          variables: {
            inputs: {
              viewInput: tableViewManager.getCreateViewInput(view.protocol),
            },
          },
        })
      })

      runInAction(() => {
        const totalCount = viewDataCountsResult.data?.getViewsData?.[0]?.totalCount ?? 0
        view.databaseTotalCount = totalCount
        tableViewManager.setSelectedViewId(createdViewProtocol.id)
      })
      modalState.close()
    },
    [apolloClient, createView, duplicateFromView, modalState, tableViewManager]
  )

  return (
    <Modal
      title={duplicateFromView ? `Duplicate View "${duplicateFromView.name}"` : 'Create View'}
      icon={faAdd}
      onOk={handleSubmit}
      okText={duplicateFromView ? 'Duplicate view' : 'Create view'}
      form
      formInitialValues={{ name: duplicateFromView?.name }}
      {...modalState}
    >
      <Input fieldName="name" required errorMessage="Views must have a name" label="Name" />
    </Modal>
  )
}

export default observer(CreateViewModal)
