import { useEffect } from 'react'

import Intercom from '@intercom/messenger-js-sdk'

import { formatUserName } from 'src/utils'

import { OrganizationFragmentWithUsers, UserDataFragment } from '../../types/graphql'
import { EnvVariables } from '../constants'

import { useSlug } from './use-slug'

type Params = {
  me: UserDataFragment | null | undefined
  organization: OrganizationFragmentWithUsers | null | undefined
}

export const useSetUpIntercom = ({ me, organization }: Params) => {
  const stringifiedMe = me ? JSON.stringify(me) : ''
  const slug = useSlug()
  useEffect(() => {
    if (!EnvVariables.IntercomAppId) {
      return
    }
    Intercom({
      api_base: EnvVariables.IntercomApiBase,
      app_id: EnvVariables.IntercomAppId,
      ...(me
        ? {
            name: formatUserName(me), // Full name
            user_id: `${me.id}`, // a UUID for your user
            email: me.emailAddress ?? '', // the email for your user
            created_at: new Date(me.createdAt ?? '').getTime(), // Signup date as a Unix time in milliseconds
            IsGovStaff: me.organizationId ? 'True' : 'False',
            GovStaffGroup: me.group || null,
            GovStaffCity: organization?.city ?? '',
            Title: me.title ?? '',
            IsPlanReviewer: me.isPlanReviewer ? 'True' : 'False',
            IsInspector: me.isInspector ? 'True' : 'False',
            IsApplicant: !me?.organizationId ? 'True' : 'False',
          }
        : {}),
    })
  }, [me, organization?.city, slug, stringifiedMe])
}
