import { useCallback, useEffect, useState } from 'react'

import { PaginationState } from '@tanstack/react-table'

export const DEFAULT_PAGE_SIZE = 50

export type SortingState<TSortKey> = {
  id: TSortKey
  desc: boolean
}[]

export const useTableState = <TSortKey extends string = string>(args?: {
  autoResetKey?: string
  initialState?: {
    pagination?: PaginationState
    sorting?: SortingState<TSortKey>
  }
}) => {
  const { autoResetKey, initialState } = args ?? {}
  const [searchQuery, setSearchQuery] = useState('')
  const defaultPaginationState = {
    pageIndex: initialState?.pagination?.pageIndex ?? 0,
    pageSize: initialState?.pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
  }
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationState)
  const defaultSortingState = initialState?.sorting ?? []
  const [sorting, setSorting] = useState<SortingState<TSortKey>>(defaultSortingState)

  useEffect(() => {
    setPagination(defaultPaginationState)
    setSorting(defaultSortingState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoResetKey, searchQuery])

  const onSortingChange = useCallback(
    (updateFn: (sorting: SortingState<TSortKey>) => SortingState<TSortKey>) => {
      setSorting(updateFn(sorting))
    },
    [sorting]
  )

  return {
    onPaginationChange: setPagination,
    onSearchQueryChange: setSearchQuery,
    onSortingChange,
    pagination,
    searchQuery,
    sorting,
  }
}
