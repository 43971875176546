import { Coordinate } from '../../../../types/graphql'

import { LeafletCoordinate, MapProps } from './types'

export const getAllCoordinates = (props: MapProps): LeafletCoordinate[] => {
  const { shapes, pins } = props
  const res: LeafletCoordinate[] = []
  shapes?.forEach((shape) => {
    shape.coordinates.forEach((coordinate) => {
      res.push(mapGWCoordinateToLeafletCoordinate(coordinate))
    })
  })
  pins?.forEach((pin) => {
    res.push(mapGWCoordinateToLeafletCoordinate(pin.coordinate))
  })
  return res
}

export const areCoordinatesEqual = (c1: number[], c2: number[]): boolean => {
  return c1[0] === c2[0] && c1[0] === c2[0]
}

export const mapGWCoordinateToLeafletCoordinate = (coordinate: Coordinate): LeafletCoordinate => {
  return [coordinate.latitude ?? 0, coordinate.longitude ?? 0]
}
