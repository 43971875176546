import { Tag } from 'antd'
import dayjs from 'dayjs'
import {
  OrganizationInspectionCoordinationType,
  RecordTaskInspectionAttemptFragment,
} from 'types/graphql'

import { DateFormats, formatDate } from '../../../../../utils/date'

type Props = {
  attempt: RecordTaskInspectionAttemptFragment
  isAvailable: boolean
  showDate?: boolean
  inspectionCoordinationType: OrganizationInspectionCoordinationType
}

const TableCellInspectionStatus = (props: Props) => {
  const { attempt, isAvailable, showDate, inspectionCoordinationType } = props

  const scheduledStr = attempt.scheduledFor
    ? formatDate(attempt.scheduledFor, DateFormats.MonthDayFullYear)
    : ''

  const requestedStr = attempt.requestedFor
    ? formatDate(attempt.requestedFor, DateFormats.MonthDayFullYear)
    : ''

  if (!isAvailable && !attempt?.status) {
    return <Tag>Not Available Yet</Tag>
  }

  switch (attempt?.status) {
    case 'Scheduled': {
      const scheduled = dayjs(attempt.scheduledFor)
      const now = dayjs()
      const isToday = scheduled.isSame(now, 'day')
      if (scheduled.isBefore(now) && !isToday) {
        return (
          <Tag color="gold">{showDate ? `Overdue (Scheduled for ${scheduledStr})` : 'Overdue'}</Tag>
        )
      }
      return (
        <Tag color="purple">
          {showDate ? `Scheduled for ${isToday ? 'Today' : scheduledStr}` : 'Scheduled'}
        </Tag>
      )
    }
    case 'Cancelled': {
      return <Tag color="red">Cancelled</Tag>
    }
    case 'Requested': {
      return <Tag color="pink">{showDate ? `Requested for ${requestedStr}` : 'Requested'}</Tag>
    }
    case 'Done': {
      return <Tag color={attempt.result?.isPassing ? 'green' : 'red'}>{attempt.result?.name}</Tag>
    }
    case 'Draft': {
      return <Tag color="default">Draft</Tag>
    }
    default: {
      return (
        <Tag color="blue">
          {inspectionCoordinationType === 'SchedulingRequest' ? 'Not Requested' : 'Not Scheduled'}
        </Tag>
      )
    }
  }
}

export default React.memo(TableCellInspectionStatus)
