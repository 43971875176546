import { ReactNode } from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { Flex } from 'antd'
import Title from 'antd/es/typography/Title'
import { Input } from 'govwell-ui'

import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'

type Props = {
  actions?: React.ReactNode
  onSearchQueryChange?: (value: string) => void
  searchQuery?: string
  title?: ReactNode
}
const TableControls = ({ actions, onSearchQueryChange, searchQuery = '', title }: Props) => {
  const { isSmallScreen } = useCurrentBreakpoint()
  return (
    <Flex align="center" justify="space-between" gap="12px">
      {title && (
        <div>
          {typeof title === 'string' ? (
            <Title level={5} style={{ margin: 0 }}>
              {title}
            </Title>
          ) : (
            title
          )}
        </div>
      )}
      {onSearchQueryChange && (
        <Input
          isClearable
          placeholder="Search"
          prefixIcon={faSearch}
          value={searchQuery}
          onValueChange={onSearchQueryChange}
          width={isSmallScreen() ? '100%' : '240px'}
        />
      )}
      <div>{actions}</div>
    </Flex>
  )
}

export default React.memo(TableControls)
