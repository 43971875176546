import { MutableRefObject, createContext, useContext } from 'react'

import { RecordMenuKey } from 'src/pages/RecordPage/Record/constants'

import { MenuState } from '../../../components/Menu/types'
import { SetState } from '../../../components/PlanReviewModal/PlanReviewContext'
import { UseDisclosureReturn } from '../../../hooks/use-disclosure'
import { RefetchFnType } from '../../../types'

export type RecordContextType = {
  orgSlug: string
  orgUuid: string
  detailsTabOverviewState: UseDisclosureReturn
  detailsTabFormSubmittalState: UseDisclosureReturn
  mailerIdempotancy: string
  setMailerIdempotancy: SetState<string>
  refetch: RefetchFnType
  isUpdatingApplication: boolean
  setIsUpdatingApplication: SetState<boolean>
  updateApplicationRef: MutableRefObject<HTMLDivElement | null>
  setUpdateApplicationRef: SetState<MutableRefObject<HTMLDivElement | null>>
  tabsMenuState: MenuState
  getIsMenuKeySelected: (key: RecordMenuKey) => boolean
}

export const RecordContext = createContext<RecordContextType>({} as RecordContextType)
export const useRecordContext = () => useContext(RecordContext)
