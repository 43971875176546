import dayjs from 'dayjs'
import isNil from 'lodash.isnil'

import { FilterBool, FilterDateRange, FilterInts, FilterStrings } from '../../../types/graphql'
import { DateFormats, formatDate } from '../../utils/date'

import { FilterInput } from './types'

export type FilterParamType = FilterDateRange | FilterInts | FilterStrings | FilterBool

export interface FilterInputDisplay extends FilterInput {
  display: string
  rawVal: any
}

interface ConstructedFilterParams {
  backendFilterInputs: Record<string, FilterParamType>
  filterInputDisplays: FilterInputDisplay[]
}

export const constructFilterParams = (
  values: Record<string, any> | undefined,
  filterInputs?: FilterInput[]
): ConstructedFilterParams => {
  const backendFilterInputs: Record<string, FilterParamType> = {}
  const filterInputDisplays: FilterInputDisplay[] = []

  if (!filterInputs?.length) {
    return {
      backendFilterInputs: {},
      filterInputDisplays: [],
    }
  }

  const getFilterParams = (filterInput: FilterInput, rawVal?: any) => {
    if (isNil(rawVal)) {
      return undefined
    }
    if (filterInput.type === 'Date') {
      if (rawVal[0] && rawVal[1]) {
        const res: FilterDateRange = {
          start: dayjs(rawVal[0]).startOf('day').toISOString(),
          end: dayjs(rawVal[1]).endOf('day').toISOString(),
        }
        return res
      }
      return undefined
    }
    if (filterInput.type === 'Arr') {
      if (!rawVal.length) {
        return undefined
      }
      const res: FilterInts = {
        values: rawVal as any[],
      }
      return res
    }
    if (filterInput.type === 'Strings') {
      if (!rawVal) {
        return undefined
      }
      const res: FilterStrings = {
        strings: rawVal,
      }
      return res
    }
    if (filterInput.type === 'Bool') {
      const res: FilterBool = {
        value: rawVal === 'true',
      }
      return res
    }
  }

  const getFilterInputDisplay = (filterInput: FilterInput, rawVal?: any): string => {
    const { label, type } = filterInput
    switch (type) {
      case 'Date': {
        return `${label}: ${formatDate(
          rawVal[0],
          DateFormats.MonthDayYear
        )} to ${formatDate(rawVal[1], DateFormats.MonthDayYear)}`
      }
      case 'Arr': {
        const options = filterInput.checkboxOptions?.filter((o) => rawVal.includes(o.value))
        const str = options?.map((o) => o.label).join(', ')
        return `${label}: ${str}`
      }
      case 'Strings': {
        return `${label}: ${rawVal.join(', ')}`
      }
      case 'Bool': {
        return `${label}: ${rawVal === 'true' ? 'True' : 'False'}`
      }
    }
  }

  filterInputs.forEach((filterInput) => {
    const rawVal = values?.[filterInput.fieldName as string]
    const params = getFilterParams(filterInput, rawVal)
    if (params) {
      backendFilterInputs[filterInput.fieldName as string] = params
      filterInputDisplays.push({
        ...filterInput,
        rawVal,
        display: getFilterInputDisplay(filterInput, rawVal),
      })
    }
  })

  return { backendFilterInputs, filterInputDisplays }
}
