import { ReactNode } from 'react'

import { ColumnsType } from 'antd/es/table'

import Text, { TextSize } from '../Typography/Text'

import { GovWellTableProps, Table } from './Table'

export interface ColumnData {
  fieldName: ReactNode
  fieldValue: ReactNode
  key?: string
}

interface Props extends GovWellTableProps {
  dataSource: ColumnData[]
}

export const FieldValueTable = (props: Props) => {
  const { dataSource, ...govWellTableProps } = props

  const renderFieldName = (fieldName: ReactNode): ReactNode => (
    <Text size={TextSize.Base} strong>
      {fieldName}
    </Text>
  )
  const renderFieldValue = (fieldValue: ReactNode): ReactNode => (
    <Text size={TextSize.Base}>{fieldValue}</Text>
  )

  const columns: ColumnsType<ColumnData> = [
    {
      dataIndex: 'fieldName',
      key: 'fieldName',
      render: renderFieldName,
      responsive: ['sm'],
      width: '30%',
    },
    {
      dataIndex: 'fieldValue',
      key: 'fieldValue',
      render: renderFieldValue,
      responsive: ['sm'],
      width: '70%',
    },
    {
      key: 'fieldValueMobile',
      responsive: ['xs'],
      width: '100%',
      render: (data: ColumnData) => (
        <>
          {renderFieldName(data.fieldName)}
          {renderFieldValue(data.fieldValue)}
        </>
      ),
    },
  ]

  return <Table columns={columns} dataSource={dataSource} {...govWellTableProps} />
}
