import { useCallback, useMemo, useRef } from 'react'

import { Flex, Tag } from 'antd'
import styled from 'styled-components'

import { UniversalSearchType } from 'src/components/UniversalSearchModal/util'
import { useAmIReviewer, useMyOrg, useMyRecordTypes } from 'src/layouts/AppStateContextLayout/utils'
import { KeyboardKey } from 'src/types'

const StyledFlex = styled(Flex)`
  padding: 12px 0px;
`
const StyledTag = styled(Tag)`
  margin: 0;
  cursor: pointer;
`

type Props = {
  searchType: UniversalSearchType
  onSearchTypeChange: (searchType: UniversalSearchType) => void
}
const UniversalSearchTypeRadioGroup = ({ searchType, onSearchTypeChange }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const recordTypes = useMyRecordTypes()
  const hasCodeEnforcementModule = useMyOrg()?.hasCodeEnforcementModule
  const isReviewer = useAmIReviewer()

  const allSearchTypes: UniversalSearchType[] = useMemo(() => [{ label: 'All', mode: 'all' }], [])
  const recordSearchTypes: UniversalSearchType[] = useMemo(
    () =>
      recordTypes
        ?.filter((rt) => rt.shouldShowInStaffNavigation)
        .map((rt) => ({
          label: rt.name,
          mode: 'records',
          recordTypeId: rt.id,
        })) ?? [],
    [recordTypes]
  )
  const violationsSearchTypes: UniversalSearchType[] = useMemo(
    () =>
      hasCodeEnforcementModule && !isReviewer
        ? [
            {
              label: `Violations`,
              mode: 'violations',
            },
          ]
        : [],
    [hasCodeEnforcementModule, isReviewer]
  )
  const addressSearchTypes: UniversalSearchType[] = useMemo(
    () => [
      {
        label: 'Addresses',
        mode: 'addresses',
      },
      {
        label: 'Parcels',
        mode: 'parcels',
      },
    ],
    []
  )

  const searchTypes: UniversalSearchType[] = useMemo(
    () => [
      ...allSearchTypes,
      ...recordSearchTypes,
      ...violationsSearchTypes,
      ...addressSearchTypes,
    ],
    [addressSearchTypes, allSearchTypes, recordSearchTypes, violationsSearchTypes]
  )

  const count = searchTypes.length

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!containerRef.current?.contains(document.activeElement)) {
        return // Don't do anything if radio does not have focus
      }

      const getSelectedIndex = () => {
        const radioItems = (containerRef.current?.querySelectorAll("[role='radio']") ??
          []) as HTMLInputElement[]
        return {
          selectedIndex: Array.from(radioItems).indexOf(e.currentTarget as HTMLInputElement),
          radioItems,
        }
      }
      switch (e.key) {
        case KeyboardKey.ArrowDown:
        case KeyboardKey.ArrowLeft:
        case KeyboardKey.ArrowRight:
        case KeyboardKey.ArrowUp:
          {
            // Move to next or previous menu item
            const direction = [KeyboardKey.ArrowDown, KeyboardKey.ArrowRight].includes(e.key)
              ? 1
              : -1

            const { selectedIndex, radioItems } = getSelectedIndex()
            if (selectedIndex < 0) {
              return
            }
            let newIndex = (selectedIndex + direction) % count
            if (newIndex < 0) {
              newIndex = count - 1
            }

            const newSearchType = searchTypes[newIndex]
            if (!newSearchType) {
              return
            }
            onSearchTypeChange(newSearchType)
            radioItems[newIndex]?.focus()
            e.preventDefault()
            e.stopPropagation()
          }
          break
      }
    },
    [count, onSearchTypeChange, searchTypes]
  )

  return (
    <StyledFlex align="center" gap="6px" wrap="wrap" role="radiogroup" ref={containerRef}>
      {searchTypes.map((st) => {
        const isChecked = st.mode === searchType.mode && st.recordTypeId === searchType.recordTypeId
        return (
          <StyledTag
            key={`${st.mode}-${st.recordTypeId}`}
            onClick={() => onSearchTypeChange(st)}
            role="radio"
            aria-checked={isChecked}
            tabIndex={isChecked ? 0 : -1}
            onKeyDown={handleKeyDown}
            color={isChecked ? 'blue' : 'default'}
          >
            {st.label}
          </StyledTag>
        )
      })}
    </StyledFlex>
  )
}

export default React.memo(UniversalSearchTypeRadioGroup)
