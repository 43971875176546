import { useEffect } from 'react'

import 'src/lib/fragments' // This must be imported so that fragments are registered before queries use them
import { ConfigProvider } from 'antd'
import useNotification from 'antd/es/notification/useNotification'
import { configure as configureMobX } from 'mobx'
import { ThemeProvider } from 'styled-components'

import { FatalErrorBoundary, Helmet, RedwoodProvider } from '@redwoodjs/web'
import { GraphQLClientConfigProp, RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import possibleTypes from 'src/graphql/possibleTypes'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import { NotificationContext } from './components/shared/Notification/NotificationContext'
import { AntDesignButtonOverride, AntDesignThemeOverride, Theme } from './constants/theme'
import { GovWellEvervaultProvider } from './utils/foreignStorage'
import './index.css'
import './scaffold.css'
import { QueryClientProvider } from './utils/queryClient'
import { GovWellRequestHeaders } from './utils/requestHeaders'

configureMobX({
  computedRequiresReaction: false,
  observableRequiresReaction: false,
  reactionRequiresObservable: false,
})

const graphQLClientConfig: GraphQLClientConfigProp = {
  httpLinkConfig: {
    headers: {
      [GovWellRequestHeaders.TimeZone]: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  },
  cacheConfig: {
    possibleTypes: possibleTypes.possibleTypes,
  },
}

const App = () => {
  useEffect(() => {
    const loader = document.getElementById('loader')
    if (loader) {
      loader.style.display = 'none'
    }
  }, [])

  const [notification, contextHolder] = useNotification()

  return (
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider>
        <RedwoodApolloProvider useAuth={useAuth} graphQLClientConfig={graphQLClientConfig}>
          <ThemeProvider theme={Theme}>
            <ConfigProvider theme={AntDesignThemeOverride} button={AntDesignButtonOverride}>
              <FatalErrorBoundary page={FatalErrorPage}>
                <Helmet>
                  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.js"></script>
                  <script src="https://cdn.tiny.cloud/1/YOUR_API_KEY/tinymce/5/tinymce.min.js"></script>
                  <script type="text/javascript" src="https://js.finix.com/v/1/finix.js"></script>
                </Helmet>
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
                <NotificationContext.Provider value={{ notification }}>
                  <QueryClientProvider>
                    <GovWellEvervaultProvider>
                      {contextHolder}
                      <Routes />
                    </GovWellEvervaultProvider>
                  </QueryClientProvider>
                </NotificationContext.Provider>
              </FatalErrorBoundary>
            </ConfigProvider>
          </ThemeProvider>
        </RedwoodApolloProvider>
      </AuthProvider>
    </RedwoodProvider>
  )
}

export default App
