import { faBookOpen } from '@fortawesome/pro-regular-svg-icons'

import Modal from 'src/components/Modal'

import { InspectionsTabModalProps } from '../types'

import { RecordTaskInspectionAttemptResults } from './RecordTaskInspectionAttemptResults'
import { ViewRecordTaskInspectionAttemptResultsModalCellProps } from './types'

export const QUERY = gql`
  query ViewRecordTaskInspectionAttemptResultsModalCellQuery($recordTaskInspectionAttemptId: Int!) {
    attempt: recordTaskInspectionAttempt(id: $recordTaskInspectionAttemptId) {
      ...ViewRecordTaskInspectionAttemptFragment
    }
  }
`

export const Loading = () => <></>

type Props = ViewRecordTaskInspectionAttemptResultsModalCellProps &
  InspectionsTabModalProps & {
    recordTaskInspectionAttemptId: number
  }

export const Success = (props: Props) => {
  const {
    modalState,
    attempt,
    attempt: { recordTaskInspection },
  } = props
  const inspectionTemplateName = recordTaskInspection?.inspectionTemplate?.name || ''

  const title = `${inspectionTemplateName} Inspection`

  const onOk = async () => {
    modalState.close()
  }

  return (
    <Modal {...modalState} icon={faBookOpen} title={title} okText="Ok" onOk={onOk} hideCancelButton>
      <RecordTaskInspectionAttemptResults attempt={attempt} />
    </Modal>
  )
}
