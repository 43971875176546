import { navigate, routes, useLocation } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { useResetAuthState } from 'src/hooks/use-reset-auth-state'

export const useRedirectToLoginIfUnauthenticated = (args?: { enabled?: boolean }) => {
  const { enabled = true } = args ?? {}
  const { isAuthenticated, loading: isLoadingAuth } = useAuth()
  const { pathname } = useLocation()
  const resetAuthState = useResetAuthState()
  if (!isAuthenticated && !isLoadingAuth && enabled) {
    void (async () => {
      await resetAuthState()
      navigate(routes.login(pathname ? { redirectTo: pathname } : {}))
    })()
  }
}
