import { createPortal } from 'react-dom'

export const getModalRoot = () => document.querySelector('#modal-root') as HTMLElement

type Props = {
  children: React.ReactNode
  container?: Element | DocumentFragment | null
}
const Portal = ({ children, container: propContainer }: Props) => {
  const defaultContainer = getModalRoot()
  const container = propContainer !== undefined ? propContainer : defaultContainer
  if (container) {
    return createPortal(children, container)
  }
  return children
}

export default React.memo(Portal)
