import { useMemo } from 'react'

import { DelayType } from 'types/graphql'

import Select, { BasicOptionType, SelectProps } from 'src/components/form/Select'
import { getLabelForDateFilterDelayType } from 'src/components/TableViews/util'

const delayTypes: DelayType[] = ['Day', 'Week', 'Month', 'Year']

type Props = Omit<SelectProps<BasicOptionType<DelayType>>, 'options'> & {
  isPlural: boolean
}
const DateFilterDelayTypeSelect = ({ isPlural, ...selectProps }: Props) => {
  const options: BasicOptionType<DelayType>[] = useMemo(
    () =>
      delayTypes.map((delayType: DelayType) => ({
        label: getLabelForDateFilterDelayType(delayType, isPlural),
        value: delayType,
      })),
    [isPlural]
  )

  return <Select options={options} {...selectProps} />
}

export default React.memo(DateFilterDelayTypeSelect)
