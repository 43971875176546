import styled from 'styled-components'

import {
  StylePropsMobile,
  getContainerStyleProps,
  removeContainerStyleProps,
} from '../shared/StyledComponents'

interface MenuContainerProps extends StylePropsMobile {
  $noStyle: boolean | undefined
}
export const MenuContainerHorizontal = styled.div<MenuContainerProps>`
  .menuContainerClass {
    .ant-menu-item-selected {
      color: ${(props) => props.theme.colorTextHeading};
    }
    .ant-menu-item-selected::after {
      border-bottom: 2px solid ${(props) => props.theme.colorTextHeading};
    }
    .ant-menu-item-selected:hover::after {
      border-bottom: 2px solid ${(props) => props.theme.colorTextHeading};
    }
  }
  ul {
    border-bottom: 0;
    ${(props) =>
      props.$noStyle ? removeContainerStyleProps() : getContainerStyleProps(props.theme)}
    display: flex;
    width: 100%;
    justify-content: ${(props) => (props.$small ? 'space-between' : 'space-around')};
    overflow-x: auto;
    overflow-y: hidden;
  }
  .ant-menu::before {
    content: none;
  }
  .ant-menu::after {
    content: none;
  }
`

export const MenuItemLabelContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

export const MenuContainerVertical = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  .ant-menu {
    border-inline-end: unset !important;
  }
  .ant-menu-item-selected {
    color: ${(props) => props.theme.colorPrimaryBase};
  }
`
export const MenuVerticalItemContainer = styled.div`
  width: 100%;
`
