import { useCallback } from 'react'

import { faDollar } from '@fortawesome/pro-regular-svg-icons'

import { routes, useLocation } from '@redwoodjs/router'

import Navigation from 'src/components/Sidebar/Navigation/Navigation'

type Props = {
  isOpen: boolean
  onToggleIsOpen: () => void
}
export const PaymentsNavItems = ({ isOpen, onToggleIsOpen }: Props) => {
  const { pathname } = useLocation()

  const checkIsActive = useCallback((href: string) => pathname === href, [pathname])
  return (
    <Navigation.Group isOpen={isOpen} onToggleIsOpen={onToggleIsOpen}>
      <Navigation.GroupHeader icon={faDollar} title="Payments" />
      <Navigation.GroupList>
        <Navigation.GroupListItem
          title="Transactions"
          isActive={checkIsActive(routes.paymentTransfers())}
          href={routes.paymentTransfers()}
        />
        <Navigation.GroupListItem
          title="Invoices"
          isActive={checkIsActive(routes.recordTaskPayments())}
          href={routes.recordTaskPayments()}
        />
        <Navigation.GroupListItem
          title="Fees"
          isActive={checkIsActive(routes.recordTaskFees())}
          href={routes.recordTaskFees()}
        />
      </Navigation.GroupList>
    </Navigation.Group>
  )
}
