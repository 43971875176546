import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { StyledHeader } from 'src/components/TableViews/ManageColumnsDrawer/styled-components'

export const HIDDEN_SECTION_HEADER_KEY = 'HIDDEN_SECTION_HEADER_KEY'

const HiddenColumnsHeader = () => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: HIDDEN_SECTION_HEADER_KEY,
  })
  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        pointerEvents: 'none',
        marginTop: '12px',
      }}
    >
      <StyledHeader {...attributes} {...listeners}>
        Hidden in table
      </StyledHeader>
    </div>
  )
}

export default React.memo(HiddenColumnsHeader)
