import { useMemo } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  PaginationState,
  getSortedRowModel,
  OnChangeFn,
  ColumnSort,
  ColumnDef,
  getPaginationRowModel,
} from '@tanstack/react-table'
import { SortingState } from 'govwell-ui/components/Table/use-table-state'

type Params<TData, TSortKey extends string = string> = {
  columns: ColumnDef<TData, any>[]
  data: TData[] | null | undefined
  onPaginationChange?: (pagination: PaginationState) => void
  onSortingChange?: (updateFn: (sorting: SortingState<TSortKey>) => SortingState<TSortKey>) => void
  pagination?: PaginationState
  sorting?: SortingState<TSortKey>
}
export const useTable = <TData, TSortKey extends string = string>({
  columns,
  data: _data,
  pagination,
  onPaginationChange,
  onSortingChange,
  sorting,
}: Params<TData, TSortKey>) => {
  const data = useMemo(() => _data ?? [], [_data])

  const isPaginationEnabled = !!pagination
  const isSortingEnabled = !!data.length && !!sorting

  const tableState = useReactTable<TData>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    ...(isPaginationEnabled
      ? {
          getPaginationRowModel: getPaginationRowModel(),
        }
      : {}),
    ...(isSortingEnabled
      ? {
          getSortedRowModel: getSortedRowModel(),
          manualSorting: true,
          onSortingChange: onSortingChange as OnChangeFn<ColumnSort[]> | undefined,
        }
      : {}),
    state: {
      ...(isPaginationEnabled ? { pagination, onPaginationChange } : {}),
      ...(isSortingEnabled
        ? {
            sorting,
          }
        : {}),
    },
  })

  return {
    tableState,
  }
}
