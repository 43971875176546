import { OrderMode } from '../../types/graphql'

export type NullablePartial<T> = { [P in keyof T]?: T[P] | null }

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T
}

export type RefetchFnType = () => Promise<unknown>

export interface MakeOrderInput<FiltersType = any> {
  mode: OrderMode
  fieldName: keyof FiltersType
}

export type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }
export type XOR<T, U> = (T & Without<U, T>) | (U & Without<T, U>)

export enum KeyboardKey {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Backspace = 'Backspace',
  Delete = 'Delete',
  Enter = 'Enter',
  Escape = 'Escape',
  K = 'k',
  Space = ' ',
  Tab = 'Tab',
}

export type ValidationOutcome =
  | {
      isValid: true
      invalidReason?: never
    }
  | {
      isValid: false
      invalidReason: string
    }

export enum LocalStorageKey {
  NavigationCodeV1IsOpen = 'NavigationCodeV1IsOpen',
  NavigationRecordTypeIsOpenMap = 'NavigationRecordTypeIsOpenMap',
  RecordTemplateFormStateMap = 'RecordTemplateFormStateMap',
}
