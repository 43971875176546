import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { FileUploadStatuses } from '../../hooks/use-file-upload-state'
import {
  FilesUploadState,
  UploadMultipleFilesFileUploadState,
} from '../../hooks/use-files-upload-state'
import { Default } from '../FileUpload/states/Default'
import { UploadingHeader } from '../FileUpload/states/Uploading'
import { StyledDragger } from '../StyledComponents'

import { ErrorFileCard } from './ErrorFileCard'
import { UploadedFileCard } from './UploadedFileCard'
import { UploadingFileCard } from './UploadingFileCard'

interface Props extends FilesUploadState {
  accept?: string
  disabled?: boolean
  fieldName?: string
  error?: boolean
}

export const FilesUpload = (props: Props) => {
  const { accept, disabled, states, beforeUpload, removeFile, cancelUpload, error } = props

  const getOverallStatus = () => {
    if (states.length === 0) {
      return FileUploadStatuses.Default
    }
    if (
      states.some(
        (state: UploadMultipleFilesFileUploadState) => state.status === FileUploadStatuses.Uploading
      )
    ) {
      return FileUploadStatuses.Uploading
    }
    return FileUploadStatuses.Done
  }

  const overallFilesUploadStatus = getOverallStatus()

  const getTopContent = () => {
    switch (overallFilesUploadStatus) {
      case FileUploadStatuses.Default:
      case FileUploadStatuses.Done:
        return <Default accept={accept} disabled={disabled} />
      case FileUploadStatuses.Uploading:
        return <UploadingHeader />
    }
  }

  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)

  return (
    <>
      <StyledDragger
        accept={accept}
        className="dragger-class-name"
        $disabled={disabled}
        disabled={disabled}
        name="file"
        action=""
        multiple
        beforeUpload={beforeUpload}
        $small={small}
        $error={error}
        style={{ marginBottom: states.length ? '12px' : '0' }}
      >
        {getTopContent()}
      </StyledDragger>
      {states.map((state: UploadMultipleFilesFileUploadState, index: number) => {
        if (state.status === FileUploadStatuses.Uploading) {
          return (
            <UploadingFileCard {...state} key={state.id} cancelUpload={() => cancelUpload(index)} />
          )
        }
        if (
          state.status === FileUploadStatuses.Done ||
          state.status === FileUploadStatuses.Existing
        ) {
          return <UploadedFileCard {...state} key={state.id} onRemove={() => removeFile(index)} />
        }
        if (state.status === FileUploadStatuses.Error) {
          return <ErrorFileCard {...state} key={state.id} onRemove={() => removeFile(index)} />
        }
      })}
    </>
  )
}
