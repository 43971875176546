import { ReactNode, useState } from 'react'

import { IconDefinition, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer as AntdDrawer, Button, Flex, Form, FormInstance, Tooltip } from 'antd'
import Title from 'antd/es/typography/Title'
import { getModalRoot } from 'govwell-ui/components/Portal/Portal'
import { styled } from 'styled-components'

import { RequiredMark } from 'src/components/form/RequiredMark'
import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'

import { UseDisclosureReturn } from '../hooks/use-disclosure'

const StyledAntDrawer = styled(AntdDrawer)<{
  $contentPadding?: React.CSSProperties['padding']
}>`
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }
  .ant-drawer-body {
    padding: ${({ $contentPadding }) => $contentPadding ?? '24px'};
  }
`
const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 24px;
`

type Props<TFormValues> = Pick<UseDisclosureReturn, 'isOpen' | 'close'> & {
  title?: React.ReactNode
  children?: ReactNode | ((formValues: TFormValues, formMethods: FormInstance) => ReactNode)
  contentPadding?: React.CSSProperties['padding']
  width?: string
  onOk?: (formValues?: TFormValues) => void | Promise<void>
  form?: boolean
  formInitialValues?: object
  watchFields?: string[]
  okDisabled?: boolean
  okDisabledReason?: string
  okText?: string
  onError?: (error: Error) => void
  icon?: IconDefinition
  headerRef?: React.RefObject<HTMLDivElement>
  footer?: React.ReactNode
  footerRef?: React.RefObject<HTMLDivElement>
}

export function Drawer<FormValues>(props: Props<FormValues>) {
  const {
    title,
    children,
    contentPadding,
    isOpen,
    close,
    width,
    okDisabled,
    okDisabledReason,
    onOk,
    watchFields,
    form,
    formInitialValues,
    okText,
    onError,
    icon,
    headerRef,
    footer,
    footerRef,
  } = props

  const [formMethods] = Form.useForm()
  const fieldsValues = formMethods.getFieldsValue()

  const [isSubmitting, setIsSubmitting] = useState(false)
  Form.useWatch(watchFields, formMethods)

  const onFormSubmit = async () => {
    try {
      setIsSubmitting(true)
      await formMethods.validateFields()
      await onOk?.(formMethods.getFieldsValue())
      formMethods.resetFields()
    } catch (e) {
      if (e instanceof Error) {
        onError?.(e)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const renderedChildren =
    typeof children === 'function' ? children(fieldsValues, formMethods) : children

  const defaultFooter = onOk ? (
    <>
      <Button onClick={close}>Cancel</Button>
      <Tooltip title={okDisabled ? okDisabledReason : ''}>
        <Button type="primary" onClick={onFormSubmit} loading={isSubmitting} disabled={okDisabled}>
          {okText || title}
        </Button>
      </Tooltip>
    </>
  ) : null
  const footerContent = footer ?? defaultFooter
  const { isSmallScreen } = useCurrentBreakpoint()

  return (
    <StyledAntDrawer
      open={isOpen}
      width={isSmallScreen() ? '100vw' : width}
      onClose={close}
      closeIcon={
        <FontAwesomeIcon
          size="xl"
          icon={faXmark}
          style={{ color: 'black' }}
          onClick={() => close()}
        />
      }
      $contentPadding={contentPadding}
      styles={{ mask: { backgroundColor: 'transparent' } }}
      title={
        <TitleContainer ref={headerRef}>
          {icon && <FontAwesomeIcon size="xl" icon={icon} />}
          <Title level={4} style={{ margin: 0 }}>
            {title}
          </Title>
        </TitleContainer>
      }
      footer={
        footerContent || !!footerRef ? (
          <Flex justify="flex-end" gap="6px" ref={footerRef}>
            {footerContent}
          </Flex>
        ) : null
      }
      getContainer={getModalRoot()}
    >
      {form ? (
        <Form
          form={formMethods}
          layout="vertical"
          requiredMark={RequiredMark}
          {...(formInitialValues ? { initialValues: formInitialValues } : {})}
        >
          {renderedChildren}
        </Form>
      ) : (
        renderedChildren
      )}
    </StyledAntDrawer>
  )
}
