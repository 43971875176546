import { forwardRef } from 'react'

import AntdText, { TextProps } from 'antd/es/typography/Text'
import styled from 'styled-components'

import { ColorToken } from '../../constants/theme/types'
import { useTheme } from '../../hooks/use-theme'

export enum TextSize {
  Base,
  ExtraSmall,
  Small,
  Large,
  ExtraLarge,
}

export enum TextAlign {
  Left,
  Center,
  Right,
}

type Props = TextProps & {
  size?: TextSize
  colorToken?: ColorToken
  inline?: boolean
  margin?: string
  center?: boolean
  lineHeight?: string
  maxLines?: number
  allowNewlines?: boolean
  noWrap?: boolean
  pointer?: boolean
  align?: TextAlign
  strikethrough?: boolean
  whiteSpace?: React.CSSProperties['whiteSpace']
  wordBreak?: React.CSSProperties['wordBreak']
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void | Promise<void>
}

export const getFontSize = (size: TextSize) => {
  switch (size) {
    case TextSize.Base:
      return 14
    case TextSize.ExtraSmall:
      return 9
    case TextSize.Small:
      return 12
    case TextSize.Large:
      return 16
    case TextSize.ExtraLarge:
      return 20
  }
}

const getLineHeight = (size: TextSize) => {
  switch (size) {
    case TextSize.Base:
      return 22
    case TextSize.ExtraSmall:
      return 14
    case TextSize.Small:
      return 20
    case TextSize.Large:
      return 24
    case TextSize.ExtraLarge:
      return 28
  }
}

const getTextAlign = (align: TextAlign) => {
  switch (align) {
    case TextAlign.Left: {
      return 'left'
    }
    case TextAlign.Center: {
      return 'center'
    }
    case TextAlign.Right: {
      return 'right'
    }
  }
}

const makeMaxLinesCss = (maxLines: number) => `
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
  white-space: normal;
`

type TextStyleProps = {
  $size: TextSize
  $align?: TextAlign
  $color?: string
  $inline?: boolean
  $margin?: string
  $center?: boolean
  $lineHeight?: string
  $maxLines?: number
  $allowNewlines?: boolean
  $noWrap?: boolean
  $pointer?: boolean
  $strong?: boolean
  $strikethrough?: boolean
  $whiteSpace?: React.CSSProperties['whiteSpace']
  $wordBreak?: React.CSSProperties['wordBreak']
}
const StyledAntdText = styled(AntdText)<TextStyleProps>`
  font-size: ${(props) => getFontSize(props.$size)}px;
  line-height: ${(props) => getLineHeight(props.$size)}px;
  ${(props) => (props.$color ? `color: ${props.$color};` : '')}
  ${(props) => (props.$inline ? `display: inline;` : '')}
  ${(props) => (props.$margin ? `margin: ${props.$margin};` : '')}
  ${(props) => (props.$center ? `text-align: center;` : '')}
  ${(props) => (props.$lineHeight ? `line-height: ${props.$lineHeight};` : '')}
  ${(props) => props.$maxLines && makeMaxLinesCss(props.$maxLines)}
  ${(props) => (props.$allowNewlines ? `white-space: pre-wrap;` : '')}
  ${(props) => (props.$noWrap ? `white-space: nowrap;` : '')}
  ${(props) => (props.$pointer ? `cursor: pointer;` : '')}
  ${(props) => (props.$strong ? `font-weight: bold;` : '')}
  ${(props) => (props.$align ? `text-align: ${getTextAlign(props.$align)};` : '')}
    ${(props) => (props.$strikethrough ? `text-decoration: line-through;` : '')}
  ${(props) => (props.$whiteSpace ? `white-space: ${props.$whiteSpace};` : '')}
  ${(props) => (props.$wordBreak ? `word-break: ${props.$wordBreak};` : '')}
`

export const Text = forwardRef<HTMLSpanElement, Props>((props: Props, ref) => {
  const {
    size = TextSize.Base,
    color,
    colorToken,
    children,
    inline,
    margin,
    center,
    lineHeight,
    maxLines,
    id,
    allowNewlines,
    noWrap,
    onClick,
    pointer,
    align,
    strikethrough,
    whiteSpace,
    wordBreak,
    ...rest
  } = props
  const { getTokenVal } = useTheme()
  const { strong } = rest
  const themeColor = getTokenVal(colorToken || 'colorText')
  return (
    <StyledAntdText
      onClick={onClick}
      $size={size}
      $color={color || themeColor}
      $inline={inline}
      $margin={margin}
      $center={center}
      $lineHeight={lineHeight}
      $maxLines={maxLines}
      $allowNewlines={allowNewlines}
      $noWrap={noWrap}
      $pointer={pointer}
      $align={align}
      $strong={strong}
      $strikethrough={strikethrough}
      $whiteSpace={whiteSpace}
      $wordBreak={wordBreak}
      id={id}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledAntdText>
  )
})

export default Text
