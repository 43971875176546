import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import dayjs, { Dayjs } from 'dayjs'
import isNil from 'lodash.isnil'

import { UserDataFragment } from '../../../types/graphql'
import { useScheduleRecordTaskInspectionAttemptMutation } from '../../fetch/recordTaskInspectionAttempts'
import { useIsGov } from '../../layouts/AppStateContextLayout/utils'
import DatePicker, { DatePickerAllowedDates } from '../form/DatePicker'
import { OwnerSelect } from '../form/shared/OwnerSelect'
import TextArea from '../form/TextArea'
import Modal from '../Modal'

import { InspectionMetadata } from './InspectionMetadata'
import InspectionSchedulingInstructions from './InspectionSchedulingInstructions'
import { InspectionsTabModalProps } from './types'

interface FormValues {
  date: Dayjs
  schedulingNotes: string
  inspectorUserId?: number
}

interface Props extends InspectionsTabModalProps {
  defaultInspectorUserId: number | undefined
}

export const ScheduleInspectionModal = (props: Props) => {
  const {
    modalState,
    recordTaskInspectionAttemptId,
    defaultInspectorUserId,
    refetch,
    recordTaskInspectionAttemptRequestedFor,
    inspectionCutoffTime,
    inspectionSchedulingInstructions,
  } = props
  const { mutateAsync: schedule } = useScheduleRecordTaskInspectionAttemptMutation()
  const onOk = async (formValues: FormValues | undefined) => {
    if (!formValues) {
      return
    }
    const { date, schedulingNotes, inspectorUserId } = formValues
    await schedule({
      id: recordTaskInspectionAttemptId,
      input: {
        scheduledFor: dayjs(date).toISOString(),
        schedulingNotes,
        inspectorUserId: inspectorUserId ? inspectorUserId : defaultInspectorUserId,
      },
    })
    await refetch()
    modalState.close()
  }

  const isGov = useIsGov()
  const placeholder = isGov
    ? 'e.g. The inspector will try to be there between 2pm and 4pm.'
    : 'e.g. I will be home between 2pm and 4pm.'
  const caption = isGov
    ? 'You may add some notes for the applicant.'
    : 'You may add some notes for the staff.'

  const title = `Schedule Inspection`

  return (
    <Modal
      {...modalState}
      form
      icon={faCalendar}
      title={title}
      okText="Schedule"
      onOk={onOk}
      formInitialValues={{
        inspectorUserId: defaultInspectorUserId,
        date: recordTaskInspectionAttemptRequestedFor
          ? dayjs(recordTaskInspectionAttemptRequestedFor)
          : undefined,
      }}
    >
      {inspectionSchedulingInstructions && (
        <InspectionSchedulingInstructions
          inspectionSchedulingInstructions={inspectionSchedulingInstructions}
        />
      )}
      <InspectionMetadata {...props} />
      {isGov && (
        <OwnerSelect
          label="Inspector"
          fieldName="inspectorUserId"
          caption="The inspector who will be assigned this inspection"
          placeholder="Inspector"
          required
          errorMessage="You must pick an inspector for this inspection."
          filterUser={(u: UserDataFragment) => !!u.isInspector}
        />
      )}
      <DatePicker
        fieldName="date"
        label="Inspection Date"
        required
        errorMessage="You must enter a date"
        allowedDates={isGov ? DatePickerAllowedDates.All : DatePickerAllowedDates.Future}
        disableWeekends
        cutoffTime={isGov || isNil(inspectionCutoffTime) ? undefined : inspectionCutoffTime}
      />
      <TextArea
        fieldName="schedulingNotes"
        label="Scheduling Notes"
        placeholder={placeholder}
        caption={caption}
      />
    </Modal>
  )
}
