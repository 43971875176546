import { ReactNode, forwardRef } from 'react'

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as MenuPrimitive from '@radix-ui/react-dropdown-menu'
import { Flex } from 'antd'
import { Portal } from 'govwell-ui'
import styled, { css } from 'styled-components'

import { TextSize, getFontSize } from 'src/components/Typography/Text'

type MenuBaseProps = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  children: ReactNode
}
const MenuBase = ({ children, isOpen, onOpen, onClose }: MenuBaseProps) => {
  return (
    <MenuPrimitive.DropdownMenu
      defaultOpen
      open={isOpen}
      onOpenChange={(open) => (open ? onOpen() : onClose())}
    >
      {children}
    </MenuPrimitive.DropdownMenu>
  )
}

type MenuTriggerProps = Pick<React.HTMLProps<HTMLButtonElement>, 'onClick'> & {
  children: ReactNode
  isDisabled?: boolean
}
const MenuTrigger = ({ onClick, children, isDisabled }: MenuTriggerProps) => {
  return (
    <MenuPrimitive.Trigger asChild disabled={isDisabled} onClick={onClick}>
      {children}
    </MenuPrimitive.Trigger>
  )
}

export const MenuContentStyles = css`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.boxShadowSecondary};
  padding: 4px;
  width: fit-content;
  min-width: 200px;
  max-width: 100vw;
  max-height: var(--radix-dropdown-menu-content-available-height);
  overflow: auto;

  &:focus {
    outline: solid 1px ${({ theme }) => theme.colorPrimaryBorder};
  }
`
const StyledContent = styled(MenuPrimitive.Content)`
  ${MenuContentStyles}
`
type MenuContentProps = Pick<
  MenuPrimitive.DropdownMenuContentProps,
  'align' | 'side' | 'sideOffset'
> & {
  children?: ReactNode
}
const MenuContent = forwardRef<HTMLDivElement, MenuContentProps>(
  ({ align = 'start', side, sideOffset = 6, children }, ref) => {
    return (
      <Portal>
        <StyledContent ref={ref} align={align} loop side={side} sideOffset={sideOffset}>
          {children}
        </StyledContent>
      </Portal>
    )
  }
)

type MenuLabelProps = {
  children: ReactNode
}
const MenuLabel = ({ children }: MenuLabelProps) => {
  return <MenuPrimitive.Label>{children}</MenuPrimitive.Label>
}

export type MenuItemStyleProps = {
  $activeColor?: React.CSSProperties['color']
  $activeBackgroundColor?: React.CSSProperties['backgroundColor']
  $isDisabled?: boolean
}
export const MenuItemStyles = css<MenuItemStyleProps>`
  cursor: pointer;
  padding: 9px 12px;
  border-radius: 4px;
  font-size: ${getFontSize(TextSize.Base)}px;

  svg {
    min-width: ${getFontSize(TextSize.Base)}px;
  }

  ${({ theme, $activeColor, $activeBackgroundColor, $isDisabled }) =>
    $isDisabled
      ? `
    color: ${theme.colorTextDisabled};
    pointer-events: none;
  `
      : `
    &:hover {
      background-color: ${theme.colorBgLayout};
    }

    &:focus,
    &[data-selected='true'] {
      outline: none;
      color: ${$activeColor ?? theme.colorPrimaryBase};
      background-color: ${$activeBackgroundColor ?? theme.controlItemBgHover};

      svg {
        color: ${$activeColor ?? theme.colorPrimaryBase};
      }
    }
  `}
`
const StyledItem = styled(MenuPrimitive.Item)<MenuItemStyleProps>`
  ${MenuItemStyles}
`
type MenuItemProps = Pick<MenuPrimitive.DropdownMenuItemProps, 'onSelect'> & {
  icon?: IconDefinition
  children: ReactNode
}
const MenuItem = ({ children, icon, onSelect }: MenuItemProps) => {
  return (
    <StyledItem onSelect={onSelect}>
      <Flex align="center" gap="12px">
        {icon && <FontAwesomeIcon icon={icon} />}
        {children}
      </Flex>
    </StyledItem>
  )
}

const Menu = Object.assign(MenuBase, {
  Content: MenuContent,
  Trigger: MenuTrigger,
  Label: MenuLabel,
  Item: MenuItem,
  Separator: MenuPrimitive.Separator,
})

export default Menu
