import { Flex } from 'antd'
import { GetOrCreateViewsMutation } from 'types/graphql'

import TableViewHeader from 'src/components/TableViews/TableViewHeader'
import TableViewTabs from 'src/components/TableViews/TableViewTabs'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'
import { RefetchFnType } from 'src/types'

type Props = {
  generateDefaultViews: () => Promise<GetOrCreateViewsMutation>
  isLoadingViewData: boolean
  isLoadingViews: boolean
  onSaveViewChanges: () => Promise<unknown>
  onSelectedViewChange: (view: TableView) => Promise<unknown>
  searchQuery: string
  setSearchQuery: (value: string) => void
  refetchViewData: RefetchFnType
  tableViewManager: TableViewManager
  isCsvEnabled: boolean
}
const TableViewEditor = ({
  generateDefaultViews,
  isLoadingViewData,
  isLoadingViews,
  onSaveViewChanges,
  onSelectedViewChange,
  searchQuery,
  setSearchQuery,
  refetchViewData,
  tableViewManager,
  isCsvEnabled,
}: Props) => {
  return (
    <Flex vertical gap="16px" className="govwell-table-view-editor">
      <TableViewTabs
        generateDefaultViews={generateDefaultViews}
        isLoading={isLoadingViews}
        onSelectedViewChange={onSelectedViewChange}
        tableViewManager={tableViewManager}
      />
      <TableViewHeader
        isLoadingViews={isLoadingViews}
        isLoadingViewData={isLoadingViewData}
        onSaveChanges={onSaveViewChanges}
        onSearchQueryChange={setSearchQuery}
        refetch={refetchViewData}
        searchQuery={searchQuery}
        tableViewManager={tableViewManager}
        isCsvEnabled={isCsvEnabled}
      />
    </Flex>
  )
}

export default React.memo(TableViewEditor)
